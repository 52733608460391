import React from "react"

interface ProjectCapacityInterface {
    availableSpots: number,
    totalSpots: number
}
export const ProjectCapacity: React.FC<ProjectCapacityInterface> = ({ availableSpots, totalSpots }) => {
    return (
        <div className="h-min flex flex-col justify-center items-center">
            <div className="flex items-end">
                <span className="primaryFont font-bold text-5xl">{availableSpots}</span>
                <span className="primaryFont text-[#3533FF] font-bold text-4xl">&nbsp;/</span>
                <span className="primaryFont text-[#3533FF] font-bold text-4xl">{totalSpots}</span>
            </div>
            <span className="primaryFont text-[#3533FF] font-semibold text-xs">Cupos Disponibles</span>
        </div>
    )
}
