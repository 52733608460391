import fireRed from '../../../../assets/img/market-place/fire-red.svg';
import { useCountdown } from '../../../../hooks/useCountdown'

export const UnitIncrementStatusCard = () => {
    const { countdown } = useCountdown()
    return (
        <div className="flex h-fit bg-[#FFEFF4] text-[#FF1515] font-[500] px-3 py-2 rounded-sm items-center">
            <img src={fireRed} alt="Fire icon" />
            <div className="ml-2">
                <p className="secondaryFont text-xs">
                    Quedan solo {parseInt(countdown.days)} días!
                </p>
            </div>
        </div>
    )
}
