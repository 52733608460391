export const ChartSkeleton = () => {
    
    return (
        <>
            <div className="absolute inset-x-0 top-[40%] md:top-[20%] h-[60%] md:h-[80%] bg-[#ffffffa6] z-10 rounded-b-lg flex items-center justify-center">
                        <span className="text-base font-semibold ">Cargando grafica...</span>
            </div>

            <div className="w-full h-[350px]  flex flex-col items-center p-6 " >
                <div className="w-full h-full relative">
                    {Array(5)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="absolute w-full border-t border-gray-300"
                                style={{
                                    top: `${(index + 1) * 20}%`
                                }}
                            ></div>
                        ))}
                    <div className="hidden md:flex w-full h-full items-end justify-around">
                        {Array(20)
                            .fill(0)
                            .map((_, index) => (
                                <div
                                    key={index}
                                    className="w-8 skeleton rounded"
                                    style={{
                                        height: `${Math.random() * 60 + 20}%`
                                    }}
                                ></div>
                            ))}
                    </div>
                    <div className="flex md:hidden w-full h-full  items-end justify-around">
                        {Array(6)
                            .fill(0)
                            .map((_, index) => (
                                <div
                                    key={index}
                                    className="w-8 skeleton rounded"
                                    style={{
                                        height: `${Math.random() * 60 + 20}%`
                                    }}
                                ></div>
                            ))}
                    </div>
                </div>
                <div className="w-full hidden md:flex justify-around mt-2">
                    {Array(20)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="w-8 h-4 skeleton rounded"
                            ></div>
                        ))}
                </div>
                <div className="w-full flex md:hidden justify-around mt-4">
                    {Array(6)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="w-8 h-4 skeleton rounded"
                            ></div>
                        ))}
                </div>
            </div>
        </>
    );
};
