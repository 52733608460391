export const HowDidYouHearAboutUs = [
  {
    label: 'Nicolas Abril',
    value: 'Nicolas Abril',
  },
  {
    label: 'Mis propias finanzas',
    value: 'Mis propias finanzas',
  },
  { 
    label: 'Anuncio o publicidad en redes sociales',
    value: 'Anuncio o publicidad en redes sociales',
  },
  { 
    label: 'Creador@ de contenido',
    value: 'Creador@ de contenido',
  },
  { 
    label: 'Programa de embajadores', 
    value: 'Programa de embajadores', 
  },
  { 
    label: 'Finzi', 
    value: 'Finzi', 
  },
  { 
    label: 'Noticias',
    value: 'Noticias',
  },
  { 
    label: 'Email', 
    value: 'Email', 
  },
  { 
    label: 'Google', 
    value: 'Google', 
  },
  { 
    label: 'Alianzas',
    value: 'Alianzas', 
  },
  { 
    label: 'Familiar, amigo o conocido', 
    value: 'Familiar, amigo o conocido', 
  },
  { 
    label: 'Eventos', 
    value: 'Eventos', 
  },
  { 
    label: 'Youtube', 
    value: 'Youtube', 
  },
  { 
    label: 'Instagram', 
    value: 'Instagram', 
  },
  { 
    label: 'Tiktok', 
    value: 'Tiktok', 
  },
  { 
    label: 'LinkedIn', 
    value: 'LinkedIn', 
  },
];
