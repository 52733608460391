import React, { createContext, useState, ReactNode } from 'react';

interface UTMContextInterface {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
}

interface UTMContextType {
    UTM: UTMContextInterface;
    setUTM: React.Dispatch<React.SetStateAction<UTMContextInterface>>;
}

const defaultUTM: UTMContextInterface = {
    utmSource: undefined,
    utmMedium: undefined,
    utmCampaign: undefined,
    utmTerm: undefined,
    utmContent: undefined,
};

export const UTMContext = createContext<UTMContextType>({
    UTM: defaultUTM,
    setUTM: () => {},
});

interface UTMProviderProps {
    children: ReactNode;
}

export const UTMProvider: React.FC<UTMProviderProps> = ({ children }) => {
    const [UTM, setUTM] = useState<UTMContextInterface>(defaultUTM);

    return (
        <UTMContext.Provider value={{ UTM, setUTM }}>
            {children}
        </UTMContext.Provider>
    );
};
