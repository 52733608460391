import shareCode from '../../../assets/img/dashboard/shareCode.svg';

interface ReferCardInterface {
    code: string
    modalCode: boolean
    setModalCode: (copied: boolean) => void
}

const ReferCodeCard = ({ code, modalCode, setModalCode }: ReferCardInterface) => {

    return (
        <>
            <div className="w-56 border-b-[1px] border-gray-300 mt-4 mb-1"></div>

            <div className="text-center">

                <div className="font-roboto text-[22px] font-black text-black">{code}</div>
                <div className="font-roboto">Tu código de referidos</div>

                <div className="flex justify-center items-center text-sm mt-2 py-2 px-1 border-[1px] border-black rounded-full cursor-pointer"
                    onClick={() => setModalCode(!modalCode)}
                >
                    <img className='mr-2 font-roboto' src={shareCode} alt="share code icon" />
                    <div className="">Compartir</div>
                </div>

            </div>

        </>
    )
}

export default ReferCodeCard
