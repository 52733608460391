import { formatNumberWithCommas } from "../../../../utils"
import { DividendHistoryInterface } from "../../interfaces"

const getStatusClasses = (status: string) => {
    switch (status) {
        case 'APPROVED':
            return 'bg-[#00F0B5]';
        case 'DECLINED':
            return 'bg-[#FFB7D1] !text-[#BD3063]';
        default:
            return 'bg-[#C3B7FF]';
    }
};

const formatStatus = (status: string) => {
    switch (status) {
        case 'APPROVED':
            return 'Aprobado';
        case 'DECLINED':
            return 'Rechazado';
        default:
            return 'Pendiente';
    }
}

export const DividendHistoryCard = ({ amount, approvalDate, requestDate, status, type }: DividendHistoryInterface) => {
    return (
        <div className="flex flex-row justify-between items-center py-3  px-2 border-b last:border-b-0">
            <div className="flex flex-row space-x-6">
                <div className="flex items-center justify-center">
                    {type === 'payout' && <svg className="w-[24px] h-[24px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64l241.9 0c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5 608 384c0 35.3-28.7 64-64 64l-241.9 0c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5 32 128c0-35.3 28.7-64 64-64zm64 64l-64 0 0 64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64l64 0 0-64zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" /></svg>}
                    {type === 'reinvestment' && <svg className="w-[24px] h-[24px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z" /></svg>}
                </div>
                <div className="flex flex-col space-y-1">
                    <span className="text-[#131313] primaryFont font-bold text-base">{type === 'reinvestment' ? 'Reinversión' : 'Retiro'}</span>
                    <span className="numberFont text-[#636262]">{requestDate}</span>
                </div>
            </div>
            <div className="flex flex-col items-end space-y-1 justify-center">
                <span className={`text-[#3533FF] numberFont px-2 rounded-md text-md font-medium ${getStatusClasses(status)}`}>
                    {formatStatus(status)}
                </span>
                <span>${formatNumberWithCommas(amount)}</span>
            </div>
        </div>
    )
}
