import shovel from '../../../../assets/img/projects/shovel.svg';

export const ConstructionStatusCard = () => {
    return (
        <div className="flex bg-[#D5CDFF] text-black font-[500] px-3 py-2 rounded-sm items-center">
            <img src={shovel} alt="Fire icon" />
            <div className="ml-2">
                <p className="secondaryFont text-xs">
                    Construcción
                </p>
            </div>
        </div>
    )
}
