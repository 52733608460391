import { useOutletContext } from "react-router-dom";
import { InvestmentDetailsInterface } from "../interfaces";
import { ListValuationCards } from "./RentDetail/ListValuationCards";
import { ValuationDashboard } from "./RentDetail/ValuationDashboard";
import { RectangleSkeleton } from "./RentDetail/RectangleSkeleton";
import { DividendCard } from "./RentDetail/DividendCard";
import { ExperienceBonusCard } from "./RentDetail/ExperienceBonusCard";

interface RentDetailDashboardOutletContext {
    investmentDetails: InvestmentDetailsInterface;
    isLoading: boolean;
}

const RentDetailDashboard = () => {
    const { investmentDetails, isLoading } = useOutletContext<RentDetailDashboardOutletContext>();
    const featureFlags = (investmentDetails?.features || []).reduce<Record<string, boolean>>((flags, feature) => {
        if (feature.enabled) {
            flags[feature.name] = true;
        }
        return flags;
    }, {});

    const dividendsDeliveryEnabled = !!featureFlags['DIVIDENDS_DELIVERY'] && investmentDetails?.features?.some(feature => feature.name === 'DIVIDENDS_DELIVERY');
    const experienceBonusesEnabled = !!featureFlags['EXPERIENCE_BONUSES'] && investmentDetails?.features?.some(feature => feature.name === 'DIVIDENDS_DELIVERY');

    return (
        <div className='w-full h-auto space-y-6'>
            {isLoading ? <RectangleSkeleton height={80} /> : <ListValuationCards />}
            {isLoading ? <RectangleSkeleton height={300} /> : <ValuationDashboard />}
            <div className="w-full flex flex-row flex-wrap space-x-[0px] md:space-x-6 space-y-6 md:space-y-[0px]">
                {experienceBonusesEnabled && <ExperienceBonusCard />}
                {dividendsDeliveryEnabled && <DividendCard />}
            </div>
        </div>
    )
}

export default RentDetailDashboard
