import { useEffect, useState } from 'react';
import scrollGif from '../../../../assets/gifs/mobile-actions/horizontal-scroll.gif';
import { useOutletContext } from 'react-router-dom';
import { cloudbedsDashboardInfoInterface } from '../../interfaces';

export interface ScrollMessageOutletContext {
    cloudbedsDashboardInfo: cloudbedsDashboardInfoInterface;
    setCloudbedsDashboardInfo: (value: any) => void;
}

export const ScrollMessage = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isClosing, setIsClosing] = useState(false);
    const { cloudbedsDashboardInfo, setCloudbedsDashboardInfo } =
        useOutletContext<ScrollMessageOutletContext>();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsClosing(true);
            setTimeout(() => {
                setIsVisible(false);
                setCloudbedsDashboardInfo({
                    ...cloudbedsDashboardInfo,
                    alreadyShowScrollMessage: true,
                });
            }, 1000);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [cloudbedsDashboardInfo, setCloudbedsDashboardInfo]);

    if (!isVisible) return null;

    return (
        <div
            className={`absolute w-full h-[60%] top-[40%] flex items-center justify-center bg-white z-50 rounded-b-lg ${
                isClosing ? 'slide-out' : 'slide-in'
            }`}
        >
            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                <span className="text-[#221feb] text-lg font-bold">Desliza a la derecha</span>
                <span className="text-gray-500 text-base">Para ver más datos</span>
            </div>
            <img src={scrollGif} alt="Desliza para ver más" className="h-40 w-40 object-contain" />
        </div>
    );
};
