import React from 'react'
import { DividendReinvestmentDetail } from '../../../Dashboard/detail/sections/DividendReinvestmentDetail';
import { useOutletContext } from 'react-router-dom';
import { DividendPayoutInterface } from '../../interfaces';


export const DividendReinvestment = () => {
  const dividendPayouts = useOutletContext<DividendPayoutInterface>();

  return (
    <>
      <DividendReinvestmentDetail totalNoRequestAmount={dividendPayouts?.totalNoRequestAmount} />
    </>
  )
}
