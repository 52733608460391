import React, { useState, useEffect } from "react";
import { cloudbedsTransactionsInterface } from "../../interfaces";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ChartTransactionsInterface {
  transactions: cloudbedsTransactionsInterface[];
  daysNumber: number;
}

export const ChartTransactions = ({ transactions, daysNumber }: ChartTransactionsInterface) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const chartData = transactions
    .map((transaction) => {
      if (!transaction.date || !transaction.totalValue) {
        console.warn("Dato inválido:", transaction);
        return { date: "N/A", totalValue: 0 };
      }

      const parsedDate = new Date(transaction.date);
      if (isNaN(parsedDate.getTime())) {
        console.warn("Fecha inválida:", transaction.date);
        return { date: "N/A", totalValue: transaction.totalValue };
      }

      return {
        date: parsedDate.toLocaleDateString("es-CO"),
        Ingresos: Number(transaction.totalValue),
      };
    })
    .filter((data) => data.date !== "N/A")
    .sort((a, b) => {
      return (
        new Date(a.date.split("/").reverse().join("-")).getTime() - 
        new Date(b.date.split("/").reverse().join("-")).getTime()
      );
    });

  const compactFormatter = (value: number) =>
    value >= 1_000_000
      ? `${Math.round(value / 1_000_000)}M`
      : value >= 1_000
      ? `${Math.round(value / 1_000)}K`
      : value.toLocaleString();

  const currencyFormatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  });

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 shadow-md border rounded">
          <p className="text-gray-700 font-bold">{`Fecha: ${label}`}</p>
          <p className="text-gray-600">{`Ingresos: ${currencyFormatter.format(payload[0].value)}`}</p>
        </div>
      );
    }
    return null;
  };

  const leftMargin = isMobile ? 0 : 64;

  return (
    <div className="relative w-full h-[350px] overflow-x-auto custom-scroll">
      <div className={`${daysNumber <= 7 ? "w-full" : "w-[1200px]"} md:w-full h-full`}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{
              top: 50,
              right: 44,
              bottom: 20,
              left: leftMargin,
            }}
          >
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="date" />
            <YAxis
              tickFormatter={(value) =>
                isMobile ? compactFormatter(value) : currencyFormatter.format(value)
              }
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              activeDot={{ r: 8 }}
              dataKey="Ingresos"
              stroke="#221feb"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
