import { useOutletContext } from 'react-router-dom';
import { InvestmentDetailsInterface } from '../../interfaces';
import { CapitalizeWords } from '../../../../utils/otherFormat';

interface InfoProjectOutletContext {
    investmentDetails: InvestmentDetailsInterface;
}

export const InfoProject = () => {
    const { investmentDetails } = useOutletContext<InfoProjectOutletContext>();

    return (
        <div className="block md:absolute top-0 left-[62%] right-0 bottom-0 w-full md:w-[38%] bg-white rounded-xl shadow-lg p-6 border border-gray-200 text-[#3533FF] overflow-auto">
            {investmentDetails && investmentDetails.description ? (
                <div className="">
                    <h2 className="primaryFont text-4xl primaryFont text-black font-bold mb-4">{CapitalizeWords(investmentDetails.name)}</h2>
                    <p className="secondaryFont text-base text-left secondaryFont text-[#5B5B5B]">{investmentDetails.description}</p>
                </div>
            ) : (
                <div className="primaryFont flex justify-center items-center text-gray-500 h-[100px]">
                    No se encontró información
                </div>
            )}

          
            {investmentDetails?.amenities && investmentDetails?.amenities.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-xl font-semibold mb-4 text-black">Detalles</h3>
                    <div className="flex flex-wrap mt-2 primaryFont">
                        {investmentDetails.squareMeters && (
                            <span className="bg-[#E9E9E9] text-[#3533FF] text-base px-2.5 py-0.5 rounded-xl m-1">
                                {investmentDetails.squareMeters} m²
                            </span>
                        )}
                        {investmentDetails.totalAccomodations && (
                            <span className="bg-[#E9E9E9] text-[#3533FF] text-base px-2.5 py-0.5 rounded-xl m-1">
                                {investmentDetails.totalAccomodations} Alojamientos
                            </span>
                        )}
                        {investmentDetails.amenities.map((amenity, index) => (
                            <span
                                key={index}
                                className="bg-[#E9E9E9] text-[#3533FF] text-base px-2.5 py-0.5 rounded-xl m-1"
                            >
                                {amenity}
                            </span>
                        ))}
                    </div>
                </div>
            )}

            
            {investmentDetails?.accommodations && investmentDetails.accommodations.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-xl font-semibold mb-4 text-black">Tipos de habitaciones</h3>
                    <div className="flex flex-wrap mt-2">
                        {investmentDetails.accommodations.map((accommodation, index) => (
                            <span
                                key={index}
                                className="bg-[#E9E9E9] text-[#3533FF] text-base px-2.5 py-0.5 rounded-xl m-1"
                            >
                                {accommodation}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
