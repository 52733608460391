import React, { useEffect, useState } from 'react'
import { InvestmentDetailMain } from './InvestmentDetailMain';
import { PayoutDetail } from './PayoutDetail';
import { useLocation, useParams } from 'react-router-dom';
import { getDividendPayout } from '../../../../services/DividenPayoutService';
import { DividendReinvestmentDetail } from './DividendReinvestmentDetail';



export const InvestmentDetail = ({project}: {project: any}) => {

  const { projectId } = useParams()

  const [ showPayoutForm, setShowPayoutForm ] = useState(false)
  const [ showReinvestmentForm, setShowReinvestmentForm ] = useState(false)
  const [ dividendPayoutIsLoading, setDividendPayoutIsLoading ] = useState(false)
  const [ dividendPayouts, setDividendPayouts ] = useState(
    {
      totalNoRequestAmount: 0,
      totalPendingAmount: 0,
      noRequestDividendPayouts: [],
      pendingDividendPayouts: [],
      dividendHistory: []
    }
  )

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const sectionParam = searchParams.get('section');

  useEffect(() => {
    if(sectionParam === 'payoutDetail') {
      setShowPayoutForm(true)
    }
  }, [sectionParam])

  useEffect(() => {
    if(projectId) {
      setDividendPayoutIsLoading(true)
      getDividendPayout(projectId).then((response: any) => {
        setDividendPayouts(response?.data?.data)
      }).finally(() => {
        setDividendPayoutIsLoading(false)
      })
    }
  } , [projectId])

  return (
    <>
      {/* {
        !showPayoutForm ?
        <InvestmentDetailMain
          payoutDividendsLoading={dividendPayoutIsLoading}
          project={project}
          setShowReinvestmentForm={setShowReinvestmentForm}
          setShowPayoutForm={setShowPayoutForm}
          totalNoRequestAmount={dividendPayouts.totalNoRequestAmount}
          totalPendingAmount={dividendPayouts.totalPendingAmount} 
        />
        : <PayoutDetail dividendPayouts={dividendPayouts.noRequestDividendPayouts } />
      } */}
      {
        showPayoutForm ?
        <PayoutDetail dividendPayouts={dividendPayouts.noRequestDividendPayouts } />
        : showReinvestmentForm ?
        <DividendReinvestmentDetail totalNoRequestAmount={dividendPayouts.totalNoRequestAmount} />
        : <InvestmentDetailMain
          payoutDividendsLoading={dividendPayoutIsLoading}
          project={project}
          setShowReinvestmentForm={setShowReinvestmentForm}
          setShowPayoutForm={setShowPayoutForm}
          totalNoRequestAmount={dividendPayouts.totalNoRequestAmount}
          totalPendingAmount={dividendPayouts.totalPendingAmount} 
        />
      }
    </>
  )
}