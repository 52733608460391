import React, { useEffect, useState } from 'react';
import { getHomeIndicators } from '../../../services/globalServices';
import { BlackBar, TimeLineColors } from '../components/componetsTimeLine';

export const TimeLine = () => {
  const [indicators, setIndicators] = useState({
    investors: '',
    totalInvestmentValue: 0,
  });

  useEffect(() => {
    const getData = async () => {
      const { data } = await getHomeIndicators();
      setIndicators({
        investors: data?.data?.totalInvestors,
        totalInvestmentValue: data?.data?.totalInvested,
      });
    };
    getData();
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col justify-between space-y-4">
    
    <TimeLineColors />

  
    <div className="w-full">
      <BlackBar 
        investors={indicators.investors} 
        totalInvestmentValue={parseFloat((indicators.totalInvestmentValue / 1000000).toFixed(0))} 
      />
    </div>
  </div>
  );
};
