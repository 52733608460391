import { useEffect, useState } from 'react';
import scrollGif from '../../../../assets/gifs/mobile-actions/horizontal-scroll.gif';


export const ScrollMessageValorization = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isClosing, setIsClosing] = useState(false);


    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsClosing(true);
            setTimeout(() => {
                setIsVisible(false);
            }, 1000);
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);

    if (!isVisible) return null;

    return (
        <div
            className={`absolute w-full h-[90%] top-[10%] flex items-center justify-center bg-white z-50 rounded-b-lg ${isClosing ? 'slide-out' : ''
                }`}
        >
            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                <span className="text-[#221feb] text-lg font-bold">Desliza a la derecha</span>
                <span className="text-gray-500 text-base">Para ver más datos</span>
            </div>
            <img src={scrollGif} alt="Desliza para ver más" className="h-40 w-40 object-contain" />
        </div>
    );
};
