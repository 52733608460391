import { useEffect, useState } from "react";
import { AppreciatedInvestmentPerMonth } from "../../interfaces";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

interface DividendsChartInterface {
  appreciatedInvestmentPerMonth: AppreciatedInvestmentPerMonth[];
}

export const DividendsChart = ({
  appreciatedInvestmentPerMonth,
}: DividendsChartInterface) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatValue = (value: number): string => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(0)}K`;
    }
    return value.toFixed(0);
  };

  const groupByQuarter = (data: AppreciatedInvestmentPerMonth[]) => {
    const quarters: Record<
      string,
      { label: string; total: number; avgPercentage: number; count: number }
    > = {};

    data.forEach((item) => {
      const quarter =
        Math.ceil(item.month / 3) === 1
          ? "Q1"
          : Math.ceil(item.month / 3) === 2
          ? "Q2"
          : Math.ceil(item.month / 3) === 3
          ? "Q3"
          : "Q4";
      const year = item.year;
      const key = `${quarter} ${year}`;

      if (!quarters[key]) {
        quarters[key] = {
          label: key,
          total: 0,
          avgPercentage: 0,
          count: 0,
        };
      }

      quarters[key].total += item.dividendPayout;
      quarters[key].avgPercentage += item.utilitiesPercentage;
      quarters[key].count += 1;
    });

    Object.keys(quarters).forEach((key) => {
      if (quarters[key].count > 0) {
        quarters[key].avgPercentage /= quarters[key].count;
      }
    });

    return Object.values(quarters);
  };

  const chartData = groupByQuarter(appreciatedInvestmentPerMonth).filter(
    (data) => data.total > 0
  );

  const CustomTooltip = ({ payload, label }: any) => {
    if (!payload || !payload.length) return null;
  
    const { total, avgPercentage } = payload[0].payload;
  
    const formatCurrency = (value: number): string =>
      new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
      }).format(value);
  
    return (
      <div className="bg-white p-3 shadow-md border rounded">
        <p className="text-gray-700 font-bold">{`${label}`}</p>
        <p className="text-gray-600 numberFont">{`Total de dividendos: ${formatCurrency(total)}`}</p>
        <p className="text-gray-600 numberFont">{`Porcentaje: ${avgPercentage.toFixed(0)}%`}</p>
      </div>
    );
  };
  
  const labelStyle = {
    fill: "#4A4A4A",
    fontWeight: "numberFont",
    fontSize: isMobile ? 8 : 10, 
  };
  const leftMargin = isMobile ? 4 : 24;

  return (
    <div className="w-full h-[350px] rounded-lg overflow-x-auto custom-scroll">
      <div className="w-full h-full">
        
        {chartData.length === 0 ? (
          <div className="w-full h-full flex justify-center items-center text-gray-600 font-bold text-xl">
            No hay historial de dividendos
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{
                top: 50,
                right: 34,
                bottom: 20,
                left: leftMargin,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis tickFormatter={(value) => formatValue(value)} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="total"
                fill="#C3B7FF"
                barSize={30}
                radius={[10, 10, 0, 0]}
                animationDuration={1500}
              >
                <LabelList
                  dataKey="total"
                  position="top"
                  formatter={(value: number) => formatValue(value)}
                  style={labelStyle}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
