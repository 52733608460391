import { useState } from "react"
import { useInvestmentSummary } from "../../../../hooks/useInvestmentSummary"
import { PaymentMethod } from "../components/PaymentMethod"
import { ReinvestmentPaymentSummary } from "../components/ReinvestmentPaymentSummary"
import { Reinvestment } from "../interfaces/interfaces"


interface PaymentInterface {
  reinvestment: Reinvestment
  totalNoRequestAmount: number
}
export const Payment: React.FC<PaymentInterface> = ({ reinvestment, totalNoRequestAmount }) => {
  const [paymentMethod, setPaymentMethod] = useState<number>(2);

  const {
    totalInvestment,
    subtotal,
    paymentMethodFee,
    installments,
    total
  } = useInvestmentSummary(
    reinvestment.investUnitAmount,
    '1',
    paymentMethod,
    reinvestment.project,
    reinvestment.coupon ? reinvestment.coupon.couponUnitPrice : 0,
    reinvestment.coupon ? reinvestment.coupon.couponSubscriptionFeeFree : false,
    totalNoRequestAmount)

  return (
    <div className="flex flex-wrap md:flex-nowrap w-full items-stretch">
      <div className="w-full md:w-3/4 xl:w-3/6 bg-white mr-0 md:mr-4">
        <PaymentMethod paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
      </div>
      <div className="w-full md:w-3/4 xl:w-2/6 bg-white">
        <ReinvestmentPaymentSummary
          totalInvestment={totalInvestment}
          subtotal={subtotal}
          paymentMethodFee={paymentMethodFee}
          installments={installments}
          total={total}
          project={reinvestment.project}
          totalNoRequestAmount={totalNoRequestAmount} />
      </div>
    </div>

  )
}