import React, { useEffect, useState } from 'react';
import './spinner.scss'

interface ReinvestmentLoadingProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReinvestmentLoading: React.FC<ReinvestmentLoadingProps> = ({ setIsLoading }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    //setIsLoading(false)
                    return 100;
                }
                return prevProgress + 1;
            });
        }, 50);

        return () => clearInterval(interval);
    }, [setIsLoading]);


    return (
        <div className="mt-10 lg:mt-0 flex flex-col items-center justify-end w-full h-[483px] bg-white rounded-md">

            <div className='flex flex-col justify-center items-center my-auto'>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
                <div className='flex'>
                    {
                        progress <= 33
                            ?
                            <span className="text-base font-semibold">Procesando utilidades</span>
                            :
                            progress <= 70
                                ?
                                <span className="text-base font-semibold">Confirmando transacción</span>
                                :
                                <span className="text-base font-semibold">Reinversión completada</span>
                    }
                </div>
            </div>
        </div>
    );
};