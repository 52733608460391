// ServiceOption.tsx
import React from "react";

interface ServiceOptionProps {
  title?: string;
  icon: JSX.Element;
  alertText?: string;
  onSelect: (alertText?: string) => void;
  isSelected: boolean;
}

const ServiceOption: React.FC<ServiceOptionProps> = ({
  title,
  icon,
  alertText,
  onSelect,
  isSelected,
}) => {
  return (
    <div
      className={`px-2 flex items-center justify-between py-4 border-b border-gray-200 last:border-b-0 cursor-pointer ${isSelected ? "bg-green-100 border-green-500" : ""
        }`}
      onClick={() => onSelect(alertText)}
    >
      <div className="flex w-fit my-auto">
        {icon}
        <h1 className="secondaryFont ml-2 font-medium">{title}</h1>
      </div>
      <div className='flex justify-center items-center'>
        {isSelected && <div
          className="w-6 h-6 bg-green-400 rounded-full flex items-center justify-center text-white"
        >
          ✓
        </div>}
      </div>
    </div>
  );
};

export default ServiceOption;
