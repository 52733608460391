
export const UrlFormat = (text: string): string => {
    const lowerCaseStr = text.toLowerCase();
    const formattedStr = lowerCaseStr.split(' ').join('-');
    return formattedStr;
}
export const CapitalizeFirstLetter = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const CapitalizeWords = (text: string) => {
    return text
        .split(' ')
        .map(word => {
            if (word.length > 3) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        })
        .join(' ');
}