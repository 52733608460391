import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../helpers/functions';
import moment from 'moment';
import { getDividendReinvestmentByDividendReinvestmentId, getDividendReinvestmentByGatewayTransactionId } from '../../services/paymentDataServices';
import { useEffectOnce } from '../../helpers/hooks';
import ModalShareCodeUnit from '../../components/ModalShareCodeUnits';
import { getProjectCards } from '../../services/projectService';
import { ProjectCardDataInterface, ProjectCardResponseInterface } from '../NewHome/interfaces/ProjectCardDataInterface';
import { CapitalizeFirstLetter } from '../../utils/otherFormat';

export const Approved = () => {

    const [dividendReinvestment, setDividendReinvestment] = useState<any>(undefined);
    const [project, setProject] = useState<ProjectCardDataInterface>({
        id: "",
        minRent: 1,
        maxRent: 1,
        unitPrice: 1,
        partners: 1,
        name: "",
        phase: "",
        country: "",
        city: "",
        accommodations: 1,
        squareMeters: 1,
        amenities: [],
        minInvestmentUnits: 1,
        maxInvestmentQuota: 1,
        availableSpots: 1,
        totalSpots: 1,
        videoURL: "",
        imageURL: ""
    })
    const useQuery = () => new URLSearchParams(useLocation().search);
    const idTransaction = useQuery().get('id');
    const dividendReinvestmentTransactionId = useQuery().get('dividendReinvestmentTransactionId');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getData = async () => {

        try {
            if (idTransaction) {
                getDividendReinvestmentByGatewayTransactionId(idTransaction).then(async (resp) => {

                    setDividendReinvestment(resp?.data);
                    const projectId = resp?.data.investment.projectId;

                    getProjectCards().then((data: ProjectCardResponseInterface) => {
                        const filteredProject = data.data.find((project: ProjectCardDataInterface) => (project.id === projectId));
                        if (filteredProject) setProject(filteredProject)
                    })
                })
            }
            else if (dividendReinvestmentTransactionId) {
                getDividendReinvestmentByDividendReinvestmentId(dividendReinvestmentTransactionId).then(async (resp) => {
                    setDividendReinvestment(resp?.data);
                    const projectId = resp?.data.investment.projectId;

                    getProjectCards().then((data: ProjectCardResponseInterface) => {
                        const filteredProject = data.data.find((project: ProjectCardDataInterface) => (project.id === projectId));
                        if (filteredProject) setProject(filteredProject)
                    })
                })
            }
        } catch (error: any) {

            navigate('/payment/fail');

        }

    }

    useEffectOnce(() => {

        getData();

    });

    return (
        <>
            <div className='hidden lg:w-[1024px] lg:flex lg:space-x-16 py-20'>

                <div className="relative lg:w-1/2 lg:h-[480]">

                    {project && project.imageURL && <img className='absolute rounded-3xl' src={project.imageURL} alt="" />}
                    <div className="flex flex-col justify-end items-center absolute w-full h-full">

                        {dividendReinvestment && dividendReinvestment.user &&
                            <div className="text-white font-extrabold text-4xl text-center">
                                ¡Hola {CapitalizeFirstLetter(dividendReinvestment.user.firstName)}!
                            </div>
                        }
                        <div className="text-white text-xl text-center">
                            Es un placer tenerte de vuelta<br />
                            ¡Gracias por seguir confiando en nosotros!
                        </div>

                    </div>

                </div>

                <div className="lg:w-1/2 lg:space-y-7">

                    <div className="text-xl text-green-400 font-medium">
                        Reinversión aprobada <br />
                    </div>

                    {dividendReinvestment && dividendReinvestment.investment &&
                        <div className="text-gray-500 text-sm">
                            {moment(dividendReinvestment.investment.createAt).format('LL')}
                            <br />
                            {moment(dividendReinvestment.investment.createAt).format('hh:mm a')}
                        </div>
                    }

                    <div className="flex items-end">

                        <div className="">
                            {/* TODO: NAME OF PROJECT */}
                            <div className="font-extrabold font-lato text-3xl">
                                {CapitalizeFirstLetter(project.name)}
                            </div>
                            {
                                dividendReinvestment && dividendReinvestment.transaction &&
                                <>
                                    <div className="font-extrabold font-lato text-3xl mt-3">
                                        ${numberWithCommas(dividendReinvestment.transaction.value)}
                                    </div>
                                    <div className="text-gray-500 text-sm">
                                        Pago extra
                                    </div>
                                </>
                            }
                            {dividendReinvestment && dividendReinvestment.investment &&
                                <>
                                    <div className="font-extrabold font-lato text-3xl">
                                        ${numberWithCommas(dividendReinvestment.dividendReinvestmentTransaction.amount || 0)}
                                    </div>
                                    <div className="text-gray-500 text-sm">
                                        Pago a través de utilidades
                                    </div>
                                    <div className="font-extrabold font-lato text-3xl text-green-400 mt-10">
                                        Inversión: ${numberWithCommas(dividendReinvestment.investment.investmentValue || 0)}
                                    </div>
                                    <div className="text-gray-500 text-base mt-3">
                                        El pago a través de utilidades estara en proceso de validacíon, pronto se encontrara reflejado en tu reinversión.
                                    </div>
                                </>
                            }
                        </div>

                    </div>

                    <div className="flex divide-x-2 space-x-2 text-sm leading-tight">

                        <div className="">{project.phase}</div>
                        {dividendReinvestment && dividendReinvestment.investment &&
                            <>
                                <div className="pl-2">Número de Units: {numberWithCommas(dividendReinvestment.investment.unitsQuantity)}</div>
                                <div className="pl-2">Costo por Unit: ${numberWithCommas(dividendReinvestment.investment.unitValue)}</div>
                            </>
                        }

                    </div>

                    {/* <div className="w-[360px] p-4 flex border-[1px] rounded">

                        <img src={share} alt="" />

                        <div className="ml-5 text-sm">

                            <div className="">Comparte con un amigo</div>
                            <div className="">Obtén puntos y canjearlos por beneficios</div>
                            <div className="">Próximamente</div>

                        </div>

                    </div> */}

                    <div className="">

                        <button
                            className="bg-gray-900 text-white hover:bg-gray-700 w-60 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out"
                            onClick={() => navigate(`/dashboard`)}
                        >
                            Continuar
                        </button>

                    </div>

                </div>

            </div>

            <div className=" bg-green-lokl text-black text-center mb-5 py-4 px-11 rounded block w-full mx-auto mt-6 md:hidden">

                <div className="">Reinversión aprobada</div>
                <div className="font-bold">¡Es un placer</div>
                <div className="font-bold">tenerte de vuelta!</div>

            </div>

            <div className="lg:hidden relative flex justify-center h-auto mb-[500px]">

                <img className="absolute rounded-3xl" src={project.imageURL} alt="" />

                <div className="absolute flex flex-col justify-center items-center ">

                    <div className="mt-32 mb-4">

                        <div className="font-semibold text-center text-4xl text-white">
                            Bienvenido <br />
                            a {CapitalizeFirstLetter(project.name)}
                        </div>

                        <div className="text-white text-center">
                            ¡Gracias por seguir confiando en nosotros!
                        </div>

                    </div>

                    <button
                        className="bg-white text-black hover:bg-gray-400 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                            navigate('/dashboard')
                        }}
                    >
                        Comencemos
                    </button>

                </div>

            </div>

            <ModalShareCodeUnit />

        </>
    )

}