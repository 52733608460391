import React, { useState, useEffect } from 'react';

import { 
  FacebookShareButton, 
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { getUserData } from '../services/ownerServices';
import jwt_decode from "jwt-decode";

const ModalShareCodeUnitsMobileNav: React.FC = () => {
  const [code, setCode] = useState('');
  const [codeCopied, setCodeCopied] = useState(false);

 
  const getData = async (id: string) => {
    const { data } = await getUserData();
    const userCode = data.data.uniqueCode;
    setCode(userCode);
  };

 
  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`);
    setCodeCopied(true);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);
  };

  useEffect(() => {
    
    const token = localStorage.getItem("token") ?? '';
    if (token) {
      const decoded: any = jwt_decode(token);
      getData(decoded.id);
    }
  }, []);

  return (
    <div className="relative w-96  rounded-xl p-6 flex flex-col justify-between items-center">
      
      
      <div className="w-[94%] font-roboto text-base text-center mb-8">
        Refiere a tus amigos  <br /> 
        <span className='text-[#3533FF] font-roboto text-xl font-bold'>¡Te obsequiamos 1UNIT!</span>
      </div>

  
      <div className="flex justify-between items-center w-[92%] border p-2 rounded-full mb-3">
        <div className="text-gray-400 text-sm">
          www.lokl.life?code={code}
        </div>
        <div
          className="bg-[#3533FF] text-white text-sm rounded-full py-2 px-3 cursor-pointer"
          onClick={copyTextToClipboard}
        >
          {codeCopied ? 'Copiado!' : 'Compartir'}
        </div>
      </div>

      <div className="w-[92%] mb-4 flex justify-center gap-3">
        <FacebookShareButton url={`https://www.lokl.life?code=${code}`} quote={'Me gusta invertir en lokl'} hashtag="#lokl">
          <FacebookIcon size={35} round />
        </FacebookShareButton>

        <TwitterShareButton url={`https://www.lokl.life?code=${code}`}>
          <TwitterIcon size={35} round />
        </TwitterShareButton>

        <WhatsappShareButton url={`https://www.lokl.life?code=${code}`}>
          <WhatsappIcon size={35} round />
        </WhatsappShareButton>

        <TelegramShareButton url={`https://www.lokl.life?code=${code}`}>
          <TelegramIcon size={35} round />
        </TelegramShareButton>

        <LinkedinShareButton url={`https://www.lokl.life?code=${code}`}>
          <LinkedinIcon size={35} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ModalShareCodeUnitsMobileNav;
