import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import ambassador_icons_white from "../../../assets/img/ambassadors/ambassadors-icons-white.png";

const PointAccumulateDescription: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      className="bg-[#5352F6] text-white p-8 mt-8 relative"
      ref={sectionRef}
    >
      <div className="flex items-center justify-between gap-10 md:mt-20 pt-9">
        <motion.div
          className="text-left md:ml-40 -space-y-1 md:-space-y-3"
          initial={{ opacity: 0, x: -50 }}
          animate={isVisible ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl md:translate-y-4 md:text-8xl font-thin primaryFont">
            Acumula
          </h2>
          <div>
            <h1 className="text-7xl md:text-[170px] font-bold primaryFont">
              puntos
            </h1>
          </div>
          <p className="text-3xl translate-y-4 md:text-8xl font-thin primaryFont">
            con tus referidos
          </p>
        </motion.div>

        <motion.div
          className="md:w-[340px] md:h-[340px] md:-translate-y-5 w-[150px] h-[150px] md:mr-44 md:mt-10 md:pt-10"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={isVisible ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.8 }}
        >
          <img
            src={ambassador_icons_white}
            alt="Referidos"
            className="w-full h-full object-contain"
            loading="lazy"
          />
        </motion.div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:mt-12 w-full mb-20 relative">
        
        <motion.div
          className="md:w-1/2 flex flex-col md:mr-20 my-8 md:my-0 items-center md:items-end text-center md:text-right"
          initial={{ opacity: 0, x: -50 }}
          animate={isVisible ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <div className="relative">
            <div className="md:text-right">
              <h3 className="text-3xl md:text-5xl font-extralight primaryFont">
                Gana
              </h3>
              <h3 className="text-3xl md:text-5xl font-semibold primaryFont">
                recompensas
              </h3>
              <h3 className="text-3xl md:text-5xl font-extralight primaryFont">
                adicionales
              </h3>
            </div>
          </div>
        </motion.div>

       
        <div className="hidden md:block h-36 w-[1px] bg-white mx-6 relative">
          <div className="w-2 h-2 bg-white rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        </div>

        <motion.div
          className="md:w-1/2 flex flex-col items-center md:text-2xl md:ml-28 md:items-start text-center md:text-left space-y-5"
          initial={{ opacity: 0, x: 50 }}
          animate={isVisible ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <div className="text-white font-light px-4 py-3 border-1 lg:w-[350px] w-[250px] secondaryFont border-white rounded-xl">
            Estadías en <span className="font-semibold">proyectos únicos</span>
          </div>
          <div className="text-white px-4 font-light py-3 border-1 lg:w-[350px] w-[250px] secondaryFont border-white rounded-xl">
            Bonos de <span className="font-semibold">inversión </span>{" "}
            adicionales
          </div>
          <div className="text-white px-4 font-light py-3 border-1 lg:w-[350px] w-[250px] secondaryFont border-white rounded-xl">
            <span className="font-semibold">Asesorías</span> personalizadas
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PointAccumulateDescription;
