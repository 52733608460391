import React, { useEffect, useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import Select from 'react-tailwindcss-select';
import { fetchCitiesNew, fetchStates } from '../../../../services/regionsServices';
import { isAuthenticated, scrollToSection } from '../../../../helpers/functions';
import { getUserData } from '../../../../services/ownerServices';

interface UpdateDateForminterface {
  setValue: any,
  clearErrors: any,
  register: any,
  errors: any,
}
export const UpdateDateForm: React.FC<UpdateDateForminterface> = ({ setValue, clearErrors, register, errors }) => {
  const [documentTypes] = useState<any>([
    { value: 'CC', label: 'Cédula de ciudadanía' },
    { value: 'PS', label: 'Pasaporte' },
    { value: 'CCE', label: 'Cédula de extranjería' },
  ]);
  const [maxDateValidation, setMaxDateValidation] = useState<any>(null);
  const [phone, setPhone] = useState<any>();
  const [state, setState] = useState<any>(null);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState(null);
  const [citySelectIsLoading, setCitySelectIsLoading] = useState<boolean>(false);
  const [cities, setCities] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userValidationScore, setUserValidationScore] = useState<any>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLowScoreAlert, setShowLowScoreAlert] = useState<boolean>(false);


  const setCustomValue = (id: string, value: any, required?: boolean) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const handlePhoneChange = (value: any) => {
    setCustomValue('phone', value);
    setPhone(value);
    clearErrors('phone');
  }

  const handleState = (val: any) => {
    setCustomValue('state', val.value);
    setState(val);
    clearErrors('state');
  }
  const handleCity = (val: any) => {
    setCustomValue('city', val.value);
    setCity(val);
    clearErrors('city');
  }
  useEffect(() => {
    getDatePickerAgeValidation()
    fetchStates('co').then((res: any) => {
      setStates(res);
    })
  }, []);

  useEffect(() => {
    if (!state) return;
    setCity(null);
    setCities([]);
    setCitySelectIsLoading(true);
    fetchCitiesNew('co', state?.value).then((res: any) => {
      setCities(res);
    }).finally(() => {
      setCitySelectIsLoading(false);
    });
  }, [state]);

  const getDatePickerAgeValidation = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    const ageToValidate = 18;
    const maxDate = new Date(currentYear - ageToValidate, currentMonth, currentDay);
    const maxDateValidation = maxDate.toISOString().split('T')[0];
    setMaxDateValidation(maxDateValidation)
  }

  //get user data
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      getUserData().then((result) => {
        const data = result.data.data
        setValue('firstName', data.firstName)
        setValue('lastName', data.lastName)
        setValue('documentNumber', data.documentNumber)
        setValue('birthDate', data.birthDate)
        setValue('address', data.address)
        setValue('documentType', data.documentType)
        setValue('state', data.state)
        setValue('city', data.city)
        // Code to try to set up the phone and country phone code
        const phone = data.phone || ''
        const formatPhoneNumber = parsePhoneNumber(phone)
        let phoneFormatted = formatPhoneNumber?.nationalNumber
        const countryPhoneCode = formatPhoneNumber?.countryCallingCode
        if (data.countryPhoneCode && data.countryPhoneCode.includes('+')) {
          setValue('phone', `${data.countryPhoneCode}${data.phone}`)
          setPhone(`${data.countryPhoneCode}${data.phone}`)
        } else if (data.countryPhoneCode) {
          setValue('phone', `+${data.countryPhoneCode}${data.phone}`)
          setPhone(`+${data.countryPhoneCode}${data.phone}`)
        } else if (phoneFormatted && countryPhoneCode && countryPhoneCode.includes('+')) {
          setPhone(`${countryPhoneCode}${phoneFormatted}`)
          setValue('phone', `${countryPhoneCode}${phoneFormatted}`)
        } else if (phoneFormatted && countryPhoneCode) {
          setPhone(`+${countryPhoneCode}${phoneFormatted}`)
          setValue('phone', `+${countryPhoneCode}${phoneFormatted}`)
        }
        //
        const identityValidation = data.identityValidations.find((element: any) => element.status === 'success')
        if (identityValidation) {
          setUserValidationScore(identityValidation.score)
          if (identityValidation.score >= 0.9) {
            scrollToSection('identity-validation-section', 200);
          } else {
            setShowLowScoreAlert(true)
          }

        }
      }).catch((error) => {
        console.log(error)
      })
    } else {
      isAuthenticated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Function to initialize the contract sign process


  

  return (
    <>
      <div className="bg-white flex flex-col rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
        <div className="flex items-center gap-3">
          <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http:www.w3.org/2000/svg">
            <rect width="45" height="43" rx="5" fill="#A9DBFF" />
            <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
            <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
          <h2 className="font-bold text-lg">Actualización de datos</h2>
        </div>

        <div className="grid grid-cols-12 gap-y-2 my-3">
          <div className="flex flex-col col-span-6">
            <label htmlFor="firstName" className="text-gray-500 mb-1">Nombre</label>
            <input
              {...register('firstName', { required: true })}
              type="text"
              id='firstName'
              className={`
             border-[1px]
             ${!errors.firstName ? 'border-gray-300' : 'border-red-500'}
             ${errors.firstName ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
             py-2.5
             px-4
             rounded-l-sm
             outline-none
           `} />
            {(errors.firstName)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-6">
            <label htmlFor="lastName" className="text-gray-500 mb-1">Apellido</label>
            <input
              {...register('lastName', { required: true })}
              type="text"
              id='lastName'
              className={`
             border-[1px]
             ${!errors.lastName ? 'border-gray-300' : 'border-red-500'}
             ${errors.lastName ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
             py-2.5
             px-4
             rounded-l-sm
             outline-none
           `} />
            {(errors.lastName)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-12">
            <label htmlFor="documentType" className="text-gray-500 mb-1">Tipo de documento*</label>
            <select
              className="py-2.5 bg-white rounded-none border px-3"
              id="documentType"
              {...register("documentType", { required: true })}
            >
              <option value="" disabled>Tipo de documento</option>
              {
                documentTypes.map((document: any) => (
                  <option key={document.value} value={document.value}>{document.label}</option>
                ))
              }
            </select>
            {(errors.documentType)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-12">
            <label htmlFor="documentNumber" className="text-gray-500 mb-1">Número de identificación</label>
            <input
              {...register('documentNumber', { required: true })}
              type="text"
              id='documentNumber'
              className={`
             border-[1px]
             ${!errors.documentNumber ? 'border-gray-300' : 'border-red-500'}
             ${errors.documentNumber ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
             py-2.5
             px-4
             rounded-l-sm
             outline-none
           `} />
            {(errors.documentNumber)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-12">
            <label htmlFor="birthDate" className="text-gray-500 mb-1">Fecha de nacimiento</label>
            <input
              {...register('birthDate', { required: true })}
              type="date"
              id='birthDate'
              max={maxDateValidation}
              className={`
             border-[1px]
             ${!errors.birthDate ? 'border-gray-300' : 'border-red-500'}
             ${errors.birthDate ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
             py-2.5
             px-4
             rounded-l-sm
             outline-none
           `} />
            {(errors.birthDate)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-12">
            <label htmlFor="phone" className="text-gray-500 mb-1">Teléfono*</label>
            <PhoneInput
              className={`
             border-[1px]
             py-2.5
             px-4

             ${errors.phone ? 'border-red-500' : 'border-gray-300'}
           `}
              international
              countryCallingCodeEditable={false}
              defaultCountry='CO'
              labels={es}
              placeholder="Teléfono"
              value={phone}
              onChange={(value: any) => {
                handlePhoneChange(value);
              }}
            />
            {(errors.phone)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>
          <div className="flex flex-col col-span-12">
            <label htmlFor="address" className="text-gray-500 mb-1">Dirección de domicilio*</label>
            <input
              {...register('address', { required: true })}
              type="text"
              id='address'
              className={`
             border-[1px]
             ${!errors.address ? 'border-gray-300' : 'border-red-500'}
             ${errors.address ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
             py-2.5
             px-4
             rounded-l-sm
             outline-none
           `} />
            {(errors.address)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>

          <div className="flex flex-col col-span-6">
            <label htmlFor="documentType" className="text-gray-500 mb-1">Departamento/Estado*</label>
            <Select
              classNames={{
                menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
                ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
                list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
              }}
              primaryColor='violet'
              value={state}
              loading={states.length <= 0}
              options={states.map((res: any) => ({ value: res.id, label: res.name }))}
              placeholder="Departamento/Estado"
              onChange={(val: any) => {
                handleState(val)
              }}
            />
            {(errors.state)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>

          <div className="flex flex-col col-span-6">
            <label htmlFor="documentType" className="text-gray-500 mb-1">Ciudad*</label>
            <Select
              classNames={{
                menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
                ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
                list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
              }}
              primaryColor='violet'
              value={city}
              loading={citySelectIsLoading}
              options={cities.map((res: any) => ({ value: res.name, label: res.name }))}
              placeholder="Ciudad"
              onChange={(val: any) => {
                handleCity(val)
              }}
            />
            {(errors.city)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          </div>

        </div>

      </div>
    </>
  )
}
