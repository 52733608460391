import React from 'react'
// import { SecondaryMarket } from '../../components/SecondaryMarket'
import { UtilitiesCard } from '../../components/UtilitiesCard'
// import { ExperienceBonusCard } from '../../components/ExperienceBonusCard'
// import reportIcon from '../../../../assets/img/report-icon.svg';
export const InvestmentDetailMain = ({
  project,
  setShowPayoutForm,
  setShowReinvestmentForm,
  totalNoRequestAmount,
  totalPendingAmount,
  payoutDividendsLoading
}: any) => {
  return (
    <div className='block space-y-5 md:space-y-0 justify-between md:flex'>
    {/* <div className="flex-1 md:mr-4 rounded-lg bg-white">
      {
        project.title === "nido de agua" ?
          <>
            <button
              id='downloadReport'
              className="
                  bg-[#E4E4E4]
                  hover:bg-[#E4E4E4]/80
                    w-[155px]
                    rounded 
                    py-3
                    m-2
                    font-medium 
                    text-sm
                    text-left
                    cursor-pointer
                    duration-300 
                    ease-in-out
                    flex
                    justify-center
                    items-center
                  "
              onClick={() => window.open('https://docsend.com/view/wqnxiwjyvppkhkh3', "blank")}
            >
              <img className='mr-2' width={30} src={reportIcon} alt="" />
              Reporte <br /> 2023 (1 -2)
            </button>
          </>
          :
          <>
            <ExperienceBonusCard />
          </>

      }
    </div> */}

    {
      project?.title?.includes('indie') &&
      <div className='flex-1 md:mx-2 rounded-xl bg-white max-w-xl -translate-y-5 lg:translate-y-0'>
        <UtilitiesCard
          setShowReinvestmentForm={setShowReinvestmentForm}
          setShowPayoutForm={setShowPayoutForm}
          totalNoRequestAmount={totalNoRequestAmount}
          totalPendingAmount={totalPendingAmount}
          payoutDividendsLoading={payoutDividendsLoading}
        />
      </div>
    }
    {/* {
      project?.title?.includes('indie') &&
      <div className='flex-1 md:ml-4 rounded-lg bg-white'>
        <SecondaryMarket />
      </div>
    } */}
  </div>
  )
}
