

import React, { useEffect, useState } from 'react'
import { PaymentMethodSelect } from '../../components/PaymentMethodSelect';
import { PseForm } from '../../../Checkout/Payment/components/PseForm';
import { FieldValues, useForm } from 'react-hook-form';
import { CreditCardForm } from '../../../Checkout/Payment/components/CreditCardForm';
import PaymentResponseModal from '../sections/PayoutResponseModal';
import { useNavigate } from 'react-router-dom';
import { createDividendReinvestmentRequest } from '../../../../services/paymentDataServices';
import { urls } from '../../../../helpers/urls';
import { createOrUpdateDividendReinvestment, getCart } from '../../../../services/cartService';

interface PaymentMethodInterface {
    paymentMethod: number
    setPaymentMethod: React.Dispatch<React.SetStateAction<number>>;
}

export const PaymentMethod: React.FC<PaymentMethodInterface> = ({ paymentMethod, setPaymentMethod }) => {

    const [showForm, setShowForm] = useState(false);
    const [modalInfo, setModalInfo] = useState<{ title: string, description: string, style: 'success' | 'error' }>({
        title: '',
        description: '',
        style: 'success'
    });
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cartId] = useState(localStorage.getItem('cartId') ? localStorage.getItem('cartId')! : null);
    const navigate = useNavigate()

    const {
        register: registerPse,
        handleSubmit: handleSubmitPse,
        formState: {
            errors: errorsPse,
        },
        setValue: setValuePse,
        setError: setErrorPse,
        clearErrors: clearErrorsPse,
        getValues: getValuesPse
    } = useForm<FieldValues>({
        defaultValues: {
            financialInstitutionCode: '',
            fullName: '',
            address: '',
            userType: '',
            documentType: '',
            documentNumber: '',
            phone: '',
            email: ''
        }
    })
    const {
        register: registerCard,
        handleSubmit: handleSubmitCard,
        formState: {
            errors: errorsCard,
        },
        setValue: setValueCard,
        setError: setErrorCard,
        clearErrors: clearErrorsCard,
        getValues: getValuesCard
    } = useForm<FieldValues>({
        defaultValues: {
            cardNumber: '',
            cardHolder: '',
            expDate: '',
            cvc: '',
            address: '',
            documentType: '',
            documentNumber: '',
            phone: '',
            email: ''
        }
    })

    const handlePaymentMethod = (selectedMethod: number) => {
        setPaymentMethod(selectedMethod);
    }
    useEffect(() => {

    }, [])

    const goToPayment = async () => {
        const cartId = localStorage.getItem('cartId');
        if (cartId) {
            getCart(cartId).then(async (resp) => {
                await createOrUpdateDividendReinvestment({
                    ...resp?.data.data,    
                    paymentMethod: paymentMethod === 1 ? 'CARD' : paymentMethod === 2 ? 'PSE' : null,
                })
            })
        }
        setShowForm(true)
    }

    const setCustomValueCard = (id: string, value: any, required: boolean) => {
        setValueCard(id, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })
    }

    const setCustomValuePse = (id: string, value: any, required: boolean) => {
        setValuePse(id, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })
    }

    const submitCardForm = async (data: any) => {
        if (!getValuesCard("documentType")) {
            setErrorCard('documentType', { type: 'required' });
            return
        }
        if (!getValuesCard("phone")) {
            setErrorCard('phone', { type: 'required' });
            return
        }
        const token = localStorage.getItem('token');
        if (!token) {
            setOpenModal(true)
            setModalInfo({
                title: '¡No tienes una sesión activa!',
                description: 'Por favor inicia sesión de nuevo',
                style: 'error'
            })
            setTimeout(() => {
                setOpenModal(true)
                navigate('/login?redirect=/nido')
            }, 3000)
            return
        };


        // Extract month and year from expDate
        const expDate = data.expDate.split('/');

        const expMonth = expDate[0]
        const expYear = expDate[1]


        const requestBody = {
            cartId: cartId,
            customerEmail: data.email,
            address: data.address,
            customerFullName: data.cardHolder,
            customerDocumentType: data.documentType,
            customerDocumentNumber: data.documentNumber,
            customerPhoneNumber: data.phone,
            cardNumber: data.cardNumber,
            expMonth: expMonth,
            expYear: expYear,
            cvc: data.cvc
        }

        try {
            setIsLoading(true);
            const result = await createDividendReinvestmentRequest(requestBody)
            if (result) {
                const transactions = result;
                if (transactions.success) {
                    navigate(`/payment/approved?id=${transactions.data?.transactionResponse?.id}`);
                } else if (transactions.data?.transactionResponse?.status === 'PENDING') {
                    setOpenModal(true)
                    setModalInfo({
                        title: '¡Tu pago esta pendiente de aprobación!',
                        description: 'Tu pago esta pendiente por confirmar. Por favor revisa tu dashboard en unos minutos. Si tienes alguna duda, por favor contáctanos.',
                        style: 'success'
                    })
                } else if (transactions.data?.transactionResponse?.status === 'ERROR') {
                    setOpenModal(true)
                    setModalInfo({
                        title: '¡Ha ocurrido un error!',
                        description: `Ha ocurrido un error con tu pago. Intenta de nuevo \n ${result.data?.transactionResponse?.status_message}`,
                        style: 'error'
                    })
                } else if (transactions.data?.transactionResponse?.status === 'DECLINED') {
                    setOpenModal(true)
                    setModalInfo({
                        title: '¡Pago declinado!',
                        description: `Tu pago a sido declinado. \n ${transactions.data?.transactionResponse?.status_message}`,
                        style: 'error'
                    })
                } else {
                    setOpenModal(true)
                    setModalInfo({
                        title: '¡Algo salio mal!',
                        description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${transactions?.data?.transactionResponse?.message}`,
                        style: 'error'
                    })
                    setIsLoading(false);
                    return
                }
            }
            else {
                setOpenModal(true)
                setModalInfo({
                    title: '¡Algo salio mal!',
                    description: `Ha ocurrido un error creando la reinversión. ¡Inténtalo de nuevo!`,
                    style: 'error'
                })
            }
            setIsLoading(false);
        } catch (error: any) {
            setOpenModal(true)
            setModalInfo({
                title: '¡Algo salio mal!',
                description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${error.response.data.message}`,
                style: 'error'
            })
            setIsLoading(false);
            console.log(error)
        }
    }

    const submitPseForm = async (data: any) => {
        if (!getValuesPse("financialInstitutionCode")) {
            setErrorPse('financialInstitutionCode', { type: 'required' });
            return
        }
        if (!getValuesPse("userType")) {
            setErrorPse('userType', { type: 'required' });
            return
        }
        if (!getValuesPse("documentType")) {
            setErrorPse('documentType', { type: 'required' });
            return
        }
        if (!getValuesPse("phone")) {
            setErrorPse('phone', { type: 'required' });
            return
        }
        const token = localStorage.getItem('token');
        if (!token) {
            setOpenModal(true)
            setModalInfo({
                title: '¡No tienes una sesión activa!',
                description: 'Por favor inicia sesión de nuevo',
                style: 'error'
            })
            setTimeout(() => {
                setOpenModal(true)
                navigate('/login')
            }, 2000)
            return
        };


        const requestBody = {
            cartId: cartId,
            financialInstitutionCode: data.financialInstitutionCode,
            redirectUrl: `${urls.URL_FRONT}payment/approved`,
            customerEmail: data.email,
            address: data.address,
            customerFullName: data.fullName,
            customerUserType: data.userType,
            customerDocumentType: data.documentType,
            customerDocumentNumber: data.documentNumber,
            customerPhoneNumber: data.phone,
        }
        setIsLoading(true);

        try {
            //TODO: Agregar URL verdadera
            const result = await createDividendReinvestmentRequest(requestBody)
            const transaction = result;
            if (!transaction.success) {
                setOpenModal(true)
                setModalInfo({
                    title: '¡Algo salio mal!',
                    description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo \n ${transaction?.message}`,
                    style: 'error'
                })
                setIsLoading(false);
                return
            }
            let redirectUrl = transaction?.data?.transactionResponse?.payment_method?.extra?.async_payment_url;

            if (!redirectUrl) {
                setOpenModal(true)
                setModalInfo({
                    title: '¡Algo salio mal!',
                    description: 'Ha ocurrido un error redirigiendo a PSE. ¡Inténtalo de nuevo!',
                    style: 'error'
                })
                setIsLoading(false);
                return
            }
            setIsLoading(false);
            return window.location.href = redirectUrl;
        } catch (error: any) {
            setOpenModal(true)
            setModalInfo({
                title: '¡Algo salio mal!',
                description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${error?.response?.data?.message}`,
                style: 'error'
            })
            setIsLoading(false);
            console.log(error)
        }

    }

    const onErrorCard = (errors: any, event: any) => {
        if (!getValuesCard("documentType")) {
            setErrorCard('documentType', { type: 'required' });
        }
        if (!getValuesCard("phone")) {
            setErrorCard('phone', { type: 'required' });
        }
    }
    const onErrorPse = (errors: any, event: any) => {
        if (!getValuesPse("financialInstitutionCode")) {
            setErrorPse('financialInstitutionCode', { type: 'required' });
        }
        if (!getValuesPse("userType")) {
            setErrorPse('userType', { type: 'required' });
        }
        if (!getValuesPse("documentType")) {
            setErrorPse('documentType', { type: 'required' });
        }
        if (!getValuesPse("phone")) {
            setErrorPse('phone', { type: 'required' });
        }
    }

    return (
        <>
            <div className="w-full">
                <div className='m-5'>
                    {
                        showForm && paymentMethod === 1 &&
                        <CreditCardForm
                            errors={errorsCard}
                            register={registerCard}
                            setValue={setValueCard}
                            setCustomValue={setCustomValueCard}
                            clearErrors={clearErrorsCard}
                        />
                    }

                    {
                        showForm && paymentMethod === 2 &&
                        <div>
                            <PseForm
                                errors={errorsPse}
                                register={registerPse}
                                setValue={setValuePse}
                                setCustomValue={setCustomValuePse}
                                clearErrors={clearErrorsPse}
                            />
                        </div>
                    }
                </div>
                {!showForm ?
                    <div className="px-10 pb-2 w-full">
                        <PaymentMethodSelect handleMethodSelected={handlePaymentMethod} selectedMethod={paymentMethod} />
                        <div className='w-fullx '>
                            <button
                                disabled={paymentMethod === 0}
                                onClick={goToPayment}
                                className='bg-black text-white font-bold text-base px-5 py-2 rounded-full w-full'
                            >Siguiente</button>
                        </div>
                    </div> :
                    <div className='px-10 pb-5 w-full'>

                        {
                            paymentMethod === 2 &&
                            <button
                                disabled={isLoading}
                                onClick={handleSubmitPse(submitPseForm, onErrorPse)}
                                className={`
                        w-full
                        rounded-full
                        bg-black
                        text-white
                        font-bold
                        px-6
                        py-2
                        text-center
                        ${isLoading ? 'opacity-50' : ''}
                      `}>
                                {
                                    isLoading
                                        ? <div><svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                            <span>Cargando...</span></div>
                                        : <span>Invertir</span>
                                }
                            </button>
                        }
                        {
                            paymentMethod === 1 &&
                            <button
                                disabled={isLoading}
                                onClick={handleSubmitCard(submitCardForm, onErrorCard)}
                                className={`
                        w-full
                        rounded-full
                        bg-black
                        text-white
                        font-bold
                        px-6
                        py-2
                        text-center
                        ${isLoading ? 'opacity-50' : ''}
                      `}>
                                {
                                    isLoading
                                        ? <div><svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                            <span>Cargando...</span></div>
                                        : <span>Invertir</span>
                                }
                            </button>
                        }
                    </div>
                }


            </div>
            <PaymentResponseModal
                isOpen={openModal}
                title={modalInfo.title}
                description={modalInfo.description}
                handleOpenModal={setOpenModal}
                modalStyle={modalInfo.style}
                buttonLabel='Aceptar'
            />
        </>
    )
}