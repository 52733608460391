import React from 'react';
import { ProjectCapacity } from './';
import { CapitalizeFirstLetter, CapitalizeWords } from '../../../../utils/otherFormat';
import '../../styles/index.scss'
interface ProjectTitleCardInterface {
    title: string,
    totalSpots: number,
    availableSpots: number,
    city: string,
    country: string
}
export const ProjectTitleCard: React.FC<ProjectTitleCardInterface> = ({ title, availableSpots, totalSpots, city, country }) => {
    return (
        <>
            <div className='mt-4 flex items-start justify-between'>
                <div className=''>
                    <h2 className='secondaryFont text-4xl font-bold'>{CapitalizeWords(title)}</h2>
                    <h3 className='special-city-up secondaryFont text-2xl' ><strong>{CapitalizeFirstLetter(city)}</strong>, {CapitalizeFirstLetter(country)}</h3>
                </div>
                <div className='hidden md:block'>
                    <ProjectCapacity availableSpots={availableSpots} totalSpots={totalSpots} />
                </div>
            </div>
            <h3 className='special-city-down hidden secondaryFont text-xl flex-wrap' ><strong>{CapitalizeFirstLetter(city) + ","}</strong>{CapitalizeFirstLetter(country)}</h3>
        </>
    )
}
