export const ProjectSkeleton = () => {
    return (
        <div className='w-full px-[5%] flex justify-between bg-white py-5'
        style={{ background: `linear-gradient(to bottom, white 45%, #DFDFFF 45%)` }}>
            <div className=' h-[580px] skeleton w-[30%] rounded-3xl'></div>
            <div className=' h-[580px] skeleton w-[30%] rounded-3xl'></div>
            <div className=' h-[580px] skeleton w-[30%] rounded-3xl'></div>
        </div>
    )
}
