import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import NavbarDashboard from "./NavbarDashboard"
import PageNotFoundDashboard from "./PageNotFoundDashboard";
import { getInvestmentDetails } from "../../../services/investordDashboardServices";
import { useEffectOnce } from "../../../helpers/hooks";
import { useEffect, useState } from "react";
import { PageDashboardSkeleton } from "./PageDashboardSkeleton";
import { cloudbedsDashboardInfoInterface, InvestmentDetailsInterface } from "../interfaces";

const InvestorDashboardLayout = () => {
    const { projectId } = useParams();
    const [investmentDetails, setInvestmentDetails] = useState<InvestmentDetailsInterface>()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [cloudbedsDashboardInfo, setCloudbedsDashboardInfo] = useState<cloudbedsDashboardInfoInterface>({
        transactions: [],
        occupation: [],
        rates: [],
        days: { label: 'Últimos 5 días', value: '4' },
        graphSelected: 'transactions',
        charts: {
            transactions: false,
            occupation: false,
            rates: false
        },
        alreadyShowScrollMessage: false
    })
    if (!projectId) return <PageNotFoundDashboard />
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffectOnce(() => {
        const fetchProjectDetails = async () => {
            try {
                setIsLoading(true);
                const response = await getInvestmentDetails(projectId);
                if (response === undefined || response?.data?.status === false) {
                    navigate("/dashboard");
                } else {
                    setInvestmentDetails(response.data.data)
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
                navigate("/dashboard");
            }
            finally {
                setIsLoading(false);
            }
        };

        fetchProjectDetails();
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const subscriptionPlanEnabled = investmentDetails?.features?.some(
            (feature) => feature.name === 'SUBSCRIPTION_PLAN' && feature.enabled
        );

        const currentPath = location.pathname;
        const isSubscriptionRoute = currentPath.includes('/dashboard/subscription');

        if (investmentDetails && !subscriptionPlanEnabled && isSubscriptionRoute) {
            navigate("/dashboard");
        }
    }, [investmentDetails, investmentDetails?.features, location.pathname, navigate]);
    const isDividendRoute = location.pathname.includes('/dividend/');
    return (
        <div className="layout-content">
            {!isDividendRoute && (
                <NavbarDashboard projectId={projectId} features={investmentDetails?.features} />
            )}
            <div className="mt-6">
                {isLoading ? (
                    <PageDashboardSkeleton />
                ) : (
                    <Outlet context={{ isLoading, investmentDetails, cloudbedsDashboardInfo, setCloudbedsDashboardInfo, setInvestmentDetails }} />
                )}
            </div>
        </div>
    )
}

export default InvestorDashboardLayout
