import { useOutletContext } from "react-router-dom";
import { InvestmentDetailsInterface } from "../../interfaces";
import { useEffect, useRef, useState } from "react";
import { ValuationChart } from "./ValuationChart";
import { DividendsChart } from "./DividendsChart";
import { formatNumberWithCommas } from "../../../../utils";
import { NoDividendsChart } from "./NoDividendsChart";
import { ScrollMessageValorization } from "./ScrollMessageValorization";

interface ValuationDashboardOutletContext {
    investmentDetails: InvestmentDetailsInterface;
}

const RENTS = 'rents'
const DIVIDENDS = 'dividends'
const VALUATION = 'valuation'

export const ValuationDashboard = () => {
    const [graphSelected, setGraphSelected] = useState(VALUATION)
    const { investmentDetails } = useOutletContext<ValuationDashboardOutletContext>();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (isMobile) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const entry = entries[0];
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                },
                {
                    threshold: 0.1,
                }
            );

            if (elementRef.current) {
                observer.observe(elementRef.current);
            }

            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                if (elementRef.current) observer.disconnect();
            };
        }
    }, [isMobile]);
    return (
        <div className="w-full h-auto bg-white rounded-lg flex flex-col py-4">
            <div className="flex flex-col-reverse md:flex-row px-8 py-3 justify-between gap-4">

                <div className="w-[90%] md:w-auto z-10">
                    {graphSelected === VALUATION
                        ? <>

                            <div className="w-full text-center">
                                <span className="text-4xl primaryFont font-bold">Valorización: </span>
                                <span className="text-4xl font-medium numberFont">${formatNumberWithCommas(investmentDetails?.appreciation)}</span>
                                <span className="text-4xl font-medium numberFont ">&nbsp;({formatNumberWithCommas(investmentDetails?.appreciationInvestmentPercentage)}%)</span>
                            </div>

                        </>
                        : graphSelected === DIVIDENDS
                            ? <>
                                <div className="w-full text-center">
                                    <span className="text-4xl primaryFont font-bold">Utilidades: </span>
                                    <span className="text-4xl font-medium numberFont">${formatNumberWithCommas(investmentDetails?.totalUtilities)}</span>
                                    <span className="text-4xl font-medium numberFont ">&nbsp;({formatNumberWithCommas(investmentDetails?.utilitiesPercentage)}%)</span>
                                </div>
                            </>
                            : graphSelected === RENTS
                                ? <>
                                    <div className="w-full text-center">
                                        <span className="text-4xl primaryFont font-bold">Rentabilidad: </span>
                                        <span className="text-4xl font-medium numberFont">${formatNumberWithCommas(investmentDetails?.appreciation + investmentDetails?.totalUtilities)}</span>
                                        <span className="text-4xl font-medium numberFont ">&nbsp;({formatNumberWithCommas(investmentDetails?.appreciationInvestmentPercentage + investmentDetails?.utilitiesPercentage)}%)</span>
                                    </div>
                                </>
                                : <></>}
                </div>

                <div className="flex w-full flex-col md:flex-row gap-2  justify-center md:w-auto md:justify-end z-10  md:mt-0 ">

                    <div className=' flex gap-2 justify-center md:w-auto md:justify-end mt-2 md:!mt-0'>
                        <button
                            className={`flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === VALUATION ? 'bg-[#221feb] text-white' : 'bg-gray-100 text-[#221feb] '}`}
                            onClick={() => setGraphSelected(VALUATION)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14" className="mr-2 hidden md:flex">
                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M.5.5v13h13" /><path d="M3.5 6.5L6 9l4-6l3.5 2.5" />
                                </g>
                            </svg>
                            Valorización
                        </button>

                        <button
                            className={`flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === DIVIDENDS ? 'bg-[#221feb] text-white' : 'bg-[#dfdfff] text-[#221feb] '}`}
                            onClick={() => setGraphSelected(DIVIDENDS)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024" className="mr-2 hidden md:flex">
                                <path fill="currentColor" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372" />
                                <path fill="currentColor" fill-opacity="0.15" d="M426.6 410.3c0 25.4 15.7 45.1 49.5 57.3c4.7 1.9 9.4 3.4 15 5v-124c-37 4.7-64.5 25.4-64.5 61.7m116.5 135.2c-2.9-.6-5.7-1.3-8.8-2.2V677c42.6-3.8 72-27.3 72-66.4c0-30.7-15.9-50.7-63.2-65.1" />
                                <path fill="currentColor" fill-opacity="0.15" d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372m22.4 589.2l.2 31.7c0 4.5-3.6 8.1-8 8.1h-28.4c-4.4 0-8-3.6-8-8v-31.4c-89-6.5-130.7-57.1-135.2-112.1c-.4-4.7 3.3-8.7 8-8.7h46.2c3.9 0 7.3 2.8 7.9 6.6c5.1 31.8 29.9 55.4 74.1 61.3V534l-24.7-6.3c-52.3-12.5-102.1-45.1-102.1-112.7c0-73 55.4-112.1 126.2-119v-33c0-4.4 3.6-8 8-8h28.1c4.4 0 8 3.6 8 8v32.7c68.5 6.9 119.8 46.9 125.9 109.2a8.1 8.1 0 0 1-8 8.8h-44.9c-4 0-7.4-2.9-7.9-6.9c-4-29.2-27.5-53-65.5-58.2v134.3l25.4 5.9c64.8 16 108.9 47 109 116.4c0 75.2-56 117.1-134.3 124" />
                                <path fill="currentColor" d="m559.7 488.8l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2c.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8c-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119c0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3c-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7c4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124c-.1-69.4-44.2-100.4-109-116.4m-68.6-16.2c-5.6-1.6-10.3-3.1-15-5c-33.8-12.2-49.5-31.9-49.5-57.3c0-36.3 27.5-57 64.5-61.7zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2c47.3 14.4 63.2 34.4 63.2 65.1c0 39.1-29.4 62.6-72 66.4" />
                            </svg>
                            Utilidades
                        </button>

                        {/* <button
                            className={`flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === RENTS ? 'bg-[#221feb] text-white' : 'bg-[#dfdfff] text-[#221feb] '}`}
                            onClick={() => setGraphSelected(RENTS)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14" className="mr-2 hidden md:flex">
                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M.5.5v13h13" /><path d="M3.5 6.5L6 9l4-6l3.5 2.5" />
                                </g>
                            </svg>
                            Rentas
                        </button> */}
                    </div>

                </div>
            </div>
            <div className="relative w-[95%] mx-auto">
                <div ref={elementRef}>
                    {isMobile && isVisible && <ScrollMessageValorization />}
                </div>
                {
                    graphSelected === VALUATION && investmentDetails?.appreciatedInvestmentPerMonth.length > 0 ? (
                        <ValuationChart appreciatedInvestmentPerMonth={investmentDetails?.appreciatedInvestmentPerMonth} />
                    ) : graphSelected === DIVIDENDS && investmentDetails?.appreciatedInvestmentPerMonth.length > 0 ? (
                        <>
                            {investmentDetails?.totalUtilities > 0 ? <DividendsChart appreciatedInvestmentPerMonth={investmentDetails?.appreciatedInvestmentPerMonth} /> : <NoDividendsChart />}
                        </>
                    ) : graphSelected === RENTS && investmentDetails?.appreciatedInvestmentPerMonth.length > 0 ? (
                        <ValuationChart appreciatedInvestmentPerMonth={investmentDetails?.appreciatedInvestmentPerMonth} />
                    ) : null
                }
            </div>
        </div >
    )
}
