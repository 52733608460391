import React, { useEffect, useState } from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { getDividendPayout } from '../../../services/DividenPayoutService';
import { PageDashboardSkeleton } from './PageDashboardSkeleton';
import { DividendPayoutInterface, InvestmentDetailsInterface } from '../interfaces';

const defaultValues = {
    totalNoRequestAmount: 0,
    totalPendingAmount: 0,
    noRequestDividendPayouts: [],
    pendingDividendPayouts: [],
    dividendHistory: []
}

interface DividendDashboardLayoutOutletContext {
    investmentDetails: InvestmentDetailsInterface
  }
  
export const DividendDashboardLayout = () => {
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [dividendPayouts, setDividendPayouts] = useState<DividendPayoutInterface>(defaultValues)
    useEffect(() => {
        if (projectId) {
            setIsLoading(true)
            getDividendPayout(projectId).then((response: any) => {
                setDividendPayouts(response?.data?.data)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [projectId])
    const { investmentDetails } = useOutletContext<DividendDashboardLayoutOutletContext>();

    return (
        <>
            {isLoading ? (<PageDashboardSkeleton />) : (<Outlet context={{ ...dividendPayouts, investmentDetails }} />)}
        </>
    );

}
