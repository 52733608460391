import React, { useEffect, useRef, useState } from 'react';
import amabassador_img_building_gray from "../../../assets/img/ambassadors/ambassador-img-building-gray.png";
import icon_white_ambassadors_chart from "../../../assets/img/ambassadors/icon-white-ambassadors-chart.png";
import icon_white_ambassadors_script from "../../../assets/img/ambassadors/icon-white-ambassadors-script.png";
import icon_white_ambassadors_union from "../../../assets/img/ambassadors/icon-white-ambassadors-union.png";
import ambassadors_tasks_icons from "../../../assets/img/ambassadors/ambassador-icons-tasks-white.png";

import amabassador_icon_blue from "../../../assets/img/ambassadors/ambassador_icon_blue.png";
import img_camilo_ambassador from "../../../assets/img/ambassadors/img-camilo-amabassador.png";
import iconPlay from "../../../assets/img/nido/header/icon-play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper';
import { motion } from 'framer-motion';
export default function AmbassadorStepsToRefer() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsVideoPlaying(true);
  };

  useEffect(() => {
    const currentSection = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="relative top-14 w-full h-auto bg-[#ADADAD]">
      <div className="absolute top-[1%] left-1/2 z-20 transform -translate-x-1/2 -translate-y-[50px] md:-translate-y-[110px]">
        <img
          src={amabassador_icon_blue}
          alt="Ícono Azul"
          className="w-24 h-24 md:w-52 md:h-52"
        />
      </div>

      <motion.div
        className="relative flex flex-col items-center justify-center text-center z-10 py-16 mb-11 px-4"
        initial={{ opacity: 0, y: 50 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <h1 className="primaryFont text-[60px] md:text-[90px] lg:text-[140px] font-semibold text-white mb-4">
          Pasos Para <span className="text-[#5352F6]">Referir</span>
        </h1>
      </motion.div>

      <div className="px-4 md:hidden">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Autoplay , Pagination]}
          
          pagination={{ clickable: true }}
          className="w-full h-56"
          autoplay={{
            delay: 3500, 
            disableOnInteraction: false, 
          }}
        >
          <SwiperSlide>
            <div className="flex flex-col items-center text-center">
              <div className="w-[100px] h-[100px] border-2 border-white rounded-full flex justify-center items-center mb-4">
                <img
                  src={icon_white_ambassadors_union}
                  alt="Icono de compartir"
                  className="w-[50px] h-[50px]"
                />
              </div>
              <p className="md:text-2xl text-white secondaryFont font-light">
                Comparte tu código o URL en redes sociales.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center text-center">
              <div className="w-[100px] h-[100px] border-2 border-white rounded-full flex justify-center items-center mb-4">
                <img
                  src={icon_white_ambassadors_script}
                  alt="Icono de guías"
                  className="w-[50px] h-[50px]"
                />
              </div>
              <p className="md:text-2xl text-white secondaryFont font-light">
                Ayuda a tus referidos a conocer LOKL con nuestras guías.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center text-center">
              <div className="w-[100px] h-[100px] border-2 border-white rounded-full flex justify-center items-center mb-4">
                <img
                  src={icon_white_ambassadors_chart}
                  alt="Icono de recompensas"
                  className="w-[50px] h-[50px]"
                />
              </div>
              <p className="md:text-2xl text-white secondaryFont font-light">
                Ve cómo crecen tus recompensas con cada inversión exitosa.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="hidden md:flex flex-col items-center px-4">
      <motion.img
          src={ambassadors_tasks_icons}
          alt="Ambassadors Tasks Icons"
          className="w-full max-w-7xl h-auto mb-8"
          initial={{ opacity: 0, y: 50 }}
          loading='lazy'
          animate={isVisible ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.4 }}
        />

<motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-14 text-white text-center"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={isVisible ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <p className="secondaryFont font-light text-4xl">
            Comparte tu código o <br /> URL en redes sociales.
          </p>
          <p className="secondaryFont font-light text-4xl">
            Ayuda a tus referidos a <br /> conocer LOKL con nuestras <br /> guías.
          </p>
          <p className="secondaryFont font-light text-4xl">
            Ve cómo crecen tus <br /> recompensas con cada <br /> inversión exitosa.
          </p>
        </motion.div>
      </div>

      <motion.h2 className="primaryFont text-white text-center text-3xl md:mt-40 md:mb-10 mt-16 md:text-6xl font-semibold mb-8"
       initial={{ opacity: 0, scale: 0.9 }}
       animate={isVisible ? { opacity: 1, scale: 1 } : {}}
       transition={{ duration: 0.8, delay: 0.6 }}>
        ¡Así funcionamos!
      </motion.h2>

      <div className="flex flex-col items-center w-full mt-16 mb-16">
        {isVideoPlaying ? (
          <motion.div
          className="w-full max-w-[320px] sm:max-w-[480px] md:max-w-6xl aspect-video bg-black rounded-lg overflow-hidden"
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : {}}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          <iframe
            className="w-full h-full"
            src="https://www.youtube.com/embed/ECcqmGXRrns?autoplay=1&mute=0"
            title="Lokl Video"
            allow="autoplay; encrypted-media"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </motion.div>
        ) : (
          <div className="relative w-full max-w-[320px] sm:max-w-[480px] md:max-w-6xl">
            <img
              src={img_camilo_ambassador}
              alt="Video Camilo Ambassador"
              className="w-full h-full object-cover rounded-lg"
              loading="lazy"
            />
            <button
              onClick={handlePlayClick}
              className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
              id="btn-play-video-stepstorefer"
            >
              <img
                src={iconPlay}
                alt="Reproducir video"
                className="w-16 h-16 md:w-20 md:h-20"
              />
            </button>
          </div>
           )}
        </div>

      <div className="relative">
        <img
          src={amabassador_img_building_gray}
          alt="Imagen de edificio"
          loading='lazy'
          className="w-full h-[300px] object-cover"
        />
        <div className="absolute inset-0 flex justify-center items-center">


          <a 
           id="btn-become-ambassador"
           href="https://docs.google.com/forms/d/1cbsePiYDLggajgEK_s2rF5eBdwXb3XvXTSjYrPnpCwQ/edit" 
           target="_blank" 
           rel="noopener noreferrer" 
          className="bg-[#5352F6] text-white no-underline text-xl md:text-4xl font-extralight primaryFont py-2 md:py-3 px-10 rounded-full">
            QUIERO SER <span className="font-bold primaryFont">EMBAJADOR</span>
          </a>
        </div>
      </div>
    </div>
  );
}