
import React, { useState } from 'react'
import { numberWithCommas } from '../../../../helpers/functions';
import { Project } from '../interfaces/interfaces';
// import PaymentResponseModal from './PayoutResponseModal'

interface ReinvestSummaryProps {
    totalInvestment: number,
    subtotal: number,
    paymentMethodFee: number,
    installments: number,
    total: number,
    project: Project,
    totalNoRequestAmount: number
}

export const ReinvestmentPaymentSummary: React.FC<ReinvestSummaryProps> = ({
    totalInvestment,
    subtotal,
    paymentMethodFee,
    installments,
    total,
    project,
    totalNoRequestAmount
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <div className="bg-white rounded-md w-full py-4 px-10 max-w-2xl flex flex-col justify-center items-center">
            <div className="relative w-full h-auto max-h-[300px] my-4">
                {project.imageURL ? (
                    <>
                        {!imageLoaded && (
                            <div className="w-full h-[200px] skeleton rounded-lg" />
                        )}
                        <img
                            src={project.imageURL}
                            alt={`Imagen del proyecto ${project.label}`}
                            className={`w-full object-cover max-h-[200px] transition-opacity rounded-lg ${imageLoaded ? 'opacity-100' : 'opacity-0'
                                }`}
                            loading="lazy"
                            onLoad={() => setImageLoaded(true)}
                        />
                    </>
                ) : (
                    <div className="w-full h-[200px] flex items-center justify-center bg-gray-100 border border-gray-300 text-gray-600">
                        Cargando imagen del proyecto
                    </div>
                )}
            </div>
            <div className="flex items-center gap-3 w-full">
                <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="45" height="43" rx="5" fill="#49E9FF" />
                    <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
                    <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <h2 className="font-bold text-lg">Resumen de la inversion</h2>
            </div>
            <div className="w-full">
                <h3 className="text-lg font-bold mb-2 mt-3">Pago por dividendos</h3>
                <p className='text-gray-600 font-roboto text-lg'>Proyecto: {project.label.charAt(0).toUpperCase() + project.label.slice(1)}</p>
                <p className='text-gray-600 font-roboto text-lg mb-3'>Pago único</p>
                <hr className="text-gray-400" />
                <div>
                    <div className="my-3">
                        <div className="flex justify-between text-green-400">
                            <p className="font-bold text-xl mb-2">Inversión:</p>
                            <p className="font-lato font-semibold text-xl">${numberWithCommas(totalInvestment)} <span className="text-sm">COP</span></p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-600 font-roboto text-lg">Pago con dividendos:</p>
                            <p className="font-lato font-semibold text-xl">${numberWithCommas(totalNoRequestAmount)} <span className="text-sm">COP</span></p>
                        </div>
                    </div>
                    <hr className="text-gray-400" />
                    <div className="my-3">
                        <div className="flex justify-between">
                            <p className="text-gray-600 font-roboto text-lg">Pago excedente</p>
                            <p className="font-lato font-semibold text-xl">${numberWithCommas(subtotal)} <span className="text-sm">COP</span></p>
                        </div>
                        <div className="flex justify-between">
                            <div className='w-1/2'>
                                <p className="text-gray-600 font-roboto text-lg">Impuestos y tarifas</p>
                                <div className='h-[30px]'>
                                    {

                                        paymentMethodFee > 0 && (
                                            <p className="text-sm">Tarifa pago con tarjeta de crédito +2.5%</p>
                                        )

                                    }
                                </div>
                            </div>
                            <p className="className='w-1/2' font-lato font-semibold text-xl">${numberWithCommas(paymentMethodFee)} <span className="text-sm">COP</span></p>
                        </div>
                    </div>
                    <hr className="text-gray-400" />
                    <div className="flex justify-between my-3">
                        <p className="font-semibold text-lg">Total excedente</p>
                        <p className="font-lato font-semibold text-xl">${numberWithCommas(subtotal + paymentMethodFee)} <span className="text-sm">COP</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

