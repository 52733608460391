import { formatNumberWithCommas } from "../../../../utils";

interface ValuationCardInterface {
  metric?: number;
  title: string;
}

export const ValuationCard = ({ metric, title }: ValuationCardInterface) => {
  return (
    <div className="h-[80px] bg-[#dfddeb] rounded-lg flex flex-col justify-center items-center sm:w-full md:w-[180px]">
      <span className="numberFont text-[#000000] font-bold text-xl sm:text-lg md:text-xl">{title.includes('Total units pagados') ? '' : '$'}{metric ? formatNumberWithCommas(metric) : 'No disponible'}</span>
      <span className="primaryFont text-[#4f4f4f] font-semibold text-sm text-center sm:text-xs md:text-xs">
        {title}
      </span>
    </div>
  );
};
