import { DividendPayoutInterface } from "../../interfaces"
import { DividendHistoryCard } from "./DividendHistoryCard"

interface DividendHistoricalProps {
    dividendPayouts: DividendPayoutInterface
}
export const DividendHistorical = ({ dividendPayouts }: DividendHistoricalProps) => {
    return (
        <div className="h-[280px] w-full">
            <h1 className="primaryFont font-bold text-2xl">Historial de movimientos</h1>
            <div className="h-[240px] overflow-y-auto mt-2 bg">
                {
                    dividendPayouts.dividendHistory.length > 0 &&
                    dividendPayouts.dividendHistory.map((dividend, index) => (
                        <DividendHistoryCard key={index} {...dividend} />
                    ))
                }
                {
                    dividendPayouts.dividendHistory.length === 0 &&
                    <div>No hay historial de movimientos</div>
                }
            </div>
        </div>
    )
}
