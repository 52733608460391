import { InvestmentDetailsInterface } from '../../interfaces';
import { useOutletContext } from 'react-router-dom';
import { addAchievement } from '../../../../services/achievementService';
import { AchievementCard } from './AchievementCard';
import { useState, useEffect } from 'react';
import { AchievementCompleted } from './AchievementCompleted';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper';

interface HelpYourProjectOutletContext {
    investmentDetails: InvestmentDetailsInterface;
    setInvestmentDetails: (value: any) => void;
}

export const HelpYourProject = () => {
    const { investmentDetails, setInvestmentDetails } = useOutletContext<HelpYourProjectOutletContext>();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedAchievement, setSelectedAchievement] = useState<{
        name: string;
        title: string;
        description: string;
        link: string;
        referenceImage?: string;
        achieved: boolean
    } | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAchievementClick = async (name: string) => {
        try {
            const achievement = investmentDetails.achievements.find((ach) => ach.name === name);

            if (achievement) {
                setSelectedAchievement({
                    name: achievement.name,
                    title: achievement.title,
                    description: achievement.description,
                    link: achievement.link || '/',
                    referenceImage: achievement.referenceImage,
                    achieved: achievement.achieved
                });
                setIsOpen(true);
            }

        } catch (error) {
            console.error(`Error al enviar el logro ${name}:`, error);
        }
    };
    const handleAchievementComplete = async (name: string) => {
        try {
            const achievement = investmentDetails.achievements.find((ach) => ach.name === name);
            if (achievement) {
                await addAchievement(name);
                const updatedAchievements = investmentDetails.achievements.map((ach) =>
                    ach.name === name ? { ...ach, achieved: true } : ach
                );
                setInvestmentDetails({
                    ...investmentDetails,
                    achievements: updatedAchievements,
                });
            }
        } catch (error) {
            console.error(`Error al enviar el logro ${name}:`, error);
        }
        setIsOpen(false)
    };

    return (
        <>
            <div className="overflow-auto md:max-w-[1200px] bg-white rounded-lg">
            <div className='bg-[#504bfa] h-auto w-full flex flex-col md:flex-row md:items-center md:justify-between pl-10 py-3'>
  
  <div className="w-full md:w-auto">
    <h2 className='primaryFont text-4xl font-bold text-white'>Impulsa tu Proyecto</h2>
    <p className='secondaryFont text-xs mt-2  text-white'>
      Nuestra sesión de logros está diseñada para impulsar el proyecto. <br />Acciones como listas de deseos, reseñas y likes aumentan su visibilidad, <br /> atrayendo más clientes. Este crecimiento beneficia al proyecto y, en consecuencia, a tu inversión.
    </p>
  </div>

 
  {investmentDetails && investmentDetails.achievements && (
    <div className="mt-4 mb-4 md:mt-0 md:mr-10 w-[90%] md:w-[40%] max-w-[600px] flex flex-col items-center">
      <div className="w-full bg-white rounded-full h-8 relative overflow-hidden shadow-md">
       
        <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-black">
          Progreso: {investmentDetails.achievements.filter(a => a.achieved).length}/{investmentDetails.achievements.length}
        </span>
        
        <div
          className="bg-[#aaaaff] h-full rounded-full transition-all duration-300 ease-in-out"
          style={{
            width: `${
              (investmentDetails.achievements.filter(a => a.achieved).length / investmentDetails.achievements.length) *
              100
            }%`,
          }}
        ></div>
      </div>
    </div>
  )}
</div>



                {investmentDetails && investmentDetails?.achievements ? (
                    <div className=" py-6  max-w-[95%] md:max-w-[1400px]">

                        <Swiper
                            modules={[Pagination, Navigation, Autoplay]}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            navigation
                            autoplay={isMobile ? {
                                delay: 2500,
                                disableOnInteraction: false,
                            } : undefined}
                            breakpoints={{
                                640: { slidesPerView: 2 },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 3 },
                                1280: { slidesPerView: 4 },
                            }}
                            className="achievement-swiper px-4 pb-2"
                        >
                            {investmentDetails.achievements.map((achievement, index) => (
                                <SwiperSlide key={index} className="flex justify-center">
                                    <div className="w-full max-w-[280px] md:max-w-[300px] flex justify-center my-9 mx-auto h-[270px]">
                                        <AchievementCard
                                            name={achievement.name}
                                            title={achievement.title}
                                            description={achievement.description}
                                            link={achievement.link}
                                            handleAchievementClick={handleAchievementClick}
                                            achieved={achievement.achieved}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>


                    </div>
                ) : (
                    <div className="w-full md:w-[60%] h-[300px] flex justify-center items-center text-center">
                        No se pudieron cargar los logros, inténtalo más tarde.
                    </div>
                )}
            </div>

            {isOpen && selectedAchievement && (
                <AchievementCompleted
                    isOpen={isOpen}
                    achieved={selectedAchievement.achieved}
                    setIsOpen={setIsOpen}
                    name={selectedAchievement.name}
                    title={selectedAchievement.title}
                    description={selectedAchievement.description}
                    link={selectedAchievement.link}
                    referenceImage={selectedAchievement.referenceImage}
                    handleAchievementComplete={handleAchievementComplete}
                />
            )}
        </>
    );
};
