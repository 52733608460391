import { ContractList, ReportList } from "./ProfileDetail"

const ProfileDetailDashboard = () => {
    return (
        <div className="flex flex-col md:flex-row">
            <ReportList />
            <ContractList />
        </div>
    )
}

export default ProfileDetailDashboard
