import { useEffect, useState } from "react";
import saveAs from "file-saver";
import { useLocation } from "react-router-dom";
import { getUserContracts } from "../../../../services/e-signService";

export const ContractList = () => {
    const [userContract, setUserContract] = useState([]);
    const location = useLocation();
    const name = sessionStorage.getItem("name") || '';

    useEffect(() => {
        getUserContracts().then(res => {
            console.log('contracts', res.data.data);
            setUserContract(res.data.data);
        }).catch(err => {
            console.log('contracts', err)
        })
    }, []);

    const downloadContract = async (url: string, name: string) => {

        const pdfBlob = await fetch(url).then(r => r.blob());
        saveAs(pdfBlob, `${name}_contrato_de_mandato_${location?.state?.title.replaceAll(' ', '_').toLowerCase()}.pdf`);

    }
    return (
        <div className="lg:w-[330px] bg-white p-[30px] rounded-2xl">

            <div className="text-2xl font-lato font-black mb-3">
                Contratos de mandato
            </div>

            {

                userContract?.map((contract: any, i: any) =>

                    <div
                        key={i}
                        className="flex justify-between py-3 cursor-pointer border-b-[0.5px] border-gray-300 last:border-b-0 text-gray-500"
                        onClick={() => downloadContract(contract.contractFile, name)}
                    >

                        <div className="">{contract.id.slice(0, 5)}_contrato_mandato_{name}</div>
                        <svg className='text-gray-500 hover:text-black focus:text-black' width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                            <path d="M14.375 15.0938H17.7891C20.2598 15.0938 22.2812 14.141 22.2812 11.6977C22.2812 9.25436 19.9004 8.39725 17.9688 8.30156C17.5694 4.48051 14.7793 2.15625 11.5 2.15625C8.40039 2.15625 6.40406 4.21322 5.75 6.25312C3.05469 6.50918 0.71875 7.86492 0.71875 10.6734C0.71875 13.482 3.14453 15.0938 6.10938 15.0938H8.625M8.625 17.9732L11.5 20.8438L14.375 17.9732M11.5 10.0625V20.1263" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>


                    </div>

                )

            }
        </div>
    )
}
