import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

interface NavbarDashboardInterface {
    projectId: string;
    features?: Array<{
        name: string;
        description: string;
        enabled: boolean;
    }>;
}

const NavbarDashboard = ({ projectId, features }: NavbarDashboardInterface) => {
    const subscriptionPlanEnabled = features?.some(
        (feature) => feature.name === 'SUBSCRIPTION_PLAN' && feature.enabled
    );

    return (
        <div className="w-full bg-white rounded-lg">
            <div className="block md:hidden">
                <Swiper
                    spaceBetween={10}
                    slidesPerView="auto"
                    grabCursor
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide className="!w-auto !h-[50px] flex items-center">
                        <NavLink
                            to={`/dashboard/project-detail/${projectId}`}
                            className={({ isActive }) =>
                                `primaryFont text-[#716178] no-underline text-lg font-semibold px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                            }
                        >
                            Proyecto
                        </NavLink>
                    </SwiperSlide>
                    <SwiperSlide className="!w-auto !h-[50px] flex items-center">
                        <NavLink
                            to={`/dashboard/rent/${projectId}`}
                            className={({ isActive }) =>
                                `primaryFont text-[#716178] no-underline text-lg font-semibold px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                            }
                        >
                            Rentabilidad
                        </NavLink>
                    </SwiperSlide>
                    {subscriptionPlanEnabled && (
                        <SwiperSlide className="!w-auto !h-[50px] flex items-center">
                            <NavLink
                                to={`/dashboard/subscription/${projectId}`}
                                className={({ isActive }) =>
                                    `primaryFont text-[#716178] no-underline text-lg font-semibold px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                                }
                            >
                                Suscripción
                            </NavLink>
                        </SwiperSlide>
                    )
                    }
                    <SwiperSlide className="!w-auto !h-[50px] flex items-center">
                        <NavLink
                            to={`/dashboard/profile/${projectId}`}
                            className={({ isActive }) =>
                                `primaryFont text-[#716178] no-underline text-lg font-semibold px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                            }
                        >
                            Documentos
                        </NavLink>
                    </SwiperSlide>
                </Swiper>
            </div>

            {/* Navbar para desktop */}
            <div className="hidden md:flex w-full h-[50px] items-center justify-around bg-white rounded-lg">
                <NavLink
                    to={`/dashboard/project-detail/${projectId}`}
                    className={({ isActive }) =>
                        `primaryFont text-[#716178] no-underline text-lg font-semibold px-[5px] md:px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                    }
                >
                    Proyecto
                </NavLink>
                <NavLink
                    to={`/dashboard/rent/${projectId}`}
                    className={({ isActive }) =>
                        `primaryFont text-[#716178] no-underline text-lg font-semibold px-[5px] md:px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                    }
                >
                    Rentabilidad
                </NavLink>
                {subscriptionPlanEnabled ? (
                    <NavLink
                        to={`/dashboard/subscription/${projectId}`}
                        className={({ isActive }) =>
                            `primaryFont text-[#716178] no-underline text-lg font-semibold px-[5px] md:px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                        }
                    >
                        Suscripción
                    </NavLink>
                ) : (
                    <span className="text-gray-400 text-lg font-normal cursor-not-allowed">
                        Suscripción
                    </span>
                )}
                <NavLink
                    to={`/dashboard/profile/${projectId}`}
                    className={({ isActive }) =>
                        `primaryFont text-[#716178] no-underline text-lg font-semibold px-[5px] md:px-4 ${isActive ? 'bg-[#dfdfff] !text-[#221feb] rounded-lg h-[110%] flex items-center font-bold' : ''}`
                    }
                >
                    Documentos
                </NavLink>
            </div>
        </div>
    );
};

export default NavbarDashboard;
