import investIcon from '../../../assets/img/dashboard/iconInvest.svg';
import blogIcon from '../../../assets/img/dashboard/blog.svg';
import whatsappIcon from '../../../assets/img/dashboard/whatsappIcon.svg';
import profileIcon from '../../../assets/img/dashboard/profile.svg';
import { Link, useNavigate} from 'react-router-dom';
import { trigger } from '../../../helpers/events';

const menuItems = [
    {
        id: 'reinvestment',
        icon: null,
        name: 'Reinvertir'
    },
    {
        id: 'inversiones',
        icon: investIcon,
        name: 'Inversiones'
    },
    {
        id: 'blog',
        icon: blogIcon,
        name: 'Blog'
    },
    {
        id: 'hablar',
        icon: whatsappIcon,
        name: 'Soporte'
    },
    {
        id: 'logout',
        icon: profileIcon,
        name: 'Cerrar sesión'
    }
]

const SideList = ({ isInvestor }: { isInvestor: boolean }) => {
    const navigate = useNavigate();

    const closeSession = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('user_id');
        trigger('unauthed');
        navigate('/');
    }

    const dashboardOptions = (item: any) => {

        switch (item.id) {
            case 'reinvestment':
                return (
                    <>
                        {
                            isInvestor &&
                            <div className="flex justify-start items-center">
                                <svg className='w-[24px] h-[24px] mr-[20px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z" /></svg>
                                <Link to={`/dashboard/dividend/${isInvestor}/reinvestment`} className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</Link>
                            </div>
                        }
                    </>
                );

            case 'inversiones':
                return (
                    <>
                        <div className="flex justify-start items-center">
                            <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
                            <Link to="/dashboard/" className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</Link>
                        </div>
                    </>
                );

            case 'hablar':
                return (
                    <>
                        <div className="flex justify-start items-center">
                            <img width={25} className="mr-[20px]" src={item.icon} alt="contacto" />
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=573126715883&text=Hola!,%20estoy%20en%20el%20dashboard,%20necesito%20tu%20ayuda"
                                className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>
                                {item.name}
                            </a>
                        </div>
                    </>
                );

            case 'logout':
                return (
                    <>
                        <div className="flex justify-start items-center  cursor-pointer" onClick={e => closeSession()}>
                            <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
                            <h1 className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</h1>
                        </div>
                    </>
                );

            default:
                return (
                    <>
                        <div className="flex justify-start items-center">
                            <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
                            <Link to={`/${item.id}`} className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</Link>
                        </div>
                    </>
                )

        }

    }
    return (
        <>
            {menuItems
                .filter((item) => item.id !== 'reinvestment' || isInvestor)
                .map((item: any) => (
                    <div key={item.id} className="w-[150px] flex flex-col my-[10px]">
                        {dashboardOptions(item)}
                    </div>
                ))}
        </>
    )
}

export default SideList
