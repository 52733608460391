import React from 'react'
import { ValuationCard } from './ValuationCard'
import { useOutletContext } from 'react-router-dom';
import { InvestmentDetailsInterface } from '../../interfaces';

interface ListValuationCardsOutletContext {
    investmentDetails: InvestmentDetailsInterface;
}

export const ListValuationCards = () => {
    const { investmentDetails } = useOutletContext<ListValuationCardsOutletContext>();

    return (
        <div className="rounded-lg w-full bg-none md:bg-[#dfddeb] grid gap-4 grid-cols-[repeat(auto-fit,minmax(200px,1fr))]">
            <ValuationCard metric={investmentDetails?.paidInvestment} title="Inversión pagada" />
            <ValuationCard metric={investmentDetails?.unitValueAverage} title="Valor promedio del unit comprado" />
            <ValuationCard metric={investmentDetails?.totalUnitsPaid} title="Total units pagados" />
            <ValuationCard metric={investmentDetails?.suggestedValue} title="Valor estimado del unit" />
            <ValuationCard metric={investmentDetails?.appreciatedInvestment} title="Inversión + Valorización" />
        </div>

    );
};
