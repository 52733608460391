import axios from "axios"
import { urls } from "../helpers/urls";


const getToken = () => localStorage.getItem("token") ?? '';

export const getCart = async(cartId: String)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}cart/${cartId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createOrUpdateCart = async(data: any)=> {
  const body = {
    projectId: data.projectId,
    unitsQuantity: data.unitsQuantity,
    paymentMethod: data.paymentMethod,
    installmentsQuantity: data.installmentsQuantity,
    couponCode: data.couponCode
  }
  try {
    const result = await axios.post(`${urls.NEW_API_URL}cart`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result.data
  } catch (error) {
    throw error
  }
}

export const createOrUpdateDividendReinvestment = async(data: any)=> {
  const body = {
    projectId: data.projectId,
    unitsQuantity: data.unitsQuantity,
    paymentMethod: data.paymentMethod,
    installmentsQuantity: data.installmentsQuantity,
    couponCode: data.couponCode
  }
  try {
    const result = await axios.post(`${urls.NEW_API_URL}cart/dividendReinvestment`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result.data
  } catch (error) {
    throw error
  }
}