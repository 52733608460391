// urls
export const urls = {
  URL: process.env.REACT_APP_BASE_URL,
  NEW_API_URL: process.env.REACT_APP_NEW_API_URL,
  URL_DASHBOARD: process.env.REACT_APP_BASE_URL_FRONT + "dashboard/",
  URL_FRONT: process.env.REACT_APP_BASE_URL_FRONT,
  // SIMULADOR: process.env.REACT_APP_BASE_URL_SIMULADOR,
  SIMULADOR: "https://simulator.lokl.life/",
  WOMPI_URL: process.env.REACT_APP_WOMPI_SANDBOX_URL,
  URL_API:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL
      : process.env.REACT_APP_BASE_URL_LOCAL,
  URL_AUCO:
    process.env.NODE_ENV === "development"
      ? "https://fill.auco.ai/stage/index.html"
      : "https://fill.auco.ai/index.html",
  ENV_AUCO: process.env.REACT_APP_AUCO_ENV,
  API_AUCO: "https://api.auco.ai/v1/ext",
  JWT_SECRET: process.env.REACT_APP_JWT_SECRET ?? '',
  ID_NIDO: process.env.REACT_APP_ID_NIDO,
  ID_INDIE: process.env.REACT_APP_ID_INDIE,
  API_ZAPSIGN: process.env.REACT_APP_API_ZAPSIGN,
  CLOUDBEDS_INDIE_UNIVERSE_PROPERTY_ID: process.env.REACT_APP_CLOUDBEDS_INDIE_UNIVERSE_PROPERTY_ID,
  CLOUDBEDS_INDIE_UNIVERSE_API_KEY: process.env.REACT_APP_CLOUDBEDS_INDIE_UNIVERSE_API_KEY,
};
