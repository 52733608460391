import gears from '../../../../assets/img/projects/gears.svg';

export const OperatingStatusCard = () => {
    return (
        <div className="flex bg-[#C9FFE9] text-black font-[500] px-3 py-2 rounded-sm items-center">
            <img src={gears} alt="Fire icon" />
            <div className="ml-2">
                <p className="secondaryFont text-xs">
                    Operando
                </p>
            </div>
        </div>
    )
}
