import axios from "axios";
import { urls } from "../helpers/urls";

const getToken = () => localStorage.getItem("token") ?? "";

export const addAchievement = async (name: string) => {
  try {
    const payload = { name }; 
    const result = await axios.post(
      `${urls.NEW_API_URL}achievement/addAchievement`, 
      payload, 
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json", 
        },
      }
    );
    return result.data; 
  } catch (error) {
    console.error("Error al añadir un logro:", error);
    throw error; 
  }
};
