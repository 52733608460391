import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import ambassador_icon_blue from '../../../assets/img/ambassadors/ambassador_icon_blue.png';
import PointAccumulateDescription from '../components/PointAccumulateDescription';

export default function AmbassadorBenefits() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSection = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="text-center  space-y-10">
      <motion.h2
        className="text-4xl pb-4 -translate-y-12 md:text-8xl font-medium"
        initial={{ opacity: 0, y: -50 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        Beneficios <br /> <span className="primaryFont font-extralight">del #EmbajadorLokl</span>
      </motion.h2>

      
      <motion.div
        className="block md:hidden w-full px-4"
        initial={{ opacity: 0 }}
        animate={isVisible ? { opacity: 1 } : {}}
        transition={{ duration: 1, delay: 0.3 }}
      >
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides
          navigation={true}
          loop
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div className="flex flex-col items-center mb-4">
              <img src={ambassador_icon_blue} alt="Beneficio 1" className="w-20 h-20" />
              <p className="text-xl font-normal mt-2 secondaryFont">
                <span className="numberFont">$500.000 </span> por <br /> <span className="font-bold">cada referido</span> <br /> exitoso.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center mb-4">
              <img src={ambassador_icon_blue} alt="Beneficio 2" className="w-20 h-20" />
              <p className="text-xl font-normal mt-2 secondaryFont">
                <span className="font-bold">Insignias</span> y <br /> reconocimiento <br /> dentro de la <br /> comunidad.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center mb-4">
              <img src={ambassador_icon_blue} alt="Beneficio 3" className="w-20 h-20" />
              <p className="text-xl font-normal mt-2 secondaryFont">
                Participación en <br /> <span className="font-bold">eventos exclusivos</span> <br /> para embajadores.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center mb-4">
              <img src={ambassador_icon_blue} alt="Beneficio 4" className="w-20 h-20" />
              <p className="text-xl font-normal mt-2 secondaryFont">
                Acceso <span className="font-bold">anticipado</span> <br /> a nuevos proyectos.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </motion.div>

      
      <motion.div
        className="hidden md:grid grid-cols-1 sm:grid-cols-2 md:pb-20 text-left md:grid-cols-4"
        initial={{ opacity: 0, y: 50 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1, delay: 0.6 }}
      >
        <div className="flex flex-col items-center mb-4">
          <img src={ambassador_icon_blue} alt="Beneficio 1" className="w-20 h-20" />
          <p className="text-3xl font-normal mt-2 secondaryFont">
            <span className="numberFont">$500.000 </span> por <br /> <span className="font-bold">cada referido</span> <br /> exitoso.
          </p>
        </div>
        <div className="flex flex-col items-center mb-4">
          <img src={ambassador_icon_blue} alt="Beneficio 2" className="w-20 h-20" />
          <p className="text-3xl font-normal mt-2 secondaryFont">
            <span className="font-bold">Insignias</span> y <br /> reconocimiento <br /> dentro de la <br /> comunidad.
          </p>
        </div>
        <div className="flex flex-col items-center mb-4">
          <img src={ambassador_icon_blue} alt="Beneficio 3" className="w-20 h-20" />
          <p className="text-3xl font-normal mt-2 secondaryFont">
            Participación en <br /> <span className="font-bold">eventos exclusivos</span> <br /> para embajadores.
          </p>
        </div>
        <div className="flex flex-col items-center mb-4">
          <img src={ambassador_icon_blue} alt="Beneficio 4" className="w-20 h-20" />
          <p className="text-3xl font-normal mt-2 secondaryFont">
            Acceso <span className="font-bold">anticipado</span> <br /> a nuevos proyectos.
          </p>
        </div>
      </motion.div>
      <div className="flex justify-center items-center mt-3 mb-10 pb-5 md:text-3xl primaryFont w-full md:w-full ">
  <a 
    href="https://docs.google.com/forms/d/1cbsePiYDLggajgEK_s2rF5eBdwXb3XvXTSjYrPnpCwQ/edit" 
    target="_blank" 
    id="btn-become-ambassador"
    rel="noopener noreferrer" 
    className="px-3 py-3 md:px-2 md:py-2 no-underline bg-[#5352F6] text-white rounded-full hover:bg-blue-700 transition w-[250px] md:w-[500px]"
  >
    Quiero ser embajador
  </a>
</div>




      

      <PointAccumulateDescription />
    </div>
  );
}
