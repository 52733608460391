import React, { useState } from 'react'
import { Invest } from './Invest'
import { PersonalData } from './PersonalData'
import { Payment } from './Payment'
import { Reinvestment } from '../interfaces/interfaces'
import { createDividendReinvestmentRequest } from '../../../../services/paymentDataServices'
import { ReinvestmentLoading } from '../components/ReinvestmentLoading'
import { useNavigate } from 'react-router-dom'

interface DividendReinvestmentDetailInterface {
  totalNoRequestAmount: number
}
export const DividendReinvestmentDetail: React.FC<DividendReinvestmentDetailInterface> = ({ totalNoRequestAmount }) => {
  const [reinvestmentFlowStep, setReinvestmentFlowStep] = useState(1)
  const [reinvestment, setReinvestment] = useState<Reinvestment | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  
  const handleNextStep = async () => {
    if ((1 + reinvestmentFlowStep) === 3 && reinvestment && (totalNoRequestAmount >= reinvestment?.totalInvestment)) {
      setIsLoading(true);
      const cartId = localStorage.getItem('cartId');
      const requestBody = { cartId };
      const result = await createDividendReinvestmentRequest(requestBody);

      if (result) {
        const transactions = result;
        if (transactions.success) {
          await new Promise(resolve => setTimeout(resolve, 5000));
          navigate(`/payment/approved?dividendReinvestmentTransactionId=${transactions.data?.dividendReinvestmentTransaction?.id}`);
        } else {
          await new Promise(resolve => setTimeout(resolve, 5000));
          navigate('/payment/fail')
        }
      }
      setIsLoading(false)
    }
    setReinvestmentFlowStep(1 + reinvestmentFlowStep);
  };


  return (
    <div className='flex gap-3 flex-col md:flex-row'>
      {
        isLoading ?
          <ReinvestmentLoading setIsLoading={setIsLoading} /> :
          <>
            {
              reinvestmentFlowStep === 1 &&
              <Invest setReinvestment={setReinvestment} goNextStep={handleNextStep} totalNoRequestAmount={totalNoRequestAmount} />
            }
            {
              reinvestmentFlowStep === 2 &&
              <PersonalData reinvestment={reinvestment} handleNextStep={handleNextStep} />
            }

            {
              reinvestmentFlowStep === 3 && reinvestment && !(totalNoRequestAmount >= reinvestment?.totalInvestment) &&
              <Payment reinvestment={reinvestment} totalNoRequestAmount={totalNoRequestAmount} />
            }
          </>
      }
    </div>
  )
}
