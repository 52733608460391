import React, { ReactNode, useState } from 'react';

export const PlaySVG = () => (
  <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0.40625C9.24781 0.40625 0.5 8.97182 0.5 19.5C0.5 30.0282 9.24781 38.5938 20 38.5938C30.7522 38.5938 39.5 30.0282 39.5 19.5C39.5 8.97182 30.7522 0.40625 20 0.40625ZM27.0097 20.3537L16.28 26.7005C16.1262 26.7908 15.9507 26.8393 15.7714 26.8412C15.5921 26.843 15.4156 26.7982 15.2598 26.7112C15.1041 26.6242 14.9748 26.4982 14.8852 26.3462C14.7956 26.1941 14.749 26.0215 14.75 25.8459V13.1541C14.749 12.9785 14.7956 12.8059 14.8852 12.6538C14.9748 12.5018 15.1041 12.3758 15.2598 12.2888C15.4156 12.2018 15.5921 12.1569 15.7714 12.1588C15.9507 12.1607 16.1262 12.2092 16.28 12.2995L27.0097 18.6463C27.1592 18.7355 27.2829 18.8609 27.3686 19.0104C27.4544 19.1599 27.4995 19.3285 27.4995 19.5C27.4995 19.6715 27.4544 19.8401 27.3686 19.9896C27.2829 20.1391 27.1592 20.2645 27.0097 20.3537Z" fill="url(#paint0_linear_3245_176)" fill-opacity="0.8"/>
    <defs>
      <linearGradient id="paint0_linear_3245_176" x1="0.5" y1="19.5" x2="39.5" y2="19.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FDFBFB"/>
          <stop offset="1" stop-color="#EBEDEE"/>
      </linearGradient>
    </defs>
  </svg>
)

export const PlaySVGBlack = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3214 0C6.87328 0 0 6.87328 0 15.3214C0 23.7696 6.87328 30.6429 15.3214 30.6429C23.7696 30.6429 30.6429 23.7696 30.6429 15.3214C30.6429 6.87328 23.7696 0 15.3214 0ZM20.829 16.0065L12.3986 21.0994C12.2777 21.1718 12.1398 21.2107 11.999 21.2122C11.8581 21.2137 11.7194 21.1777 11.597 21.1079C11.4747 21.0381 11.3731 20.937 11.3027 20.815C11.2323 20.693 11.1956 20.5545 11.1964 20.4136V10.2293C11.1956 10.0884 11.2323 9.94985 11.3027 9.82784C11.3731 9.70582 11.4747 9.60473 11.597 9.53493C11.7194 9.46513 11.8581 9.42913 11.999 9.43064C12.1398 9.43214 12.2777 9.47109 12.3986 9.54348L20.829 14.6364C20.9466 14.708 21.0437 14.8086 21.1111 14.9286C21.1785 15.0485 21.2139 15.1838 21.2139 15.3214C21.2139 15.459 21.1785 15.5943 21.1111 15.7143C21.0437 15.8343 20.9466 15.9349 20.829 16.0065Z" fill="url(#paint0_linear_3249_106)" fill-opacity="0.9"/>
    <defs>
      <linearGradient id="paint0_linear_3249_106" x1="0" y1="15.3214" x2="30.6429" y2="15.3214" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1"/>
      </linearGradient>
    </defs>
  </svg>
)

interface VideoComponentProps {
  thumbnailSrc: string;
  videoId: string;
  design?: ReactNode,
  width?: number,
}

const VideoComponent: React.FC<VideoComponentProps> = ({ thumbnailSrc, videoId, design, width }) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleThumbnailClick = () => {
    setShowVideo(true);
  };

  return (
    <div>
      {showVideo ? (
        <div>
          <iframe
            className='w-full aspect-video md:min-w-[485px]'
            title='video-blog'
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1;start=2`}
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      ) : (
        <div onClick={handleThumbnailClick} className="relative">
          <img className='rounded-xl' src={thumbnailSrc} alt="Thumbnail" />
          {design}
          <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full cursor-pointer hover:scale-125 transition-all duration-200 ease-in">
            <PlaySVG/>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoComponent;
