import { useEffect, useState } from 'react'

export const usePageVisits = () => {

  const [visits, setPageVisits] = useState(0)

  // FIXME: this function must be removed when the API to get the number of people who are seeing the project is ready
  const generateProjectViews = () => {
    const date = new Date();
    const hour = date.getHours();
    let randomNumber = 1;
    if (hour >= 0 && hour <= 5) {
      randomNumber = Math.floor(Math.random() * 3) + 1;
    } else if (hour >= 6 && hour <= 9) {
      randomNumber = Math.floor(Math.random() * 10) + 10;
    } else if (hour >= 10 && hour <= 20) {
      randomNumber = Math.floor(Math.random() * 20) + 30;
    } else {
      randomNumber = Math.floor(Math.random() * 5) + 1;
    }
    setPageVisits(randomNumber)
  }

  useEffect(() => {
    generateProjectViews()
  }, [])

  return {
    visits
  }
}
