import { useEffect, useState } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
    // EmailShareButton,
    // EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share';
import { getUserData } from '../../../../services/ownerServices';
import { checkSessionStorageToken } from '../../../../helpers/functions';
import jwt_decode from "jwt-decode";
interface AchievementCompletedInterface {
    name: string
    achieved: boolean
    isOpen: boolean
    setIsOpen: (boolean: boolean) => void
    title: string
    description: string
    link: string
    referenceImage?: string
    handleAchievementComplete: (name: string) => void
}
export const AchievementCompleted = ({ name, achieved, isOpen, setIsOpen, title, description, link, referenceImage, handleAchievementComplete }: AchievementCompletedInterface) => {
    const [code, setCode] = useState('');
    const [codeCopied, setCodeCopied] = useState(false);
    const copyTextToClipboard = () => {

        navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`)
        setCodeCopied(true);
        setTimeout(() => {
            setCodeCopied(false);
        }, 3000);

    }
    const getData = async (id: string) => {
        const { data } = await getUserData();
        const userCode = data.data.uniqueCode;
        setCode(userCode);
    }

    useEffect(() => {

        const token = localStorage.getItem("token") ?? '';
        const decoded: any = jwt_decode(token);

        if (checkSessionStorageToken()) {
            getData(decoded.id);
        }

    }, []);

    return (
        <div onClick={() => setIsOpen(false)} className={`fixed top-9 md:top-11 left-0 right-0 bottom-0 z-[100]  bg-[#96DEDA]/75 bg-opacity-75 ${isOpen === true ? 'flex' : 'hidden'} items-center justify-center`}>
            <div className="relative w-[90%] md:w-[500px] py-12 bg-white rounded-xl p-6 flex flex-col justify-center items-center overflow-auto">
                <button className="absolute top-2 right-2" onClick={() => setIsOpen(false)}>
                    <svg className="w-[24px] h-[24px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                </button>
                <div className="flex flex-col justify-center items-center gap-3">
                    {/* <img className='mb-3' src={loklProfile} alt="lokl profile" /> */}

                    <div className="flex gap-2 items-center relative">
                        <h3 className="font-bold text-lg text-center">{title}</h3>
                        <div>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.7345 2.26212C10.288 1.27264 11.712 1.27264 12.2655 2.26212C12.664 2.97446 13.5717 3.21767 14.273 2.80002C15.2471 2.21988 16.4803 2.93184 16.4649 4.06553C16.4538 4.88168 17.1183 5.54615 17.9345 5.5351C19.0682 5.51975 19.7801 6.75289 19.2 7.72702C18.7823 8.42831 19.0255 9.33599 19.7379 9.7345C20.7274 10.288 20.7274 11.712 19.7379 12.2655C19.0255 12.664 18.7823 13.5717 19.2 14.273C19.7801 15.2471 19.0682 16.4803 17.9345 16.4649C17.1183 16.4538 16.4538 17.1183 16.4649 17.9345C16.4803 19.0682 15.2471 19.7801 14.273 19.2C13.5717 18.7823 12.664 19.0255 12.2655 19.7379C11.712 20.7274 10.288 20.7274 9.7345 19.7379C9.33599 19.0255 8.42831 18.7823 7.72702 19.2C6.75289 19.7801 5.51975 19.0682 5.5351 17.9345C5.54615 17.1183 4.88168 16.4538 4.06553 16.4649C2.93184 16.4803 2.21988 15.2471 2.80002 14.273C3.21767 13.5717 2.97446 12.664 2.26212 12.2655C1.27264 11.712 1.27264 10.288 2.26212 9.7345C2.97446 9.33599 3.21767 8.42831 2.80002 7.72702C2.21988 6.75289 2.93184 5.51975 4.06553 5.5351C4.88168 5.54615 5.54615 4.88168 5.5351 4.06553C5.51975 2.93184 6.75289 2.21988 7.72702 2.80002C8.42831 3.21767 9.33599 2.97446 9.7345 2.26212Z" fill="#6DECB9" />
                                <path d="M14.125 8.5L9.75 13.5L7.875 11.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <p className="font-medium text-justify">{description}</p>
                    {
                        !name.includes('REFER') ?
                            <>
                                <div className="flex flex-col items-center justify-center my-2">
                                    {referenceImage && <img className=' w-[90%] md:w-[60%] rounded-lg aspect-square object-cover' src={referenceImage} alt="imagen de referencia" />}
                                    <a href={link} target='_blank' className="underline text-[#3533FF]" rel='noreferrer'>{
                                        name.includes('REVIEW') ? 'Dejar mi reseña'
                                            : (name.includes('WISHLIST')) ? 'Añadir a la lista de deseos'
                                                : (name.includes('BOOKING')) ? 'Reservar'
                                                    : 'Ir a la página'
                                    }</a>
                                </div>
                                <div className="mt-3">
                                    <button
                                        id='btnContractCompleted'
                                        onClick={() => handleAchievementComplete(name)}
                                        className={`rounded-full ${achieved ? 'bg-[#504bfa]' : 'bg-black '} text-white py-2 px-6 font-medium text-lg`}
                                    >
                                        {
                                            achieved ? 'Completado!' : 'Marcar como completado'
                                        }
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className="w-[94%] font-roboto text-base text-center mb-8">
                                    Refiere a tus amigos  <br /> <span className='text-[#3533FF] font-roboto text-xl font-bold'>¡Te obsequiamos 1UNIT!</span>
                                </div>

                                <div className="flex justify-between items-center w-[92%] border p-2 rounded-full mb-3">

                                    <div className="text-gray-400 text-sm">www.lokl.life?code={code}</div>

                                    <div
                                        className="bg-[#3533FF] text-white text-sm rounded-full py-2 px-3 cursor-pointer"
                                        onClick={() => copyTextToClipboard()}
                                    >
                                        {codeCopied ? 'Copiado!' : 'Compartir'}
                                    </div>

                                </div>

                                <div className="w-[92%] mb-4 flex justify-center gap-3">
                                    <FacebookShareButton
                                        url={`https://www.lokl.life?code=${code}`}
                                        quote={'Me gusta invertir en lokl'}
                                        hashtag="#lokl"
                                    >
                                        <FacebookIcon size={35} round />
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                        url={`https://www.lokl.life?code=${code}`}
                                    >
                                        <TwitterIcon size={35} round />
                                    </TwitterShareButton>

                                    <WhatsappShareButton
                                        url={`https://www.lokl.life?code=${code}`}
                                    >
                                        <WhatsappIcon size={35} round />
                                    </WhatsappShareButton>

                                    <TelegramShareButton
                                        url={`https://www.lokl.life?code=${code}`}
                                    >
                                        <TelegramIcon size={35} round />
                                    </TelegramShareButton>

                                    <LinkedinShareButton
                                        url={`https://www.lokl.life?code=${code}`}
                                    >
                                        <LinkedinIcon size={35} round />
                                    </LinkedinShareButton>

                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
