import React from "react"

interface BenefitItemProps {
    benefit: {
        coupon: string,
        isActive: boolean,
        discount: number,
        condition: string
    }
    handleDiscount: (coupon: string) => void
}
const BenefitItem: React.FC<BenefitItemProps> = ({ benefit, handleDiscount }) => {

    return (
        <div onClick={() => handleDiscount(benefit.coupon)} className={`flex px-2 py-3.5 border-b border-gray-200 last:border-b-0 cursor-pointer  ${benefit.isActive ? 'bg-green-100 border-green-500' : 'hover:bg-gray-100'}`} >
            <div className='flex flex-col w-full'>
                <span className='text-lg w-full font-bold'> • Descuento del <span className='text-green-400 font-bold'>{benefit.discount}%</span> en el precio del unit</span>
                <span className='w-full'>&nbsp; {benefit.condition}</span>
            </div>
            <div className='flex justify-center items-center'>
                {benefit.isActive && <div
                    className="w-6 h-6 bg-green-400 rounded-full flex items-center justify-center text-white"
                >
                    ✓
                </div>}
            </div>
        </div>
    )
}

export default BenefitItem
