import React from 'react'
import ExperienceBonusList from './ExperienceBonusList'

export const ExperienceBonusCard = () => {
    return (
        <div className='w-full md:w-[32%] min-w-[300px] bg-white rounded-lg px-4 flex flex-col justify-start pb-10'>

            <div className='w-full mt-4 flex justify-between items-center'>
                <h1 className='primaryFont font-bold text-3xl'>Bonos de experiencia</h1>
                <svg className='w-[24px] h-[24px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z" /></svg>
            </div>

            <div className='w-full h-full flex flex-col items-center'>

                <p className='secondaryFont font-lato text-sm my-5'>
                    Es hora de disfrutar de tu inversión y sus beneficios. Para más información
                    <a className='text-[#3533FF]' href='https://drive.google.com/file/d/1R6aOvsRjYVo-d398PskWJjwL4_WrY9PP/view?usp=drive_link' target='_blank' rel="example noreferrer"> Ver términos y condiciones</a>
                </p>

                <ExperienceBonusList />
            </div>

        </div>
    )
}
