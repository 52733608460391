import React, { useEffect, useState } from 'react'
import { PayoutForm } from '../../components/PayoutForm'
import { getCurrentAccount, getPayoutTargetStatus } from '../../../../services/PayoutService'
import { PayoutAccount } from '../../components/PayoutAccount'
import { PayoutSummary } from './PayoutSummary'
import { useLocation, useSearchParams } from 'react-router-dom'
import PaymentResponseModal from './PayoutResponseModal'

interface Dividend {
  dividendAmount: number;
  id: string;
  investmentAmount: number;
  investmentId: string;
  payoutPeriod: string;
  returnPercentage: number;
  projectName: string;
  availableAmount: number
}

interface PayoutDetailProps {
  dividendPayouts: Dividend[] 
}

export const PayoutDetail: React.FC<PayoutDetailProps> = ({dividendPayouts}) => {

  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState<{title: string, description: string, style: 'success' | 'error'}>({
    title: '',
    description: '',
    style: 'success'
  });

  const [account, setAccount] = React.useState<any>('')
  const [isLoadingAccount, setIsLoadingAccount] = React.useState<any>(true)

  const [myParams, setMyParams] = useSearchParams()
  

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sectionParam = searchParams.get('section');
  const payoutTargetId = searchParams.get('payoutTargetId');

  const handleGetCurrentAccount = async() => {
    try {
      setIsLoadingAccount(true)
      const response = await getCurrentAccount()
      if(response?.data?.success) {
        setAccount(response?.data?.data)
      }
      setIsLoadingAccount(false)
    } catch (error) {
      setIsLoadingAccount(false)

      console.log(error)
    }
  
  }

  useEffect(() => {
    handleGetCurrentAccount()
  }, [])

  useEffect(() => {
    setIsLoadingAccount(true)
    if(payoutTargetId) {
      getPayoutTargetStatus(payoutTargetId).then((response: any) => {
        if(response?.data?.success) {
          if(response?.data?.data?.status === 'pending') {
            setOpenModal(true)
            setModalInfo({
              title: '¡Registro pendiente!',
              description: 'Parece que este proceso esta tardando mas de lo normal. ¡No te preocupes!, en cuanto tengamos una respuesta te notificaremos a tu correo.',
              style: 'success'
            })
          } else if(response?.data?.data?.status === 'active') {
            setOpenModal(true)
            setModalInfo({
              title: '¡Registro exitoso! 🎉️',
              description: 'Ahora puedes solicitar el retiro de tus utilidades.',
              style: 'success'
            })
            handleGetCurrentAccount()
          } else if (response?.data?.data?.status === 'cancelled') {
            setOpenModal(true)
            setModalInfo({
              title: '¡Registro rechazado!',
              description: `Tu solicitud de registro ha sido rechazada. Por favor verifica que los datos ingresados sean correctos. ${response?.data?.data?.statusMessage}`,
              style: 'error'
            })
          }
        }
      }).catch((error) => {
        console.log(error)
        setIsLoadingAccount(false)
        setOpenModal(true)
        setModalInfo({
          title: '¡Algo salio mal!',
          description: 'Ha ocurrido un error. Por favor intenta mas tarde.',
          style: 'error'
        })
      }).finally(() => {
        setMyParams({})
        setIsLoadingAccount(false)
      })
    }
  }, [sectionParam, myParams, payoutTargetId, setMyParams])

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  
  return (
    <div className='flex gap-3 flex-col md:flex-row'>
      {
        isLoadingAccount ?
          <div role="status" className="animate-pulse flex gap-4 flex-col md:flex-row">
            <div className="h-[500px] bg-gray-200 dark:bg-gray-500 rounded-md w-[400px] mb-4"></div>
          </div>
          :
          <>
            {
              account ?
                <PayoutAccount account={account} />
                : <PayoutForm />
            }
            <>
              <PayoutSummary dividendPayouts={dividendPayouts} payoutTargetId={account.id} />
            </>
          </>
      }


      <PaymentResponseModal
        isOpen={openModal}
        title={modalInfo.title}
        description={modalInfo.description}
        handleButton={() => handleCloseModal()}
        handleOpenModal={setOpenModal}
        modalStyle={modalInfo.style}
        buttonLabel='Aceptar'
      />
    </div>
  )
}
