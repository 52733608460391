import React from 'react';
import { LetsGrowthTogether, WeReDifferent, TimeLine, OurTeam, FrecuentlyQs, Supplierbar } from "./sections/index";

export const AboutUs = () => {
  return (
    <div > 
      <LetsGrowthTogether />
      
      <div>
        <TimeLine />
      </div>
      
      <div className=" md:mt-0">
        <WeReDifferent />
      </div>
      
      <OurTeam />
      
      <FrecuentlyQs />
      
      <div className="mt-16">
        <Supplierbar />
      </div>
    </div>
  );
};
