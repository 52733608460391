import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { getCloudbedsOccupation, getCloudbedsRates, getCloudbedsTransactions } from '../../../../services/cloudbedsService';
import { useEffectOnce } from '../../../../helpers/hooks';
import { ChartTransactions } from './ChartTransactions';
import { ChartSkeleton } from './ChartSkeleton';
import { cloudbedsDashboardInfoInterface } from '../../interfaces';
import { NoLoadedChart } from './NoLoadedChart';
import { OccupationChart } from './OccupationChart';
import { RatesChart } from './RatesChart';
import Select from 'react-tailwindcss-select';
import { ScrollMessage } from './ScrollMessage';
export interface cloudbedsDashboardInfo {
    cloudbedsDashboardInfo: cloudbedsDashboardInfoInterface
    setCloudbedsDashboardInfo: (value: any) => void;
}
const OCCUPATION = 'occupation'
const RATES = 'rates'
const TRANSACTIONS = 'transactions'

const daysOptions = [
    { label: 'Últimos 5 días', value: '4' },
    { label: 'Última semana', value: '7' },
    { label: 'Últimos 15 días', value: '15' },
    { label: 'Último mes', value: '30' },
    { label: 'Últimos 3 meses', value: '90' },
]
export const CloudbedsDashboard = () => {
    const { cloudbedsDashboardInfo, setCloudbedsDashboardInfo } = useOutletContext<cloudbedsDashboardInfo>();
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [graphSelected, setGraphSelected] = useState(cloudbedsDashboardInfo.graphSelected ?? TRANSACTIONS)
    const [days, setDays] = useState(cloudbedsDashboardInfo.days ?? { label: 'Últimos 5 días', value: '4' })
    const { projectId } = useParams();
    useEffectOnce(() => {
        const fetchProjectDetails = async () => {
            if (cloudbedsDashboardInfo.transactions.length === 0) {
                try {
                    setIsLoading(true)
                    if (projectId) {
                        const resp = await getCloudbedsTransactions(projectId, Number(days.value));
                        setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, transactions: resp.data.data, graphSelected: TRANSACTIONS, charts: { ...cloudbedsDashboardInfo.charts, transactions: true } })
                    }
                } catch (error) {
                    console.error("Error fetching project details:", error);
                }
                finally {
                    setIsLoading(false);
                }
            }
        };

        fetchProjectDetails();
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleGraphSelected = async (graphSelected: string) => {
        setGraphSelected(graphSelected)
        if ((cloudbedsDashboardInfo.occupation.length === 0 || !cloudbedsDashboardInfo.charts.occupation) && graphSelected === OCCUPATION) {
            try {
                setIsLoading(true)
                if (projectId) {
                    const resp = await getCloudbedsOccupation(projectId, Number(days.value));
                    setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, occupation: resp.data.data, graphSelected: OCCUPATION, charts: { ...cloudbedsDashboardInfo.charts, occupation: true } })
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
        else if ((cloudbedsDashboardInfo.rates.length === 0 || !cloudbedsDashboardInfo.charts.rates) && graphSelected === RATES) {
            try {
                setIsLoading(true)
                if (projectId) {
                    const resp = await getCloudbedsRates(projectId, Number(days.value));
                    setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, rates: resp.data.data, graphSelected: RATES, charts: { ...cloudbedsDashboardInfo.charts, rates: true } })
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
        else if ((cloudbedsDashboardInfo.transactions.length === 0 || !cloudbedsDashboardInfo.charts.transactions) && graphSelected === TRANSACTIONS) {
            try {
                setIsLoading(true)
                if (projectId) {
                    const resp = await getCloudbedsTransactions(projectId, Number(days.value));
                    setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, transactions: resp.data.data, graphSelected: TRANSACTIONS, charts: { ...cloudbedsDashboardInfo.charts, transactions: true } })
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
        else {
            setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, graphSelected: graphSelected })
        }
    }
    const handleDaysChange = async (val: any) => {
        setDays(val);
        if (projectId && graphSelected === RATES) {
            try {
                setIsLoading(true)
                const resp = await getCloudbedsRates(projectId, Number(val.value));
                setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, rates: resp.data.data, graphSelected: RATES, days: val, charts: { rates: true, occupation: false, transactions: false } })
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
        else if (projectId && graphSelected === OCCUPATION) {
            try {
                setIsLoading(true)
                const resp = await getCloudbedsOccupation(projectId, Number(val.value));
                setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, occupation: resp.data.data, graphSelected: OCCUPATION, days: val, charts: { rates: false, occupation: true, transactions: false } })
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
        else if (projectId && graphSelected === TRANSACTIONS) {
            try {
                setIsLoading(true)
                const resp = await getCloudbedsTransactions(projectId, Number(val.value));
                setCloudbedsDashboardInfo({ ...cloudbedsDashboardInfo, graphSelected: TRANSACTIONS, transactions: resp.data.data, days: val, charts: { rates: false, occupation: false, transactions: true } })
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
    }


    return (
        <div className="relative w-full bg-white rounded-lg justify-center items-center">
            <div className="pt-4 px-[0px] md:px-8 flex flex-col items-start gap-4 md:flex-row md:items-center md:justify-between">

                <div className="w-[90%] mx-auto md:w-auto z-10">
                    <span className='primaryFont text-4xl font-bold text-black text-center z-10 md:text-left'>
                        {graphSelected === TRANSACTIONS
                            ? "Ingresos diarios del hotel"
                            : graphSelected === OCCUPATION
                                ? "Tasa de Ocupación del hotel"
                                : graphSelected === RATES
                                    ? "Tarifa Promedio del hotel"
                                    : ""}
                    </span>
                    <p className='secondaryFont text-xs mt-1'>Los valores mostrados en las gráficas son aproximaciones basadas en los datos disponibles y están diseñados para proporcionar una visión general de los ingresos, ocupación y tarifas del hotel.</p>
                </div>


                <div className="flex w-full flex-col md:flex-row gap-2  justify-center md:w-auto md:justify-end z-10  md:mt-0 ">
                    <Select
                        classNames={{
                            menuButton: () =>
                                `primaryFont mx-auto w-[90%] md:w-full secondaryFont flex justify-between h-full border-[1px] text-[#4F4F4F] bg-white px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0]  overflow-hidden whitespace-nowrap text-ellipsis`,
                            list: 'z-50 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                            listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                        }}
                        primaryColor="violet"
                        value={days}
                        onChange={handleDaysChange}
                        options={daysOptions}
                        isDisabled={isLoading}
                        placeholder="¿Cómo nos conociste?"
                    />
                    <div className=' flex gap-2 justify-center md:w-auto md:justify-end mt-2 md:!mt-0'>
                        <button
                            disabled={isLoading}
                            className={`primaryFont flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === TRANSACTIONS ? 'bg-[#221feb] text-white' : 'bg-gray-100 text-[#221feb] '}`}
                            onClick={() => handleGraphSelected(TRANSACTIONS)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024" className="mr-2 hidden md:flex">
                                <path fill="currentColor" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372" />
                                <path fill="currentColor" fill-opacity="0.15" d="M426.6 410.3c0 25.4 15.7 45.1 49.5 57.3c4.7 1.9 9.4 3.4 15 5v-124c-37 4.7-64.5 25.4-64.5 61.7m116.5 135.2c-2.9-.6-5.7-1.3-8.8-2.2V677c42.6-3.8 72-27.3 72-66.4c0-30.7-15.9-50.7-63.2-65.1" />
                                <path fill="currentColor" fill-opacity="0.15" d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372m22.4 589.2l.2 31.7c0 4.5-3.6 8.1-8 8.1h-28.4c-4.4 0-8-3.6-8-8v-31.4c-89-6.5-130.7-57.1-135.2-112.1c-.4-4.7 3.3-8.7 8-8.7h46.2c3.9 0 7.3 2.8 7.9 6.6c5.1 31.8 29.9 55.4 74.1 61.3V534l-24.7-6.3c-52.3-12.5-102.1-45.1-102.1-112.7c0-73 55.4-112.1 126.2-119v-33c0-4.4 3.6-8 8-8h28.1c4.4 0 8 3.6 8 8v32.7c68.5 6.9 119.8 46.9 125.9 109.2a8.1 8.1 0 0 1-8 8.8h-44.9c-4 0-7.4-2.9-7.9-6.9c-4-29.2-27.5-53-65.5-58.2v134.3l25.4 5.9c64.8 16 108.9 47 109 116.4c0 75.2-56 117.1-134.3 124" />
                                <path fill="currentColor" d="m559.7 488.8l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2c.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8c-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119c0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3c-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7c4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124c-.1-69.4-44.2-100.4-109-116.4m-68.6-16.2c-5.6-1.6-10.3-3.1-15-5c-33.8-12.2-49.5-31.9-49.5-57.3c0-36.3 27.5-57 64.5-61.7zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2c47.3 14.4 63.2 34.4 63.2 65.1c0 39.1-29.4 62.6-72 66.4" />
                            </svg>
                            Ingresos
                        </button>

                        <button
                            disabled={isLoading}
                            className={`primaryFont flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === OCCUPATION ? 'bg-[#221feb] text-white' : 'bg-[#dfdfff] text-[#221feb] '}`}
                            onClick={() => handleGraphSelected(OCCUPATION)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em"  className="mr-2"   viewBox="0 0 512 512"><path fill="currentColor" d="M432 230.7a79.4 79.4 0 0 0-32-6.7H112a79.5 79.5 0 0 0-32 6.69A80.09 80.09 0 0 0 32 304v112a16 16 0 0 0 32 0v-8a8.1 8.1 0 0 1 8-8h368a8.1 8.1 0 0 1 8 8v8a16 16 0 0 0 32 0V304a80.09 80.09 0 0 0-48-73.3M376 80H136a56 56 0 0 0-56 56v72a4 4 0 0 0 5.11 3.84A95.5 95.5 0 0 1 112 208h4.23a4 4 0 0 0 4-3.55A32 32 0 0 1 152 176h56a32 32 0 0 1 31.8 28.45a4 4 0 0 0 4 3.55h24.46a4 4 0 0 0 4-3.55A32 32 0 0 1 304 176h56a32 32 0 0 1 31.8 28.45a4 4 0 0 0 4 3.55h4.2a95.5 95.5 0 0 1 26.89 3.85A4 4 0 0 0 432 208v-72a56 56 0 0 0-56-56"/></svg>
                            Ocupación
                        </button>

                        <button
                            disabled={isLoading}
                            className={`primaryFont flex items-center justify-center bg-[#dfdfff] text-[#221feb] rounded-md px-4 py-2 transition-all duration-300 ease-in-out ${graphSelected === RATES ? 'bg-[#221feb] text-white' : 'bg-[#dfdfff] text-[#221feb] '}`}
                            onClick={() => handleGraphSelected(RATES)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em"  className="mr-2" viewBox="0 0 24 24"><path fill="currentColor" d="M3 21v-2h2V5q0-.825.588-1.412T7 3h10q.825 0 1.413.588T19 5v14h2v2zm12-2h2V5h-4.5V3.9q1.1.2 1.8 1.025T15 6.85zm-4-6q.425 0 .713-.288T12 12t-.288-.712T11 11t-.712.288T10 12t.288.713T11 13"/></svg>
                            Tarifa
                        </button>
                    </div>

                </div>
            </div>

            <div className='w-full h-full'>
                {isMobile && !cloudbedsDashboardInfo.alreadyShowScrollMessage && Number(cloudbedsDashboardInfo.days.value) > 7 && <ScrollMessage />}
                {isLoading ? (
                    <ChartSkeleton />
                ) : graphSelected === TRANSACTIONS ? (
                    cloudbedsDashboardInfo.transactions.length === 0 ? (
                        <NoLoadedChart />
                    ) : (
                        <ChartTransactions transactions={cloudbedsDashboardInfo.transactions} daysNumber={Number(days.value)} />
                    )
                ) : graphSelected === OCCUPATION ? (
                    cloudbedsDashboardInfo.occupation.length === 0 ? (
                        <NoLoadedChart />
                    ) : (
                        <OccupationChart occupation={cloudbedsDashboardInfo.occupation} daysNumber={Number(days.value)} />
                    )
                ) : graphSelected === RATES ? (
                    cloudbedsDashboardInfo.rates.length === 0 ? (
                        <NoLoadedChart />
                    ) : (
                        <RatesChart rates={cloudbedsDashboardInfo.rates} daysNumber={Number(days.value)} />
                    )
                ) : null}
            </div>

        </div>

    );

}
