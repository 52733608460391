import axios, { AxiosError } from "axios"
import { urls } from "../helpers/urls"

const getToken = () => localStorage.getItem("token");

const { origin } = window.location;

export const fetchBanks = async (setBanks?: any) => {
  try {
    const res = await axios.get(`${urls.NEW_API_URL}payment/financialInstitutions`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    
    })
    if(setBanks) {
      setBanks(res.data.data)
    }
    return res.data.data
  } catch (error) {
    console.log('error: ', error);

  }
}

export const createTransaction = async (data: any, setMsgAlert: any) => {
  try {
    const res = await axios.post(`${urls.URL}transaction`, data);
    if (res) {
      // console.log("error:", res?.data?.code);

      if (res?.data?.code === "01"){

        let message: string;

        if(res?.data?.data?.reference){
          message = res?.data?.data?.reference[0]
        }
        else if(res?.data?.data?.customer_email){
          message = res?.data?.data?.customer_email[0]
        }
        else{
          message = res?.data?.message
        }

        console.log('email', res?.data?.data?.customer_email);

        setMsgAlert({
          msg: message,
          status: 'error',
        });

      }

      let id = res.data?.data?.data?.id;
      // esperar 3 segundos
      setTimeout(async () => {
        try {
         
          const response = await axios.get(`${urls.WOMPI_URL}/transactions/${id}`);
          if (res) {
  
            setMsgAlert({
              msg: res.data.message,
              status: 'success',
            });
            
            if(data.type === "0"){
  
              let url = response?.data?.data?.payment_method?.extra?.async_payment_url;
        
              if(url !== null || url !== undefined){
                window.location.href = url;
              }else{
                window.location.href = `${origin}/payment/error`;
              }
              
            }else{
              
              let url = response?.data?.data?.redirect_url;
        
              if(url !== null || url !== undefined){
                window.location.href = `${url}?id=${id}`;
              }else{
                window.location.href = `${origin}/payment/error`;
              }
        
            }
  
          }
          // console.log('res: ', response, res);
          
        } catch (error: any) {
          console.log("error:", error?.response?.data?.error?.reason);
          // setMsgAlert({
          //   msg: res.data.message,
          //   status: 'error',
          // });
        }
      }, 5000);
    }
  } catch (error: any) {

    // if (axios.isAxiosError(error)) {
    // }
    const axiosError = error as AxiosError;
    console.error(axiosError.message);
    setMsgAlert({
      msg: `Ocurrió un error en la transacción, vuelva a intentar.`,
      status: 'error',
    });
    
  }

};

export const sendTransaction = async (data: any) => {
  try {
    const res = await axios.post(`${urls.NEW_API_URL}payment`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return res.data
  } catch (error) {
    throw error;
  }
}

export const createDividendReinvestmentRequest = async (data: any) => {
  try {
    const res = await axios.post(`${urls.NEW_API_URL}dividendReinvestmentRequest`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return res.data
  } catch (error) {
    throw error;
  }
}

export const getDividendReinvestmentByGatewayTransactionId = async (gatewayTransactionId: string) => {
  try {
    const res = await axios.get(`${urls.NEW_API_URL}dividendReinvestment/gatewayTransaction/${gatewayTransactionId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return res.data
  } catch (error) {
    throw error;
  }
}

export const getDividendReinvestmentByDividendReinvestmentId = async (dividendReinvestmentTransactionId: string) => {
  try {
    const res = await axios.get(`${urls.NEW_API_URL}dividendReinvestment/dividendReinvestment/${dividendReinvestmentTransactionId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return res.data
  } catch (error) {
    throw error;
  }
}

export const getTransaction = async (id: any) => {
  try {
    const transaction = await axios.get(`${urls.WOMPI_URL}/transactions/${id}`);
    return transaction;
  } catch (error) {
    throw error;
  }
};

export const successTransaction = async (data: any) => {
  await axios.post(`${urls.URL}transaccionsuccess`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      console.log();
    })
    .catch(err => {

    });
};


export const payInstallment = async(data: any) => {
  try {
    const response = await axios.post(`${urls.NEW_API_URL}payment/installmentPayment`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPseUrl = async (transactionId: string) => {
  try {
    const response = await axios.get(`${urls.URL}/payment/get-pse-url/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response.data
  } catch (error) {
    throw error;
  }
}