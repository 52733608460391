import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../../helpers/functions'
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import toast from 'react-hot-toast';
import { createdPayoutRequest } from '../../../../services/DividendPayoutRequestService';
import PaymentResponseModal from '../../../Checkout/Payment/components/PaymentResponseModal';

const convertQuarter = (str:string) => {
  const regex = /Q(\d+) (\d{4})/;
  return str.replace(regex, (match, quarter, year) => {
      return `Trimestre ${quarter} del año ${year}`;
  });
}

interface Dividend {
  dividendAmount: any;
  id: string;
  investmentAmount: any;
  investmentId: string;
  payoutPeriod: string;
  returnPercentage: number;
  projectName: string;
  availableAmount: number
}

interface PayoutSummaryProps {
  dividendPayouts: Dividend[]
  payoutTargetId: string
}

export const PayoutSummary: React.FC<PayoutSummaryProps> = ({
  dividendPayouts,
  payoutTargetId
}) => {


  const navigate = useNavigate()

  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState<{title: string, description: string, style: 'success' | 'error'}>({
    title: '',
    description: '',
    style: 'success'
  });

  const [isLoading, setIsLoading] = React.useState(false)
  const [totalDividendAmount, setTotalDividendAmount] = React.useState<any>(0)

  const handleCreatePayoutRequest = async() => {
    try {
      setIsLoading(true)
      const dividendPayoutIds = dividendPayouts.map((dividend) => {
        return dividend.id
      })
      const response = await createdPayoutRequest({ dividendPayoutIds, payoutTargetId })
      if(response?.data.success) {
        setOpenModal(true)
        setModalInfo({
          title: '¡Solicitud de retiro de utilidades exitosa!',
          description: 'Tu solicitud de retiro de utilidades ha sido enviada. Una vez validada la información, el dinero será transferido a tu cuenta bancaria registrada en un plazo de 5 a 8 días hábiles.',
          style: 'success'
        })
      } else {
        toast.error(response?.data.message)
      }
      setIsLoading(false)
    } catch (error: any) {
      const errorData = error?.response?.data?.data || ''
      toast.error(`${errorData}`, { duration: 5000})
      setIsLoading(false)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    navigate('/dashboard')
  }

  useEffect(() => {
    const total = Object.values(dividendPayouts).reduce((t, {availableAmount}) => t + availableAmount, 0)
    setTotalDividendAmount(total)
    console.log(dividendPayouts)
  }, [dividendPayouts])


  return (
    <>
      <div className="bg-white rounded-md w-full md:w-3/4 xl:w-5/12 py-4 px-10 max-w-2xl flex flex-col justify-between items-center">
        <div>
          <div className="flex items-center gap-3 w-full">
            <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="45" height="43" rx="5" fill="#49E9FF" />
              <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
              <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            <h2 className="font-bold text-lg">Resumen de pago</h2>
          </div>
          <p className='mt-2 text-gray-600'>El pago de utilidades de la inversión por ley genera una retención del 7%. Este 7% podrá ser deducido en su declaración de renta del próximo año.</p>
        </div>
        <div className="w-full mb-4">
          <h3 className="text-lg font-bold mb-2 mt-4">Pago de utilidades</h3>
          <div>
            {
              dividendPayouts.map((dividend) => {
                return (
                  <>
                    <div className="my-3 flex flex-col gap-2">
                      <div className="flex justify-between">
                        <p className="text-gray-600 font-roboto text-lg">Proyecto:</p>
                        <p className="font-lato font-semibold text-xl">{(dividend.projectName).toUpperCase()}</p>
                      </div>
                      {/* <div className="flex justify-between">
                        <p className="text-gray-600 font-roboto text-lg">Inversión total:</p>
                        <p className="font-lato font-semibold text-xl">${numberWithCommas(totalInvestmentValue)} <span className="text-sm">COP</span></p>
                      </div> */}
                      <div className="flex justify-between">
                        <p className="text-gray-600 font-roboto text-lg">Porcentaje de retorno/trimestre</p>
                        <p className="font-lato font-semibold text-xl">{dividend.returnPercentage}%<span className="text-sm"></span></p>
                      </div>
                      {/* <div className="flex justify-between">
                        <p className="text-gray-600 font-roboto text-lg">Retorno</p>
                        <p className="font-lato font-semibold text-xl">${numberWithCommas(200000)} <span className="text-sm">COP</span></p>
                      </div> */}
                      <div className="flex justify-between">
                        <p className="text-gray-600 font-roboto text-lg">Período de Pago</p>
                        <div className="flex flex-col">
                          <p className="font-lato font-semibold text-xl">{convertQuarter(dividend.payoutPeriod)}</p>
                        </div>
                      </div>
                    </div>
                    <hr className="text-gray-400" />
                  </>

                )
              })
            }
            {/* <hr className="text-gray-400" /> */}
            <div className="flex justify-between items-center mt-4">
              <p className="font-semibold text-xl">Retorno total</p>
              <p className="font-lato font-semibold text-2xl">${numberWithCommas(totalDividendAmount)} <span className="text-sm">COP</span></p>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-3/4 xl:w-2/4 my-4">
          <Button disabled={!payoutTargetId} label='Retirar utilidades' onSubmit={() => handleCreatePayoutRequest()} isLoading={isLoading} />
        </div>
      </div>
      <PaymentResponseModal
        isOpen={openModal}
        title={modalInfo.title}
        description={modalInfo.description}
        handleButton={() => handleCloseModal()}
        handleOpenModal={setOpenModal}
        modalStyle={modalInfo.style}
        buttonLabel='Aceptar'
      />
    </>
    
  )
}
