import React from 'react';
import PropTypes from 'prop-types';

interface AllyIconCardProps {
  icon: string;
  alt: string;
  className?: string; 
}

const AllyIconCard: React.FC<AllyIconCardProps> = ({ icon, alt, className }) => {
  return (
    <div className=" flex justify-center items-center w-20 h-20 md:w-52 "> 
      <img src={icon} alt={alt} className={`w-20 h-20  px-1 md:w-60 md:px-0 md:h-60 object-contain ${className}`} /> 
    </div>
  );
};

AllyIconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default AllyIconCard;
