import React from 'react'
import close from "../../../assets/img/home/close-white.svg";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'

interface ReferCardInterface {
    code: string
    codeCopied: boolean
    setCodeCopied: (copied: boolean) => void
    setModalCode: (visible: boolean) => void
}

const ReferCard: React.FC<ReferCardInterface> = ({ code, codeCopied, setCodeCopied, setModalCode, }) => {
    const copyTextToClipboard = () => {

        navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`);
        setCodeCopied(true);
        setTimeout(() => {
            setCodeCopied(false);
        }, 3000);

    }
    return (
        <div className="flex justify-center items-center w-full min-h-[735px] absolute bg-black/40 rounded z-50">

            <div className="flex flex-col justify-center items-center relative w-[380px] h-[240px] bg-white px-9 py-2 rounded-lg">

                <div className="absolute -top-5 -right-5 cursor-pointer"
                    onClick={() => setModalCode(false)}
                >
                    <img src={close} alt="close" />
                </div>

                <div className="w-full font-bold">Compártelo</div>
                <div className="w-full mb-3">y recibe beneficios por referido</div>

                <div className="flex justify-between items-center w-full border p-2 rounded-full mb-3">

                    <div className="text-gray-400 text-sm">https://www.lokl.life?code={code}</div>

                    <div
                        className="bg-black text-white text-sm rounded-full py-1.5 px-4 cursor-pointer"
                        onClick={() => copyTextToClipboard()}
                    >
                        {codeCopied ? 'Copiado!' : 'Copiar'}
                    </div>

                </div>

                <div className="w-full mb-3 flex justify-center gap-3">

                    <FacebookShareButton
                        url={`https://www.lokl.life?code=${code}`}
                        quote={'Me gusta invertir en lokl'}
                        hashtag="#lokl"
                    >
                        <FacebookIcon size={35} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={`https://www.lokl.life?code=${code}`}
                    >
                        <TwitterIcon size={35} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                        url={`https://www.lokl.life?code=${code}`}
                    >
                        <WhatsappIcon size={35} round />
                    </WhatsappShareButton>

                    <TelegramShareButton
                        url={`https://www.lokl.life?code=${code}`}
                    >
                        <TelegramIcon size={35} round />
                    </TelegramShareButton>

                    <LinkedinShareButton
                        url={`https://www.lokl.life?code=${code}`}
                    >
                        <LinkedinIcon size={35} round />
                    </LinkedinShareButton>

                </div>

            </div>

        </div>
    )
}

export default ReferCard
