export const PageDashboardSkeleton = () => {
    return (
        <div className="w-full h-auto">
            <div className="skeleton w-full h-[500px] md:h-[400px] rounded-lg"></div>
            <div className="mt-6 flex justify-between  w-full">
                <div className="skeleton w-[68%] h-[550px] rounded-lg"></div>
                <div className="skeleton w-[30%] h-[550px] rounded-lg"></div>
            </div>
            <div className="skeleton w-full h-[400px] rounded-lg mt-6"></div>
        </div>
    )
}
