import React, { useEffect, useState } from 'react'
import { UpdateDateForm } from '../components/UpdateDateForm'
import { SignReinvestmentContract } from '../components/SignReinvestmentContract'
import { Reinvestment } from '../interfaces/interfaces'
import { FieldValues, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { completeOwnerData } from '../../../../services/ownerServices'
import { parsePhoneNumber } from 'react-phone-number-input'
import { ContractSignModal } from '../../../Checkout/PersonalData/components/ContractSignModal'
import { createDocumentFromTemplate, saveContractFile } from '../../../../services/e-signService'
import { ConfirmationModalReinvestment } from '../components/ConfirmationModalReinvestment'

interface PersonalDataInterface {
  reinvestment?: Reinvestment
  handleNextStep?: any
}

export const PersonalData: React.FC<PersonalDataInterface> = ({ reinvestment, handleNextStep }) => {

  const [signUrl, setSignUrl] = useState('')
  const [isSignModalOpen, setIsSignModalOpen] = useState<boolean>(false);
  const [contractSignButtonLoading, setContractSignButtonLoading] = useState(false)
  const [documentInfo, setDocumentInfo] = useState<any>({})
  const [confirmationModalIsOpen, setConfirmValidationModalIsOpen] = useState<boolean>(false)
  const [confirmationModal, setConfirmValidationModal] = useState<any>({
    title: '',
    description: '',
    type: '',
    nextStep: false
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
    setError
  } = useForm<FieldValues>({
    defaultValues: {
      documentType: ""
    },
    mode: 'onTouched',
  });


  const updateData = async (data: any) => {
    try {
      const formatPhoneNumber = parsePhoneNumber(data.phone)
      let phone = formatPhoneNumber?.nationalNumber || ''
      const countryPhoneCode = formatPhoneNumber?.countryCallingCode || ''
      // This validation is for user without phone code
      if (!phone || !countryPhoneCode) {
        phone = data.phone
      }
      // Update owner information
      const result = await completeOwnerData(
        data.firstName,
        data.lastName,
        data.documentType,
        data.documentNumber,
        data.birthDate,
        data.address,
        phone,
        countryPhoneCode,
        data.state,
        data.city
      )
      return result
    } catch (error: any) {
      console.log(error)
      if (error?.response.status === 403) {
        toast.error('Tu sesión ha expirado, por favor inicia sesión nuevamente')
      }
    }
  }
  const onSignContractSubmit = async (data: any) => {
    if (!getValues("phone")) {
      setError('phone', { type: 'required' });
      return
    }
    await updateData(data)

    try {
      const token = localStorage.getItem('token');
      const cartId = localStorage.getItem('cartId');
      if (token) {
        setIsSignModalOpen(true);
        setContractSignButtonLoading(true)
        const result = await createDocumentFromTemplate({
          cartId
        })
        setDocumentInfo(result?.data?.data)
        setContractSignButtonLoading(false)
        setSignUrl(result?.data.data.signUrl)
      }

    } catch (error) {
      setContractSignButtonLoading(false)
      console.log('Error onSignContract', error)
    }
  }

  useEffect(() => {
    let handleMessage: (e: any) => void;
    if(documentInfo?.token) {
      handleMessage = (e: any) => {
        if (e.data === 'zs-signed-file-ready') {
          saveContractFile({contractId:documentInfo.contractId}).then((result) => {
            setIsSignModalOpen(false);
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Contrato firmado!',
              description: '¡Excelente! Has firmado el contrato de vinculación exitosamente.',
              type: 'success',
              nextStep: true
            })
          }).catch((error) => {
            console.log(error)
            setIsSignModalOpen(false);
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Algo salió mal!',
              description: 'No pudimos guardar tu contrato firmado, por favor comunícate con nosotros para ayudarte.',
              type: 'error',
              communityRedirectionLink: 'https://wa.link/z8zvp3'
            })
          })

        }
      };
      // Attach the event listener when the component mounts
      window.addEventListener('message', handleMessage);
    }


    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentInfo]);
  
  return (
    <>
      <UpdateDateForm
        setValue={setValue}
        clearErrors={clearErrors}
        register={register}
        errors={errors}
      />
      <SignReinvestmentContract contractSignButtonLoading={contractSignButtonLoading} onSignContractSubmit={onSignContractSubmit} handleSubmit={handleSubmit} />
      <ConfirmationModalReinvestment
          isOpen={confirmationModalIsOpen}
          title={confirmationModal.title}
          description={confirmationModal.description}
          type={confirmationModal.type}
          nextStep={handleNextStep}
          setIsOpen={setConfirmValidationModalIsOpen}
          communityLink={confirmationModal.communityRedirectionLink}
        />
      <ContractSignModal isOpen={isSignModalOpen} setIsOpen={setIsSignModalOpen} signUrl={signUrl} />
    </>
  )
}
