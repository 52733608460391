import React from 'react';

interface AchievementCardInterface {
    link?: string;
    name: string;
    title: string;
    description: string;
    achieved: boolean;
    handleAchievementClick: (name: string) => void;
}

export const AchievementCard = ({
    link,
    name,
    title,
    description,
    handleAchievementClick,
    achieved
}: AchievementCardInterface) => {
    return (
        <div className={`relative shadow-sm w-[200px] rounded-lg bg-gray-50 flex flex-col justify-between items-center group ${!(achieved || name.includes('REFER')) && 'hover:bg-gray-600'}`}>
            <div className="flex flex-col justify-center items-center">
                <div className={`w-[90px] h-[90px] ${achieved ? 'bg-[#504bfa]' : 'bg-gray-600'} rounded-full my-4 flex justify-center items-center`}>
                    {(() => {
                        if (name.includes('REVIEW')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="#ffffff" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                </svg>
                            );
                        }
                        if (name.includes('BOOKING') && name.includes('WISHLIST')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="#ffffff" d="M64 32C28.7 32 0 60.7 0 96L0 256 0 416c0 35.3 28.7 64 64 64l128 0c70.7 0 128-57.3 128-128c0-46.5-24.8-87.3-62-109.7c18.7-22.3 30-51 30-82.3c0-70.7-57.3-128-128-128L64 32zm96 192l-96 0L64 96l96 0c35.3 0 64 28.7 64 64s-28.7 64-64 64zM64 288l96 0 32 0c35.3 0 64 28.7 64 64s-28.7 64-64 64L64 416l0-128z" />
                                </svg>
                            );
                        }
                        if (name.includes('AIRBNB')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="#ffffff" d="M224 373.1c-25.2-31.7-40.1-59.4-45-83.2-22.6-88 112.6-88 90.1 0-5.5 24.3-20.3 52-45 83.2zm138.2 73.2c-42.1 18.3-83.7-10.9-119.3-50.5 103.9-130.1 46.1-200-18.9-200-54.9 0-85.2 46.5-73.3 100.5 6.9 29.2 25.2 62.4 54.4 99.5-32.5 36.1-60.6 52.7-85.2 54.9-50 7.4-89.1-41.1-71.3-91.1 15.1-39.2 111.7-231.2 115.9-241.6 15.8-30.1 25.6-57.4 59.4-57.4 32.3 0 43.4 25.9 60.4 59.9 36 70.6 89.4 177.5 114.8 239.1 13.2 33.1-1.4 71.3-37 86.6zm47-136.1C280.3 35.9 273.1 32 224 32c-45.5 0-64.9 31.7-84.7 72.8C33.2 317.1 22.9 347.2 22 349.8-3.2 419.1 48.7 480 111.6 480c21.7 0 60.6-6.1 112.4-62.4 58.7 63.8 101.3 62.4 112.4 62.4 62.9 .1 114.9-60.9 89.6-130.2 0-3.9-16.8-38.9-16.8-39.6z" />
                                </svg>
                            );
                        }
                        if (name.includes('MAPS')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path fill="#ffffff" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                            );
                        }
                        if (name.includes('REFER')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path fill="#ffffff" d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM504 312l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                                </svg>
                            );
                        }
                        if (name.includes('BOOKING')) {
                            return (
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="#ffffff" d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z" />
                                </svg>
                            );
                        }
                        return (
                            <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path fill="#ffffff" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                            </svg>
                        );
                    })()}
                </div>

            </div>
            <div className="px-4 space-y-2 mb-2">
                <h2 className="text-sm font-bold leading-none text-center ">{title}</h2>
                <p className="h-[32px] text-xs leading-none text-center overflow-hidden text-ellipsis">{description}</p>
            </div>
            <div className={`${(achieved || name.includes('REFER')) && 'hidden'} w-full bg-gray-600 text-white h-[50px] rounded-b-lg primaryFont text-2xl font-thin flex items-center justify-center`}>
                <strong className='pr-1'>Desbloquear </strong> logro
            </div>
            <div className={`${!achieved && 'hidden'} w-full bg-[#504bfa] text-white h-[50px] rounded-b-lg primaryFont text-2xl font-thin flex items-center justify-center`}>
                <strong className='pr-1'>Completado </strong>
            </div>
            <div className={`${(!achieved && name.includes('REFER')) ? '' : 'hidden'} w-full bg-gray-600 text-white h-[50px] rounded-b-lg primaryFont text-2xl font-thin flex items-center justify-center`}>
                <strong className='pr-1'>Refiere </strong>
            </div>
            <button
                onClick={() => handleAchievementClick(name)}
                className={`absolute top-0 left-0 w-full h-full ${achieved ? 'bg-[#504bfa]' : 'bg-gray-600'} text-white hidden group-hover:flex items-center justify-center rounded-lg`}
            >
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <div className="hover:scale-105 w-[90px] h-[90px] border-white border-2 rounded-full my-4 flex justify-center items-center">
                        {
                            achieved ?
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                                :
                                <svg className="text-white w-[35px] h-[35px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80l0 48c0 17.7 14.3 32 32 32s32-14.3 32-32l0-48C576 64.5 511.5 0 432 0S288 64.5 288 144l0 48L64 192c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-192c0-35.3-28.7-64-64-64l-32 0 0-48z" /></svg>
                        }
                    </div>
                    <span className='text-2xl font-thin'>
                        {
                            achieved ?
                                <><strong>Revisar</strong> nuevamente</>
                                :
                                <><strong>Completar </strong> logro</>
                        }
                    </span>
                </div>
            </button>
        </div>
    );
};
