import { useState } from "react";
import ServiceOption from "./ServiceOption";
import { InvestmentDetailsInterface } from "../../interfaces";
import { useOutletContext } from "react-router-dom";
import { CapitalizeFirstLetter } from "../../../../utils/otherFormat";

interface ExperienceBonusListOutletContext {
  investmentDetails: InvestmentDetailsInterface;
}
const ExperienceBonusList = () => {
  const { investmentDetails } = useOutletContext<ExperienceBonusListOutletContext>();
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  const services = [
    {
      title: `Estadía en ${CapitalizeFirstLetter(investmentDetails?.name)}`,
      icon: (
        <svg
          className="w-[22px] h-[17px]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24l16 0 0 33.3C119.6 157.2 32 252.4 32 368l448 0c0-115.6-87.6-210.8-200-222.7l0-33.3 16 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0-40 0zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24l464 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 400z" />
        </svg>
      ),
      alertText: `Hola, me encantaría reservar en ${CapitalizeFirstLetter(investmentDetails?.name)} utilizando mis bonos de experiencia. ¿Me podrían ayudar con el proceso?`
    },
    {
      id: "coworking",
      title: "Coworking",
      label: "coworking",
      icon: (
        <svg
          className="w-[22px] h-[17px]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3l0-84.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5l0 21.5c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-26.8C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112l32 0c24 0 46.2 7.5 64.4 20.3zM448 416l0-21.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176l32 0c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2l0 26.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3l0-84.7c-10 11.3-16 26.1-16 42.3zm144-42.3l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2l0 42.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-42.8c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112l32 0c61.9 0 112 50.1 112 112z" />
        </svg>
      ),
      alertText: "Hola, me encantaría adquirir una membresía de coworking utilizando mis bonos de experiencia. ¿Me podrían ayudar con el proceso?"
    },
    {
      title: "Salas de reuniones",
      label: "Salas de reuniones",
      icon: (
        <svg className="w-[22px] h-[17px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8 512 128l-.7 0-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48 0 224 28.2 0 91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16L0 352c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-224-80 0zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128l0 224c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-208c0-8.8-7.2-16-16-16l-80 0zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" /></svg>
      ),
      alertText: "Hola, me encantaría adquirir una sala de reuniones utilizando mis bonos de experiencia. ¿Me podrían ayudar con el proceso?"
    },
    {
      id: "content",
      title: "Espacios de creación de contenido",
      label: "Espacios de creación de contenido",
      icon: (
        <svg className="w-[22px] h-[17px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M256 0L576 0c35.3 0 64 28.7 64 64l0 224c0 35.3-28.7 64-64 64l-320 0c-35.3 0-64-28.7-64-64l0-224c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l80 0 48 0 144 0c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128l96 0 0 256 0 32c0 17.7 14.3 32 32 32l128 0c17.7 0 32-14.3 32-32l0-32 160 0 0 64c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zm0 104c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zm0 104c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zm336 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16z" /></svg>
      ),
      alertText: "Hola, me encantaría adquirir un espacio para la creación de contenido utilizando mis bonos de experiencia. ¿Me podrían ayudar con el proceso?"
    },
    {
      id: "events",
      title: "Espacios para eventos",
      label: "Espacios para eventos",
      icon: (
        <svg className="w-[22px] h-[17px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M155.6 17.3C163 3 179.9-3.6 195 1.9L320 47.5l125-45.6c15.1-5.5 32 1.1 39.4 15.4l78.8 152.9c28.8 55.8 10.3 122.3-38.5 156.6L556.1 413l41-15c16.6-6 35 2.5 41 19.1s-2.5 35-19.1 41l-71.1 25.9L476.8 510c-16.6 6.1-35-2.5-41-19.1s2.5-35 19.1-41l41-15-31.3-86.2c-59.4 5.2-116.2-34-130-95.2L320 188.8l-14.6 64.7c-13.8 61.3-70.6 100.4-130 95.2l-31.3 86.2 41 15c16.6 6 25.2 24.4 19.1 41s-24.4 25.2-41 19.1L92.2 484.1 21.1 458.2c-16.6-6.1-25.2-24.4-19.1-41s24.4-25.2 41-19.1l41 15 31.3-86.2C66.5 292.5 48.1 226 76.9 170.2L155.6 17.3zm44 54.4l-27.2 52.8L261.6 157l13.1-57.9L199.6 71.7zm240.9 0L365.4 99.1 378.5 157l89.2-32.5L440.5 71.7z" /></svg>
      ),
      alertText: "Hola, me encantaría adquirir un espacio para la creación de eventos utilizando mis bonos de experiencia. ¿Me podrían ayudar con el proceso?"
    }
  ];

  const handleSelectOption = (alertText: string | undefined) => {
    setSelectedOption(alertText);
  };

  const handleAcquireService = () => {
    if (selectedOption) {
      const whatsappLink = `https://api.whatsapp.com/send/?phone=573126715883&text=${encodeURIComponent(selectedOption)}`;
      window.open(whatsappLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="animate_fadeInUp w-full h-full flex flex-col justify-between items-center">
      <h1 className="flex font-lato font-extrabold text-2xl w-full">Selecciona un servicio</h1>
      <div className="w-full h-auto overflow-y-auto mt-2">
        {services.map((service) => (
          <ServiceOption
            key={service.title}
            title={service.title}
            icon={service.icon}
            alertText={service.alertText}
            isSelected={selectedOption === service.alertText}
            onSelect={handleSelectOption}
          />
        ))}
      </div>
      {selectedOption ? (
        <button
          className="bg-black text-white font-semibold mx-auto py-2.5 w-full md:w-[200px] min-w-[200px] rounded-full mt-4 mb-0"
          onClick={handleAcquireService}
        >
          Adquirir servicio
        </button>) : (
        <div className='w-full h-[62px]'></div>
      )}
    </div>

  );
};

export default ExperienceBonusList;
