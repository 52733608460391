import React, { useEffect, useState } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
} from "recharts";
import { AppreciatedInvestmentPerMonth } from "../../interfaces";

interface ValuationChartInterface {
  appreciatedInvestmentPerMonth: AppreciatedInvestmentPerMonth[];
}

export const ValuationChart = ({
  appreciatedInvestmentPerMonth,
}: ValuationChartInterface) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatValue = (value: number): string => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(0)}K`;
    }
    return value.toFixed(0);
  };

  const chartData = appreciatedInvestmentPerMonth.map((item) => ({
    date: `1/${item.month}/${item.year}`,
    appreciation: item.appreciation,
    investmentPaid: item.investmentPaid,
    unitValue: item.unitValue,
    appreciationPercentage: item.appreciationPercentage,
  }));

  const CustomTooltip = ({ payload, label }: any) => {
    const defaultLabel = "Sin fecha";
    const defaultValue = 0;

    const investmentPaid = payload?.[0]?.payload?.investmentPaid ?? defaultValue;
    const appreciation = payload?.[1]?.payload?.appreciation ?? defaultValue;
    const unitValue = payload?.[0]?.payload?.unitValue ?? defaultValue; 
    const appreciationPercentage =
      payload?.[1]?.payload?.appreciationPercentage ?? defaultValue;

    const currencyFormatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });

    const percentageFormatter = (value: number) => `${Math.round(value)}%`;

    return (
      <div className="bg-white p-3 shadow-md border rounded">
        <p className="text-gray-700 font-bold numberFont">{`Fecha: ${label || defaultLabel}`}</p>
        <p className="text-gray-600 numberFont">{`Inversión Pagada: ${currencyFormatter.format(
          investmentPaid
        )}`}</p>
        <p className="text-gray-600 numberFont">{`Valorización: ${currencyFormatter.format(
          appreciation
        )}`}</p>
        <p className="text-gray-600 numberFont">{`Valor Unit: ${currencyFormatter.format(
          unitValue
        )}`}</p>
        <p className="text-gray-600 numberFont">{`Porcentaje de valorización: ${percentageFormatter(
          appreciationPercentage
        )}`}</p>
      </div>
    );
  };

  const labelStyle = {
    fill: "#4A4A4A",
    fontWeight: "numberFont",
    fontSize: isMobile ? 8 : 10, 
  };

  const leftMargin = isMobile ? 4 : 24;
  return (
    <div className="w-full h-[400px] rounded-lg overflow-x-auto custom-scroll">
      <div className="w-[1200px] lg:w-full h-full">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 50,
              
              bottom: 20,
              left: leftMargin,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              tickFormatter={(value) => formatValue(value)}
              yAxisId="left"
            />
            <YAxis
              tickFormatter={(value) => formatValue(value)}
              yAxisId="right"
              orientation="right"
            />
            <Tooltip content={<CustomTooltip />} />

            <Bar
              dataKey="investmentPaid"
              fill="#C3B7FF"
              stackId="stack"
              yAxisId="left"
            >
              <LabelList
                dataKey="investmentPaid"
                position="insideBottom"
                formatter={(value: number) => formatValue(value)}
                style={labelStyle}
              />
            </Bar>
            <Bar
              dataKey="appreciation"
              fill="#e2dcff" 
              stackId="stack"
              radius={[10, 10, 0, 0]}
              yAxisId="left"
            >
              <LabelList
                dataKey="appreciation"
                position="insideTop"
                formatter={(value: number) => formatValue(value)}
                style={labelStyle}
              />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
