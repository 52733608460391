import { useOutletContext } from "react-router-dom";
import { InvestmentDetailsInterface } from "../interfaces";

import { ProgressTimeLine } from "./ProjectDetail/ProgressTimeLine";
import { GalleryProject } from "./ProjectDetail/GalleryProject";
import { InfoProject } from "./ProjectDetail/InfoProject";
import { HelpYourProject } from "./ProjectDetail/HelpYourProject";
import { CloudbedsDashboard } from "./ProjectDetail/CloudbedsDashboard";
import { GalleryProjectSkeleton } from "./ProjectDetail/GalleryProjectSkeleton";
import { InfoProjectSkeleton } from "./ProjectDetail/InfoProjectSkeleton";
import { HelpYourProjectSkeleton } from "./ProjectDetail/HelpYourProjectSkeleton";
import { MainList } from "./ProjectDetail/MainList";

interface ProjectDetailDashboardOutletContext {
    investmentDetails: InvestmentDetailsInterface;
    isLoading: boolean;
}
const ProjectDetailDashboard = () => {
    const { investmentDetails, isLoading } = useOutletContext<ProjectDetailDashboardOutletContext>();
    const featureFlags = (investmentDetails?.features || []).reduce<Record<string, boolean>>((flags, feature) => {
        if (feature.enabled) {
            flags[feature.name] = true;
        }
        return flags;
    }, {});

    const cloudbedsDashboardEnabled = !!featureFlags['CLOUDBEDS_DASHBOARD'];
    const progressTimeLineEnabled = !!featureFlags['PROGRESS_TIMELINE'];

    return (
        <div className='w-full h-auto space-y-8'>
            {cloudbedsDashboardEnabled && <CloudbedsDashboard />}
            <MainList projectName={investmentDetails?.name} cloudbedsDashboardEnabled={cloudbedsDashboardEnabled} />
            {progressTimeLineEnabled && <ProgressTimeLine />}
            <div className="relative w-full h-auto flex md:flex-row flex-col justify-between space-y-4 md:space-y-[0px]">
                {isLoading ? <GalleryProjectSkeleton /> : <GalleryProject />}
                {isLoading ? <InfoProjectSkeleton /> : <InfoProject />}
            </div>
            {isLoading ? <HelpYourProjectSkeleton /> : <HelpYourProject />}
        </div>
    )
}

export default ProjectDetailDashboard
