// localhost:4001/api/investment/investmentDetails/c3f50b31-1e1b-4ebe-881e-0d390458f471
import axios from "axios"
import { urls } from "../helpers/urls";


const getToken = () => localStorage.getItem("token") ?? '';

export const getInvestmentDetails = async(projectId: String)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}investment/investmentDetails/${projectId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }
}