import React from "react"

interface ArticleCoverProps {
    title: string
}
export const ArticleCover: React.FC<ArticleCoverProps> = ({ title }) => {
    return (
        <div className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

            <div className="wrapper-lokl text-white ">

                <div className="w-[90%] md:w-5/12 ml-[5%] md:ml-0">

                    <h1 className="fadeInLeft font-lato font-extrabold text-[38px] mb-3">
                        {title}
                    </h1>

                </div>

            </div>

        </div>
    )
}
