import React, {useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Swiper as SwiperType , Pagination, Keyboard, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../../../components/galleryNido.scss';

interface GalleryAllProps {
  images: { url: string; type: string }[]; 
}

const GalleryAll: React.FC<GalleryAllProps> = ({ images }) => {
  const [active, setActive] = useState(true);
  const swiperRef = useRef<SwiperType>();

  return (
    <>
      {active && (
        <div className="fixed top-[20px] left-0 w-screen h-screen flex justify-center pt-8 items-center bg-black/70 z-50">
          <div className="max-w-[90%] md:max-w-xl lg:max-w-5xl 2xl:max-w-6xl relative">
            <div className="rounded-2xl overflow-hidden gallery-swiper">
              <div
                onClick={() => setActive(false)}
                className="absolute top-8 right-6 z-10 cursor-pointer"
              >
                <svg
                  width="30"
                  height="29"
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.342097 1.83572C-0.671703 0.875237 0.793497 -0.655643 1.8073 0.304837L14.8128 12.585L27.8183 0.304837C28.8321 -0.655643 30.2936 0.875237 29.2835 1.83572L16.3557 14.0423L29.2835 26.2488C30.2936 27.2056 28.8321 28.7402 27.8183 27.7797L14.8128 15.4996L1.8073 27.7797C0.793497 28.7402 -0.671703 27.2056 0.342097 26.2488L13.2699 14.0423L0.342097 1.83572Z"
                    fill="white"
                  />
                </svg>
              </div>

              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                lazy={true}
                loop={false}
                pagination={{ clickable: true }}
                modules={[Pagination, Keyboard, Navigation]}
                keyboard={{
                  enabled: true,
                }}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="rounded-2xl overflow-hidden">
                      <div className="aspect-[14/9]">
                        <div className="h-full w-full">
                          <img
                            src={image.url}
                            alt={`Imagen ${index + 1}`}
                            className="object-cover w-full h-full"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="hidden md:block">
                <button
                  onClick={() => swiperRef.current?.slidePrev()}
                  className="absolute top-[45%] left-[-50px] translate-y-[-50%] rotate-180 z-50"
                >
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6289 0.0317383C7.97951 0.0317383 0.128906 8.06165 0.128906 17.9314C0.128906 27.8012 7.97951 35.8311 17.6289 35.8311C27.2783 35.8311 35.1289 27.8012 35.1289 17.9314C35.1289 8.06165 27.2783 0.0317383 17.6289 0.0317383ZM23.9197 18.7318L14.2904 24.6817C14.1524 24.7663 13.9949 24.8118 13.834 24.8135C13.6731 24.8153 13.5147 24.7732 13.3749 24.6917C13.2352 24.6101 13.1191 24.492 13.0387 24.3495C12.9583 24.2069 12.9164 24.0451 12.9174 23.8805V11.9824C12.9164 11.8178 12.9583 11.6559 13.0387 11.5134C13.1191 11.3708 13.2352 11.2527 13.3749 11.1712C13.5147 11.0896 13.6731 11.0476 13.834 11.0494C13.9949 11.0511 14.1524 11.0966 14.2904 11.1812L23.9197 17.1311C24.0539 17.2148 24.1648 17.3323 24.2418 17.4725C24.3188 17.6126 24.3592 17.7707 24.3592 17.9314C24.3592 18.0922 24.3188 18.2503 24.2418 18.3904C24.1648 18.5306 24.0539 18.6481 23.9197 18.7318Z" fill="white" />
                  </svg>
                </button>
                <button
                  onClick={() => swiperRef.current?.slideNext()}
                  className="absolute top-[45%] right-[-50px] translate-y-[-50%] z-30"
                >
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6289 0.0317383C7.97951 0.0317383 0.128906 8.06165 0.128906 17.9314C0.128906 27.8012 7.97951 35.8311 17.6289 35.8311C27.2783 35.8311 35.1289 27.8012 35.1289 17.9314C35.1289 8.06165 27.2783 0.0317383 17.6289 0.0317383ZM23.9197 18.7318L14.2904 24.6817C14.1524 24.7663 13.9949 24.8118 13.834 24.8135C13.6731 24.8153 13.5147 24.7732 13.3749 24.6917C13.2352 24.6101 13.1191 24.492 13.0387 24.3495C12.9583 24.2069 12.9164 24.0451 12.9174 23.8805V11.9824C12.9164 11.8178 12.9583 11.6559 13.0387 11.5134C13.1191 11.3708 13.2352 11.2527 13.3749 11.1712C13.5147 11.0896 13.6731 11.0476 13.834 11.0494C13.9949 11.0511 14.1524 11.0966 14.2904 11.1812L23.9197 17.1311C24.0539 17.2148 24.1648 17.3323 24.2418 17.4725C24.3188 17.6126 24.3592 17.7707 24.3592 17.9314C24.3592 18.0922 24.3188 18.2503 24.2418 18.3904C24.1648 18.5306 24.0539 18.6481 23.9197 18.7318Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GalleryAll;
