import rocket from '../../../../assets/img/projects/rocket.svg';

export const LaunchStatusCard = () => {
    return (
        <div className="flex bg-[#FFE297] text-[#000000] font-[500] px-3 py-2 rounded-sm items-center">
            <img src={rocket} alt="Fire icon" />
            <div className="ml-2">
                <p className="secondaryFont text-xs">
                    Prelanzamiento
                </p>
            </div>
        </div>
    )
}
