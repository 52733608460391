import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import error from '../../assets/img/payment/photo-complete-error.png';
import { numberWithCommas } from '../../helpers/functions';
import moment from 'moment';

function Fail() {

  const navigate = useNavigate();
  const instalmentValue: any = sessionStorage.getItem('instalmentValue');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='lg:w-[1024px] lg:flex lg:space-x-16 py-20'>

      <div className="lg:w-1/2">
        <img src={error} alt="" />
      </div>

      <div className="lg:w-1/2 lg:space-y-7">

        <div className="flex justify-start items-center">

          <div className="text-xl text-red-500 font-medium mr-8">
            Reinversión denegada <br />
            Vuelve a intentarlo
          </div>

          <button
            className="bg-gray-900 text-white hover:bg-gray-700 w-60 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Volver a invertir
          </button>

        </div>

        <div className="text-gray-500 text-sm">
          {moment(new Date()).format('LL')}
          <br />
          {moment(new Date()).format('hh:mm a')}
        </div>

        <div className="flex items-end">

          <div className="">

            <div className="font-extrabold font-lato text-3xl">
              Intenta nuevamente
            </div>

            <div className="font-extrabold font-lato text-3xl">
              ${numberWithCommas(parseInt(instalmentValue))} /mes
            </div>

            <div className="text-gray-500 text-sm">
              Un mes único y total de inversión
            </div>

          </div>

          <div className="ml-8">
            Descargar contrato
          </div>

        </div>

        <div className="flex divide-x-2 space-x-2 text-sm leading-tight">

          <div className="">Etapa</div>

          <div className="pl-2">Total: Units</div>

          <div className="pl-2">Costo por Unit: $</div>

        </div>

      </div>

    </div>
  )

}

export default Fail;