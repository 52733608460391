import React from 'react'
import { Button } from '../../components/Button'

interface SignReinvestmentContractInterface{
  onSignContractSubmit: any,
  handleSubmit: any,
  contractSignButtonLoading: boolean
}

export const SignReinvestmentContract: React.FC<SignReinvestmentContractInterface> = ({
  onSignContractSubmit, handleSubmit, contractSignButtonLoading
}) => {

  return (
    <div className="bg-white flex flex-col rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
      <div className="flex items-center gap-3">
        <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="45" height="43" rx="5" fill="#A9DBFF" />
          <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
          <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        <h2 className="font-bold text-lg">Contrato de vinculación</h2>
      </div>

      <div className='mt-6'>
        Por medio del documento de intención se te entregarán los derechos de la compañía a la cual vas a invertir. Firma el documento de forma segura a través de ZapSign.
      </div>

      <div className="w-full mt-6">
        <Button  label='Firmar contrato' onSubmit={handleSubmit(onSignContractSubmit)} isLoading={contractSignButtonLoading} />
      </div>

    </div>
  )
}
