import axios from "axios";
import { urls } from "../helpers/urls";

const getToken = () => localStorage.getItem("token") ?? "";
const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
};
const getDatesWithOffset = (daysOffset: number) => {
    const currentDate = new Date();
    const offsetDate = new Date();
    offsetDate.setDate(currentDate.getDate() - daysOffset);

    const formattedCurrentDate = formatDate(currentDate);
    const formattedOffsetDate = formatDate(offsetDate);

    return {
        currentDate: formattedCurrentDate,
        offsetDate: formattedOffsetDate
    };
}

export const getCloudbedsTransactions = async (projectId: string, daysAgo : number) => {
    try {
        const { currentDate, offsetDate } = getDatesWithOffset(daysAgo);
        const result = await axios.get(
            `${urls.NEW_API_URL}cloudbeds/transaction/${projectId}?resultsFrom=${offsetDate}&resultsTo=${currentDate}`,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return result;
    } catch (error) {
        console.error("Error al obtener transacciones de Cloudbeds:", error);
        throw error;
    }
};


export const getCloudbedsOccupation = async (projectId: string, daysAgo : number) => {
    try {
        const { currentDate, offsetDate } = getDatesWithOffset(daysAgo);
        const result = await axios.get(
            `${urls.NEW_API_URL}cloudbeds/occupation/${projectId}?resultsFrom=${offsetDate}&resultsTo=${currentDate}`,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            }
        );
        console.log('result', result)
        return result;
    } catch (error) {
        console.error("Error al obtener las ocupaciones de Cloudbeds:", error);
        throw error;
    }
};

export const getCloudbedsRates = async (projectId: string, daysAgo : number) => {
    try {
        const { currentDate, offsetDate } = getDatesWithOffset(daysAgo);
        const result = await axios.get(
            `${urls.NEW_API_URL}cloudbeds/rate/${projectId}?resultsFrom=${offsetDate}&resultsTo=${currentDate}`,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            }
        );
        console.log('result', result)
        return result;
    } catch (error) {
        console.error("Error al obtener las tarifas de Cloudbeds:", error);
        throw error;
    }
};

