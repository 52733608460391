import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { InvestmentDetailsInterface } from '../../interfaces';
import { cloudbedsRatesInterface } from '../../interfaces';
import { useOutletContext } from 'react-router-dom';


interface RatesChartInterface {
  rates: Array<cloudbedsRatesInterface>;
  daysNumber: number;
}

interface InfoProjectOutletContext {
  investmentDetails: InvestmentDetailsInterface;
}



export const RatesChart = ({ rates, daysNumber }: RatesChartInterface) => {
  const [isMobile, setIsMobile] = useState(false);

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { investmentDetails } = useOutletContext<InfoProjectOutletContext>();

  const roomTypes = Array.isArray(investmentDetails.accommodations)
    ? investmentDetails.accommodations.filter(
        (type): type is string => typeof type === 'string'
      )
    : [];

  const currencyFormatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
  });

  const chartData = rates.reduce((acc, rate) => {
    const existingDate = acc.find((item) => item.date === rate.date);

    const roomTypeGroup = roomTypes.find((group) =>
      rate.roomTypeName.includes(group)
    );

    if (roomTypeGroup) {
      if (existingDate) {
        existingDate[`${roomTypeGroup}_total`] =
          (existingDate[`${roomTypeGroup}_total`] || 0) + rate.averageTotalRate;
      } else {
        acc.push({
          date: rate.date,
          [`${roomTypeGroup}_total`]: rate.averageTotalRate,
        });
      }
    }

    return acc;
  }, [] as any[]);

  
  const getDynamicColors = (roomTypes: string[]): Record<string, string> => {
    const colorPalette = [
      '#6f6ff3', '#221feb', '#dcdcdc', '#000000', '#1fe9eb',
      '#ff7f50', '#ffa500', '#32cd32', '#87ceeb', '#ff1493',
    ];

    return roomTypes.reduce((colorMap, roomType, index) => {
      colorMap[roomType] = colorPalette[index % colorPalette.length]; 
      return colorMap;
    }, {} as Record<string, string>);
  };

  const colorMap = getDynamicColors(roomTypes);
  const leftMargin = isMobile ? 4 : 64;

  const compactFormatter = (value: number) =>
    value >= 1_000_000
      ? `${Math.round(value / 1_000_000)}M`
      : value >= 1_000
      ? `${Math.round(value / 1_000)}K`
      : value.toLocaleString();


  return (
    <div className="relative w-full h-[350px] overflow-x-auto custom-scroll">
      
      <div className={`${daysNumber <= 7 ? 'w-full' : 'w-[1200px]'} md:w-full h-full`}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 80,
            right: 54,
            bottom: 20,
            left: leftMargin,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="date"
            tickFormatter={(value) => {
              const dateParts = value.split('-');
              return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            }}
          />
          <YAxis
            tickFormatter={(value) =>
              isMobile ? compactFormatter(value) : currencyFormatter.format(value)
            } 
          />
          <Tooltip
            formatter={(value: number) =>
              typeof value === 'number' ? currencyFormatter.format(value) : ''
            }
          />
          <Legend />

          {roomTypes.map((roomType) => (
            <Line
              key={`line-${roomType}`}
              type="monotone"
              dataKey={`${roomType}_total`}
              stroke={colorMap[roomType]} 
              dot={true}
              activeDot={{ r: 8 }}
              connectNulls={true}
              name={roomType}
              strokeWidth={3}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      </div>
      
      
    </div>
  );
};
