import React from 'react'

export const UtilitiesList = ({ setShowPayoutForm, setShowReinvestmentForm, totalNoRequestAmount }: any) => {
    return (
        <div className='animate_fadeInUp'>
            <h1 className="font-lato font-extrabold text-2xl">Adquirir servicios</h1>
            <div className="h-64 overflow-y-auto px-4 mt-2 bg">
                <div className="flex items-center justify-between py-3.5 border-b border-gray-200 last:border-b-0">
                    <div className='flex w-fit my-auto'>
                        <svg className='w-[22px] h-[17px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                        <h1 className='ml-2 font-extrabold'>Reinvertir</h1>
                    </div>
                    <button
                        className={`
                            w-fit
                            px-4
                            py-2
                            text-sm
                            rounded-full
                            font-bold
                            font-roboto
                          bg-[#6232EB]
                            text-white
                            ${totalNoRequestAmount < 0 ? 'cursor-not-allowed bg-slate-300' : ''}
                            `}
                        onClick={() => setShowReinvestmentForm(true)}
                        disabled={totalNoRequestAmount < 0}
                    >Solicitar</button>
                </div>
                <div className="flex items-center justify-between py-3.5 border-b border-gray-200 last:border-b-0">
                    <div className='flex w-fit my-auto'>
                        <svg className='w-[22px] h-[17px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" /></svg>
                        <h1 className='ml-2 font-extrabold'>Retirar</h1>
                    </div>
                    <button
                        className={`
                            w-fit
                            px-4
                            py-2
                            text-sm
                            rounded-full
                            font-bold
                            font-roboto
                            bg-[#6232EB]
                            text-white
                            ${totalNoRequestAmount < 1000 ? 'cursor-not-allowed bg-slate-300' : ''}
                            `}
                        onClick={() => setShowPayoutForm(true)}
                        disabled={totalNoRequestAmount < 1000}
                    >
                        Solicitar
                    </button>
                </div>
            </div>
        </div>
    )
}
