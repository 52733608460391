import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface CardCarrouselDifferentProps {
  src: string;
  textButton: string;
  text: string;
  link: string; 
}

export const CardCarrouselDifferent: React.FC<CardCarrouselDifferentProps> = ({ src, text, textButton, link }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); 
      }
    }, {
      threshold: 0.1, 
    });

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <motion.div 
  ref={cardRef}
  className="w-full max-w-[500px] h-[350px] md:h-[300px] relative"
  initial={{ opacity: 0, scale: 0.9 }} 
  animate={isVisible ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }} 
  transition={{ duration: 0.5 }}
>
  <img 
    className="w-full h-full rounded-[27px] object-cover"  
    src={src} 
    alt="Carrousel" 
    loading="lazy" 
  />
  <div className="absolute inset-0 bg-gradient-to-t from-black/95 to-transparent rounded-[27px]"></div>
  <div className="absolute inset-0 flex flex-col justify-end items-center mb-10">
    <p className="primaryFont italic text-white text-3xl font-normal text-center mb-3">
      {text}
    </p>
    <Link 
      to={link} 
      className="no-underline bg-[#4F4CF1] rounded-full px-4 py-1 text-white text-base secondaryFont italic font-normal"
    >
      {textButton}
    </Link>
  </div>
</motion.div>
  );
};
