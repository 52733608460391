import reportIcon from '../../../../assets/img/report-icon.svg';
import indieIcon from '../../../../assets/img/indie-icon.svg';
import { InvestmentDetailsInterface } from '../../interfaces';
import { useOutletContext } from 'react-router-dom';

interface ReportListOutletContext {
    investmentDetails: InvestmentDetailsInterface;
}

export const ReportList = () => {
    const { investmentDetails } = useOutletContext<ReportListOutletContext>();
    return (
        <>
            {
                investmentDetails.name.includes('nido') &&
                <>
                    <div className="flex flex-col lg:mr-5">
                        <div className='w-full md:w-[330px] flex flex-wrap mr-4'>
                            <button
                                id='downloadReport'
                                className="
                        bg-[#E4E4E4]
                        hover:bg-[#E4E4E4]/80
                            w-full
                            rounded 
                            py-3
                            font-medium 
                            text-sm
                            text-left
                            cursor-pointer
                            duration-300 
                            ease-in-out
                            mb-[16px]
                            flex
                            justify-center
                            items-center
                        "
                                onClick={() => window.open('https://docsend.com/view/wqnxiwjyvppkhkh3', "blank")}
                            >
                                <img className='mr-2' width={30} src={reportIcon} alt="" />
                                Reporte <br /> 2023 (1 -2)
                            </button>
                        </div>
                        <div className='w-full md:w-[330px] flex flex-wrap mr-4'>
                            <button
                                id='downloadReport'
                                className="
                        bg-[#E4E4E4]
                        hover:bg-[#E4E4E4]/80
                            w-full
                            rounded 
                            py-3
                            font-medium 
                            text-sm
                            text-left
                            cursor-pointer
                            duration-300 
                            ease-in-out
                            mb-[16px]
                            flex
                            justify-center
                            items-center
                        "
                                onClick={() => window.open('https://docsend.com/view/it9usmc8ue2zs3gw', "blank")}
                            >
                                <img className='mr-2' width={30} src={reportIcon} alt="" />
                                Reporte <br /> 2024 (1 -2)
                            </button>
                        </div>
                    </div>
                </>
            }
            {
                investmentDetails.name.includes('indie') &&
                <>
                    <div className='w-full md:max-w-1/2 md:w-fit'>
                        <div className="flex flex-col lg:mr-5">
                            <div className='w-full md:w-[330px] flex flex-wrap mr-4'>
                                <a
                                    id='reserveIndieDetail'
                                    className="
                      bg-[#EBE2DD]
                      hover:bg-[#EBE2DD]/80
                      hover:text-black
                        hover:no-underline
                        no-underline
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                                    href='https://wa.link/mheirj'
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <img className='mr-2' width={30} src={indieIcon} alt="" />
                                    Reserva en <br /> Indie Universe
                                </a>

                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[16px]
                        flex
                        justify-center
                        items-center
                      "
                                    onClick={() => window.open('https://docsend.com/view/c43a2bz2497jwywj', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte <br /> trimestral 2022
                                </button>

                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                                    onClick={() => window.open('https://docsend.com/view/vduaf5ekw8gv5yjb', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte 2022 (4) <br /> 2023 (1)
                                </button>
                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                                    onClick={() => window.open('https://docsend.com/view/jifetre26z4nzcpu', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte 2023 (2)
                                </button>
                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                                    onClick={() => window.open('https://docsend.com/view/brh54pepy8tcidq6', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte 2023 (3)
                                </button>
                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                                    onClick={() => window.open('https://docsend.com/view/cdjbiyxqy5a7fmkb', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte 2024 (1)
                                </button>
                                <button
                                    id='downloadReport'
                                    className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                                    onClick={() => window.open('https://docsend.com/view/pfd8hy8fpdv5g2as', "blank")}
                                >
                                    <img className='mr-2' width={30} src={reportIcon} alt="" />
                                    Reporte 2024 (2)
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
