import React, { useState, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import ambassador_logo from "../../../assets/img/ambassadors/ambassador_logo.png";

export default function MainHeaderWithCTA() {
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const logoRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);

  
  const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.target === logoRef.current) {
          setIsVisible(true);
        }
        if (entry.target === textRef.current) {
          setIsButtonVisible(true);
        }
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2, 
    });

    if (logoRef.current) observer.observe(logoRef.current);
    if (textRef.current) observer.observe(textRef.current);
    if (buttonRef.current) observer.observe(buttonRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="relative top-14 w-full h-[100vh] bg-gray-50 overflow-hidden ">

    
      <div
        ref={logoRef}
        className="absolute top-8 right-8 w-44 h-44 md:w-60 md:h-60 flex items-center justify-center"
        style={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
        }}
      >
        <img
          src={ambassador_logo}
          alt="Logo embajadores Inversiones inmobiliarias"
          className="w-full h-full object-contain"
          loading="lazy"
        />
      </div>


      <div
        ref={textRef}
        className="relative flex flex-col items-center justify-center h-[100vh] md:mt-36 mt-16 md:h-[80vh] text-center z-10"
        style={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out 0.3s',
        }}
      >
        <p className="text-base md:text-xl lg:text-3xl text-black mb-2">#CreceConLokl</p>

        <h1
          className="primaryFont w-full text-[55px] md:text-[80px] lg:text-[165px] font-semibold text-black"
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-50px)',
            transition: 'opacity 1s ease-in-out, transform 1s ease-in-out 0.6s',
          }}
        >
          Sé <span className="text-[#5352F6]">Embajador</span> Lokl
        </h1>

        <p
          className="mt-4 text-lg text-gray-600 font-light md:text-3xl mx-10 secondaryFont max-w-[1100px]"
          style={{
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out 0.9s',
          }}
        >
          Únete a una comunidad apasionada por democratizar el acceso <br /> a bienes raíces
          y crear impacto positivo mientras creces con nosotros.
        </p>

        <div
          ref={buttonRef}
          className="mt-8 pt-4 flex flex-col md:flex-row gap-6 md:gap-32 justify-center text-base md:text-3xl primaryFont w-[200px] h-[80px] md:w-full md:max-w-[900px]"
          style={{
            opacity: isButtonVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out 1.2s',
          }}
        >
          <a 
  href="https://docs.google.com/forms/d/1cbsePiYDLggajgEK_s2rF5eBdwXb3XvXTSjYrPnpCwQ/edit" 
  target="_blank" 
   id="btn-become-ambassador"
  rel="noopener noreferrer" 
  className=" flex-1 px-3 py-3 md:px-2 md:py-2 no-underline bg-[#5352F6] text-white rounded-full hover:bg-blue-700 transition"
>
  Quiero ser embajador
</a>

          <HashLink
            to="#howitworks"
            smooth
            className="flex-1 no-underline px-3 py-3 md:px-2 md:py-2 bg-[#5352F6] text-white rounded-full hover:bg-blue-700 transition"
          >
            Descubrir cómo funciona
          </HashLink>
        </div>

        <div
          className="absolute hidden xl:block left-0 font-normal primaryFont md:top-[70%] transform -translate-y-1/2 rotate-[-90deg] text-gray-600 text-lg tracking-widest"
          style={{
            opacity: isButtonVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out 1.5s',
          }}
        >
          EmbajadoresLokl
        </div>
      </div>
    </div>
  );
}
