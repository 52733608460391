import React from "react"
import { formatNumberWithCommas } from "../../../../utils"

interface ProjectInfoTopCardInterface {
    minRent: number,
    maxRent: number,
    unitPrice: number,
    partners: number,
}

export const ProjectInfoTopCard: React.FC<ProjectInfoTopCardInterface> = ({ minRent, maxRent, unitPrice, partners }) => {
    return (
        <div className="mt-4 flex w-[90%] mx-auto flex-row justify-evenly">
            <div className="text-center">
                <p className="primaryFont font-bold text-lg">{minRent * 100} - {maxRent * 100}%</p>
                <p className="secondaryFont text-xxs font-normal">ROI proyectado</p>
            </div>
            <div className="text-center">
                <p className="primaryFont font-bold text-lg">${formatNumberWithCommas(unitPrice,0)}</p>
                <p className="secondaryFont text-xxs font-normal">Valor por Unit</p>
            </div>
            <div className="text-center">
                <p className="primaryFont font-bold text-lg">{partners}</p>
                <p className="secondaryFont text-xxs font-normal"># de partners</p>
            </div>
        </div>
    )
}
