import { NavLink } from "react-router-dom";

const PageNotFoundDashboard = () => {
    return (
        <div className="bg-red-500 h-full min-h-[500px] rounded-lg w-full text-white flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold mb-4">Página no encontrada</h1>
            <NavLink
                to={`/dashboard`}
                className="px-6 py-3 bg-white text-red-500 font-bold rounded-lg shadow hover:bg-gray-200 transition"
            >
                Volver a mis inversiones
            </NavLink>
        </div>
    );
};

export default PageNotFoundDashboard;
